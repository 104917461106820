import moment from 'moment'

export function getElementPositionY(el) {
  const rect = el.getBoundingClientRect();
  return rect.top + window.scrollY
}

export function getElementPositionYWithLoop(el) { 
    var top = 0; 
    do { 
        top += el.offsetTop-el.scrollTop; 
    } while ( el = el.offsetParent ); 

    return top; 
}

export function getElementPositionYWithLoopRWD(el, screenMode='COMPUTER', showTopNews, ...args) { 
  let adjustmentHeight = 0
  if (screenMode==='COMPUTER') adjustmentHeight+=100
  else adjustmentHeight+=64
  
  if (args.length) {
    adjustmentHeight = args.reduce((acc, height) => acc+height, adjustmentHeight)
  }

  if (showTopNews) adjustmentHeight+=24
    
  let top = 0;
  do { 
      top += el.offsetTop-el.scrollTop; 
  } while ( el = el.offsetParent ); 

  return top-adjustmentHeight; 

}

export function euqalObject(obj1, obj2) {
  let map1 = new Map(Object.entries(obj1));
  let map2 = new Map(Object.entries(obj2));
    
  if (map1.size !== map2.size) return false;
  for (let [key, val] of map1) {
    let val2 = map2.get(key);
    if (val !== val2 || (val2 === undefined && !map2.has(key)) ) return false;
  }
  return true;
}

export function timestampParse(timestamp) {
  const stringifyTimeStamp = String(timestamp)
  if (stringifyTimeStamp.length > 10) return moment(timestamp)
  return moment(timestamp*1000)
}

export function completePhoneNumber(number) {
  const re = /9[0-9]{8}/g;
  const reComplete = /09[0-9]/g
  if (re.test(number)) return `0${number}`
  else if (reComplete.test(number)) return number
  else return '號碼格式錯誤'
}

export function genFormData(obj) {
  return Object.entries(obj).reduce((acc, [key, val])=> {
    if (val) {
      acc.append(key, Array.isArray(val) || (typeof val === 'object' && val !== null) ? JSON.stringify(val) : val)
    }
    return acc
  }, new FormData())
}

export function clearData(data) {
  return Object.assign({}, ...Object.entries(data).map(([key, val]) => (val && { [key]: val })))
}

export async function fetchApi(api, data) {
  let res;
  if (data) {
    const cleanData = clearData(data)
    const body = genFormData(cleanData)
    res = await fetch(api, { body, method: 'POST' })
  } else {
    res = await fetch(api)
  }

  let text = await res.text()
  try {
    const json = JSON.parse(text)
    return json
  } catch (error) {
    console.error(error);
    console.error(`response text: ${text}`);
    return
  }
}

export function saveToLocal(key, val) {
  console.log(val);
  localStorage.setItem(key, Array.isArray(val) || (typeof val === 'object' && val !== null) ? JSON.stringify(val) : val)
}

export function getLocal(key) {
  return JSON.parse(localStorage.getItem(key))
}

export function removeFromLocal(key) {
  localStorage.removeItem(key)
}

export function roundDecimal(val, precision) {
  return Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0));
}

export function checkEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email)
}

export function checkPhoneNumber(number) {
  const re = /09[0-9]{8}/g
  return re.test(number)
}
