import { defineStore } from "pinia";
import { timestampParse } from '@/utils'
import database from '@/utils/database'

export const usePostStore = defineStore({
  id: 'Post',
  state: () => ({
    postList: [],
    currentPostTitle: ""
  }),
  getters: {
    currentPost() {
      if (this.postList.length && this.currentPostTitle) {
        const index = this.postList.map(e => e.post_title).indexOf(this.currentPostTitle)
        return this.postList[index]
      }
      return {}
    } 
  },
  actions: {
    async queryPost() {
      const res = await database.table('post').where('post_isPublished', '=', '1').orderBy('post_create_at', 'desc').get()
      this.postList = res.data.map(e => ({
        ...e,
        post_create_at: timestampParse(e.post_create_at),
        post_content: e.post_content.replaceAll(/img\/post\/[\w\-]+.[\w]+/g, (...args) => `../${args[0]}`)
      }))
    }
  }
})
