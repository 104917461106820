<script setup>
</script>

<template>
  <div class="hlep-return-order">
    <header class="subpage-header">
      <h1>退換貨須知</h1>
    </header>
    
    <main>
      <section>
        <h2>退換貨注意事項</h2>
        <ul>
          <li>
            <header>如有下列任一情形，將無法接受商品退換：</header>
            <ol>
              <li>提出退換貨時間已超過7日鑑賞期 (自收到貨當日開始計算，含週休及國定例假日）。</li>
              <li>已被拆封（包裝封膜損毀、原廠瓶口封條損毀、已開瓶使用）的正商品（此為個人消耗性商品，一經拆封恕不退換），但不包含試用品</li>
              <li>發票遺失或毀損（無法辨識發票編號、商品貨號等資訊）</li>
            </ol>
          </li>
          <li>
            <header>辦理退貨之後多久就可以收到退款？</header>
            <span>在收到退貨產品並確認商品無拆封且含有該商品發票的 3 個工作天後，我們會將款項以訂單原付款方式退款，完成退款也會收到信件通知。</span>
          </li>
          <li>
            <header>辦理退貨是否要自行負擔運費？</header>
            <span>
              這個服務是免費的，只有在原訂單金額未達免運標準（未滿 2,000 元）的時候，我們將從退回的款項中扣除運費 120 元。 (原訂單交易中使用的購物金會在退款程序中全額退回至購物金帳戶，但已使用的優惠券將無法重新補發）
            </span>
          </li>
        </ul>
        <span class="extra">**以上Chris Farrell保有最終退貨條款解釋權</span>
      </section>

      <section>
        <h2>官網退貨程序（整單退貨）</h2>
        <ol>
          <li>在官網「<a href="/member" target="_blank">我的訂單</a>」頁面中，找到要退貨的訂單並點擊該筆訂單進入訂單詳情頁</li>
          <li>在訂單詳情頁的右上角點擊「退貨申請」按鈕，按鈕下方會出現退貨申請表單</li>
          <li>
            請在申請表單中填寫退貨地址及退貨原因並提交退貨申請（請確認退貨地址及退貨人相關資料是否正確，若物流人員無法找到該地址且無法聯絡退貨人，將無法進行退貨）
          </li>
          <li>
            在提交退貨相關資料後，客服人員將儘速審核退貨申請並協助安排宅配公司於審核通過後的 1-3 個工作天到府收回您手邊的產品 （無需負擔退貨物流費用）
          </li>
          <li>
            請將<b>未拆封的正品</b>與<b>出貨單</b>及<b>發票</b>裝回原本的 Chris Farrell 紙箱包裝妥當<b>（若發票遺失恕無法辦理退貨）</b>，若原紙箱已遺失，請使用其他完整紙箱包裝，切勿直接於原廠包裝上黏貼紙張或書寫文字，並在宅配公司到府收件時交給收貨人員
          </li>
          <li>
            退貨包裹會在 2 個工作天退回 Chris Farrell Taiwan 客服中心，若您要求退貨之商品若有缺件或是損毀， Chris Farrell Taiwan 有權視損毀程度而折扣退款金額。
          </li>
          <li>客服中心收到商品後確認商品無拆封且含有該商品發票後，我們將在 3 個工作天後完成退款，並同步透過信件通知</li>
        </ol>
      </section>

      <section>
        <h2>官網退貨程序（部分商品退貨）</h2>
        <ol>
          <li>
            透過 <a href="https://page.line.me/369ljfeu" target="_blank">Line @</a> 客服通知客服人員，並提供訂單編號以及您要退貨的品項
          </li>
          <li>客服人員將儘速安排宅配公司於 1-3 個工作天到府收回您手邊的產品 （無需負擔退貨物流費用）</li>
          <li>
            請將要退貨的<b>未拆封正品</b>與<b>出貨單</b>及<b>發票</b>裝回原本的 Chris Farrell 紙箱包裝妥當<b>（若發票遺失恕無法辦理退貨）</b>，若原紙箱已遺失，請使用其他完整紙箱包裝，切勿直接於原廠包裝上黏貼紙張或書寫文字，並在宅配公司到府收件時交給收貨人員
          </li>
          <li>
            退貨包裹會在 2 個工作天退回 Chris Farrell Taiwan 客服中心，若您要求退貨之商品若有缺件或是損毀， Chris Farrell Taiwan 有權視損毀程度而折扣退款金額。
          </li>
          <li>客服中心收到商品後確認商品無拆封且含有該商品發票後，我們將在 3 個工作天內退回指定退貨商品款項，並同步透過信件通知</li>
        </ol>
      </section>

      <section>
        <h2>官網換貨程序</h2>
        <ol>
          <li>透過官方 <a href="https://page.line.me/369ljfeu" target="_blank">Line @</a> 客服通知客服人員，並提供訂單編號以及產品瑕疵照片</li>
          <li>客服人員確認產品情況符合換貨標準後，請提供退貨地址及退貨人資料，客服人員將儘速為您安排宅配公司於 5 個工作天內到府收貨</li>
          <li>
            請將<b>有瑕疵的正品</b>與<b>出貨單</b>及<b>發票</b>裝回原本的 Chris Farrell 紙箱包裝妥當<b>（若發票遺失恕無法辦理換貨）</b>，若原紙箱已遺失，請使用其他完整紙箱包裝，切勿直接於原廠包裝上黏貼紙張或書寫文字，並在宅配公司到府收件時交給收貨人員
          </li>
          <li>
            換貨包裹會在 2 個工作天退回 Chris Farrell Taiwan 客服中心，客服中心收到商品後確認商品無拆封且含有該商品發票後，我們將在 3 個工作天內為您換新商品出貨，並同步透過 Line @ 通知到貨
          </li>
        </ol>
        <span class="extra">** 欲更換不同商品：若您收到訂購的商品後，想要更換其中任何一款商品，您必須先退回此次訂購的所有商品，取消此筆訂單，然後重新下訂一筆新的訂單，恕無法直接使用原訂單進行換貨。</span>
      </section>

      <section>
        <h2>退款</h2>
        <p>本公司收到退貨商品及發票，並確認商品無誤後將為您安排退款作業</p>
        <ol>
          <li>宅配/超商已取貨：於 14 個工作日內（不含週休及國定例假日）</li>
          <li>
            超商未取貨：於 21 個工作日內（不含週休及國定例假日）完成刷卡退款之作業；唯依據不同發卡銀行的處理流程，刷退款項可能列於本月或次月的帳單上，請您聯絡您的發卡銀行詢問細節。
          </li>
        </ol>
      </section>
    </main>
  </div>
</template>
