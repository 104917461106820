<script setup>
import { VScrollFadein } from '../directives'
import SwiperCore, { Controller, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useRoute, useRouter } from 'vue-router'
import { ref, inject, onMounted, watch, onBeforeUnmount } from 'vue'
import Icon from '../components/Icon.vue'
import { usePostStore } from '../store/post';
import { useProductStore } from '../store/product';
import { useLoginStore } from '../store/login';
import { useLayoutStore } from '../store/layout';
// import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination])
const router = useRouter()
const route = useRoute()
const ScreenState = inject('ScreenProvider')
const PostStore = usePostStore()
const ProductStore = useProductStore()
const LoginStore = useLoginStore()
const LayoutStore = useLayoutStore()

onMounted(() => {
  window.scrollTo(0,0)
  carouselFunc()
  const { state, login, cus } = route.query
  if (state==='200' && login==='line') {
    LoginStore.queryUserWithId(cus)
  }
})

const carouselSwiper = ref(null)
const productSwiper = ref(null)
const postSwiper = ref(null)
const setSwiper = (swiper, swiperType) => {
  switch (swiperType) {
    case 'PRODUCT': return ScreenState.mode === 'COMPUTER'
      ? swiper.disable()
      : productSwiper.value = swiper
    case 'POST': return postSwiper.value = swiper
    default: return carouselSwiper.value = swiper
  }
}

const swipeNext = (swiperType, isNext=true) => {
  switch (swiperType) {
    case 'PRODUCT': return productSwiper.value[isNext ? 'slideNext' : 'slidePrev']()
    case 'POST': return postSwiper.value[isNext ? 'slideNext' : 'slidePrev']()
    default: return carouselSwiper.value[isNext ? 'slideNext' : 'slidePrev']()
  }
}

const currentTimeout = ref()
const carouselSensor = ref()
watch(carouselSensor, changeCarouselPage)
async function carouselFunc() {
  currentTimeout.value = setTimeout(() => {
    carouselSensor.value = !carouselSensor.value
  }, 5000);
}
function changeCarouselPage(isAuto=true, isNext=true) {
  if (!isAuto) carouselSwiper.value[isNext ? 'slideNext' : 'slidePrev']()
  else swipeNext('CAROUSEL', true)
  clearTimeout(currentTimeout.value)
  carouselFunc()
}
onBeforeUnmount(() => clearTimeout(currentTimeout.value))

const carouselPureImages = []
const carouselMobilePureImages = []
const starProducts = [
  { product_uid: '2012', product_slogan: '一瓶急救9種問題肌' },
  { product_uid: '2004', product_slogan: '全面平衡透出嫩亮肌' },
  { product_uid: '2080', product_slogan: '清香不油膩重拾柔嫩雙手' }
]
</script>

<template>
  <div class="view-home">
    <section class="carousel">
      <button class="control left" @click="changeCarouselPage(false, false)">
        <Icon icon="chevron-left" />
      </button>
      <button class="control right" @click="changeCarouselPage(false)">
        <Icon icon="chevron-right" />
      </button>
      <swiper
        @swiper="swiper => setSwiper(swiper, 'CAROUSEL')"
        :loop="true"
        :pagination="{ clickable: true }"
        :allowTouchMove="false"
      >
        <swiper-slide  
          v-for="img, index in !LayoutStore.layoutList.length
            ? ScreenState.mode === 'COMPUTER'? carouselPureImages : carouselMobilePureImages
            : ScreenState.mode === 'COMPUTER'
              ? LayoutStore.layoutDict['Carousel-Pure-PC'].layout_content
              : LayoutStore.layoutDict['Carousel-Pure-Mobile'].layout_content
          " 
          :key="`carousel_pure_img_${index}`"
        >
          <router-link :to="img.link">
            <img :src="`/img/carousel/${img.img}`">
          </router-link>
        </swiper-slide>
      </swiper>
    </section>
    <section class="transition">
      <ul>
        <li><img :src="require('@/assets/img/v3/vip_right_d4.webp')" alt=""><p>滿千免運</p></li>
        <li><img :src="require('@/assets/img/v3/vip_right_d2.webp')" alt=""><p>7天鑑賞期</p></li>
        <li><img :src="require('@/assets/img/v3/vip_right_d3.webp')" alt=""><p>首購金200元</p></li>
      </ul>
    </section>
    <section class="from-spa">
      <img :src="require('@/assets/img/v3/home_bg_v3.webp')">
      <div class="content">
        <header v-scroll-fadein>
          <h1>來自德國古溫泉鎮</h1>
          <h2>生理學打造 人體仿生活性成分</h2>
          <span>
            保養就跟瘦身一樣，快速的方法通常對身體有負面影響，肌膚角質代謝就需要28天，你又怎麼能要求皮膚2天就變白呢？試試人體仿生成分保養品，還給你肌膚原生的修復力。
          </span>
        </header>
        <main v-scroll-fadein>
          <h1>風靡全球頂級 SPA</h1>
          <p>全球合作超過5000間美容沙龍遍布比利時、法國、美國、盧森堡等30多國</p>
          <img :src="ScreenState.mode === 'COMPUTER' ? require( '@/assets/img/v3/spa_flipagram.gif') : require('@/assets/img/v3/spa_flipagram_m.gif')" alt="">
        </main>
      </div>
    </section>
    <section class="star-products" v-if="ProductStore.productList.length">
      <header>
        <h1 v-scroll-fadein>明星產品</h1>
        <!-- <router-link to="/product"><p>來去逛逛</p> <icon icon="chevron-right" /></router-link> -->
      </header>
      <main v-scroll-fadein>
        <div v-if=" ScreenState.mode === 'MOBILE'" class="control left">
          <button @click="swipeNext('PRODUCT', false)"><Icon icon="chevron-left" /></button>
        </div>
        <div v-if=" ScreenState.mode === 'MOBILE'" class="control right">
          <button @click="swipeNext('PRODUCT', true)"><Icon icon="chevron-right" /></button>
        </div>
        
        
        <swiper
          @swiper="swiper => setSwiper(swiper, 'PRODUCT')"
          :slides-per-view="ScreenState.mode === 'COMPUTER' ? 3 : 1"
          :space-between="ScreenState.mode === 'COMPUTER' ? 32 : 48"
          :loop="true"
        >
          <swiper-slide  
            v-for="product in starProducts"
            :key="`star_${product.product_uid}`"
          >
            <router-link :to="`/product/${ProductStore.productUidDict[product.product_uid].product_name_en.toLowerCase().replace(/\s/g, '-')}`">
              <div class="img-wrapper">
                <img :src="`/img/product/${product.product_uid}/avatar.webp`">
              </div>
              <h4>{{ product.product_slogan }}</h4>
              <h3>{{ ProductStore.productUidDict[product.product_uid].product_name_en }}</h3>
              <h2>{{ ProductStore.productUidDict[product.product_uid].product_name_zh }}</h2>
              <p>{{ ProductStore.productUidDict[product.product_uid].product_size[0] }}ml / ${{ ProductStore.productUidDict[product.product_uid].product_price.MEMBER }}</p>
            </router-link>
          </swiper-slide>
        </swiper>
      </main>
    </section>
    <section class="tech">
      <header>
        <h1 v-scroll-fadein>Dr. Chris Farrell</h1><h1 class="zh" v-scroll-fadein>50年研發</h1>
      </header>
      <main>
        <div v-scroll-fadein>
          <div>
            <span class="gif-title">
              有人體仿生
            </span>
            <img :src="require('@/assets/img/v3/tech1.gif')" alt="">
          </div>
          <div>
            <span class="gif-title">
              無人體仿生
            </span>
            <img :src="require('@/assets/img/v3/tech2.gif')" alt="">
          </div>
        </div>
        <span v-scroll-fadein>
          克莉斯瑞博士於1964年著手進行生物、化學、皮膚醫學、植物藥學等研究，結合上述廣闊的學問，研發專業保養品以及美容療程，並結合人體仿生的科技與成分，用最適合人體的方式幫助皮膚重獲健康與修復力。
          皮膚是人體最大的器官，它的天職是保護人體免於外在環境的侵害。對於皮膚來說，任何不熟悉的物質，甚至是保養品成分，它都會盡力避免吸收進人體，雖然這些成分對於人體是有幫助的，但為了人類的生存與演化，這正是皮膚數千年來保護人類的方式。然而現代社會的衛生條件相比過去已經完全不同，人們活著不再只是為了生存，更多人追求的是精緻地活著。有了人體仿生保養品的協助，皮膚更願意接納其他對皮膚有效的成分，讓其他美白、抗老、修護等成分與人體仿生成分一同深入肌底，用更安全有效的方式將保養的效果帶給使用者。在這裡邀請大家一同體驗德國研發50年的人體仿生技術，更安全、更有效地照顧自己的肌膚。
        </span>
        <!-- <button>了解更多 <icon icon="chevron-right" /></button> -->
      </main>
    </section>
    <section class="skin">
      <h1 v-scroll-fadein>依膚質選擇</h1>
      <main v-scroll-fadein>
        <router-link to="/skin-type/fragile"><img :src="require('@/assets/img/v3/skin_fregile.webp')"><p>特殊美容</p></router-link>
        <router-link to="/skin-type/dry"><img :src="require('@/assets/img/v3/skin_dry.webp')"><p>乾肌</p></router-link>
        <router-link to="/skin-type/combination"><img :src="require('@/assets/img/v3/skin_oil.webp')"><p>混合肌</p></router-link>
        <router-link to="/skin-type/sensitive"><img :src="require('@/assets/img/v3/skin_sensitive.webp')"><p>敏感肌</p></router-link>
      </main>
    </section>
    <section class="insist">
      <span class="line"></span>
      <main>
        <h1 v-scroll-fadein>四大堅持</h1>
        <ul v-scroll-fadein>
          <li><img :src="require('@/assets/img/v3/insist1.webp')" alt=""><p>德國空運</p></li>
          <li><img :src="require('@/assets/img/v3/insist2.webp')" alt=""><p>不含礦物油</p></li>
          <li><img :src="require('@/assets/img/v3/insist3.webp')" alt=""><p>無動物實驗</p></li>
          <li><img :src="require('@/assets/img/v3/insist4.webp')" alt=""><p>不含色素</p></li>
        </ul>
      </main>
    </section>
    <section class="vip-right">
      <h1 v-scroll-fadein>三大會員權益</h1>
      <ul v-scroll-fadein>
        <li><img :src="require('@/assets/img/v3/vip_right1.webp')" alt=""><p>好友推薦碼</p></li>
        <li><img :src="require('@/assets/img/v3/vip_right2.webp')" alt=""><p>7天鑑賞期</p></li>
        <li><img :src="require('@/assets/img/v3/vip_right3.webp')" alt=""><p>首購金200元</p></li>
      </ul>
    </section>
    <section class="post" v-if="PostStore.postList.length">
      <h1 v-scroll-fadein>皮膚知識專欄</h1>
      <p v-scroll-fadein>“所有文章皆參考論文撰寫而成，絕非憑空杜撰的草食文章，只傳達有科學背書的皮膚知識”</p>
      <div class="swiper">
        <template v-if="ScreenState.mode === 'MOBILE'">
          <button class="m-control left" @click="swipeNext('POST', false)"><icon icon="chevron-left" /></button>
          <button class="m-control right" @click="swipeNext('POST', true)"><icon icon="chevron-right" /></button>
        </template>
        <Swiper
          @swiper="swiper => setSwiper(swiper, 'POST')"
          :slides-per-view="ScreenState.mode === 'COMPUTER' ? 2 : 1"
          :space-between="ScreenState.mode === 'COMPUTER' ? 64 : 48"
          :loop="true"
        >
          <swiper-slide v-for="post in PostStore.postList.filter(p => p.category_id!=='9')" :key="post.post_id">
            <router-link :to="`/blog/${post.post_title}`">
              <div class="image-container">
                <img :src="post.post_bannerPath" :alt="post.post_banner">
              </div>
              <div class="content">
                <h2>{{ post.post_title }}</h2>
                <span v-html="post.post_summary.replace(/\n/g, '<br>') "></span>
                <p>{{ post.post_create_at.format('YYYY/MM/DD') }}</p>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
      <div class="control" v-if="postSwiper">
        <button @click="swipeNext('POST', false)"><Icon icon="chevron-left" /></button>
        <p>{{ postSwiper.realIndex + 1 }} /
          {{ PostStore.postList.length ? PostStore.postList.filter(p => p.category_id!=='9').length : '-' }}</p>
        <button @click="swipeNext('POST', true)"><Icon icon="chevron-right" /></button>
      </div>
    </section>
  </div>
</template>
