import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createPinia } from 'pinia'
import "@/assets/scss/index.scss"
import "swiper/swiper.scss"
import smoothscroll from 'smoothscroll-polyfill';
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

smoothscroll.polyfill();
createApp(App).use(createPinia()).use(store).use(router).use(VueClipboard).mount('#app')
