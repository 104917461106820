<script setup>
import BrandLogoPcImg from '@/assets/img/brand_logo_pc.png'
import BrandLogoWPcImg from '@/assets/img/brand_logo_w_pc.png'
import BrandLogoMImg from '@/assets/img/brand_logo_m.svg'
import Icon from '@/components/Icon.vue'
import { ref, watch, inject, onMounted, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useLoginStore } from '../store/login';
import { usePaymentStore } from '../store/payment';
import { useLayoutStore } from '../store/layout';

const LoginStore = useLoginStore()
const PaymentStore = usePaymentStore()
const router = useRouter()
const route = useRoute()
const ScreenState = inject('ScreenProvider')
const isWhite = ref(false)
const whiteRoute = ['About', 'Post', 'ProductInstruction', 'ProductExplore', 'Vip-Right']
watch(route, (newVal) => {
  if (whiteRoute.includes(route.name)) isWhite.value = true
  else isWhite.value = false
}, { immediate: true })

const toHome = () => {
  if (ScreenState.mode === 'MOBILE') hasSpread.value = false
  router.push('/')
}

const LayoutStore = useLayoutStore()
const LayoutSetting = inject('LayoutSetting')
LayoutStore.$subscribe(() => {
  console.log(LayoutStore.layoutDict['Nav-Top']);
  if (!LayoutStore.layoutDict['Nav-Top'].layout_content) {
    LayoutSetting.showNavTopNews = false
  }
})

const hasSpread = ref(false)
const toggleSpread = () => hasSpread.value = !hasSpread.value

const showBackground = ref(false)
const showProductNav = ref(false)
const toggleShowBackground = boolean => {
  const windowScrollY = document.documentElement.scrollTop
  if (ScreenState.mode === "COMPUTER" && windowScrollY < window.innerHeight * 0.1) showBackground.value = boolean
  if (!boolean) showProductNav.value = false
}
const toggleShowProductNav = boolean => {
  if (ScreenState.mode === "COMPUTER") showProductNav.value = boolean
}
let func = () => {
  const windowScrollY = document.documentElement.scrollTop
  const windowHeight = window.innerHeight
  if (windowScrollY >= window.innerHeight * 0.1) showBackground.value = true
  else showBackground.value = false
}
onMounted(() => {
  window.scrollTo(0, 0)
  document.addEventListener('scroll', func)
})
onBeforeUnmount(() => document.removeEventListener('scroll', func))

const $message = inject('$message')
const logout = async () => {
  router.push('/login')
  const result = await LoginStore.logout()
  if (result.status === 200) $message.success('已登出')
  location.reload()
}

const hideNavTopNews = () => LayoutSetting.showNavTopNews = false
</script>

<template>
  <header
    v-if="ScreenState.mode === 'COMPUTER'"
    id="nav"
    :class="{ white: isWhite, background: showBackground }"
    @mouseenter="toggleShowBackground(true)"
    @mouseleave="toggleShowBackground(false)"
  >
    <div v-if="LayoutSetting.showNavTopNews && LayoutStore.layoutDict['Nav-Top']" class="pc-nav-top-news">
      <span>{{ LayoutStore.layoutDict['Nav-Top'].layout_content }}</span>
      <div @click="hideNavTopNews"><Icon icon="x" /></div>
    </div>
    <div class="nav-container">
      <nav class="pc-nav">
        <ul>
          <li @mouseenter="toggleShowProductNav(true)" @click="router.push('/product')">
            <router-link to="/product">系列商品</router-link>
          </li>
          <li>
            <router-link to="/blog">保養知識</router-link>
          </li>
          <li>
            <router-link to="/about">品牌故事</router-link>
          </li>
        </ul>
      </nav>
      <router-link class="brand-logo" to="/">
        <img :src="BrandLogoPcImg" alt />
      </router-link>
      <nav class="pc-nav">
        <ul>
          <li>
            <router-link to="/vip-rights">會員權益</router-link>
          </li>
          <li>
            <router-link v-if="PaymentStore.cartItems.length" to="/payment">
              <Icon icon="cart-fill"></Icon>
              <span
                v-if="PaymentStore.cartItems.length"
                class="items-count"
              >{{ PaymentStore.cartItems.length }}</span>
            </router-link>
            <Icon v-else icon="cart-fill"></Icon>
          </li>
          <li v-if="LoginStore.currentUser">
            <router-link to="/member/order">
              <Icon icon="person-circle"></Icon>
            </router-link>
          </li>
          <li>
            <router-link v-if="!LoginStore.currentUser" to="/login">會員登入</router-link>
            <p v-else @click="logout" style="font-size: 16px; cursor: pointer;">登出</p>
          </li>
        </ul>
      </nav>
    </div>
    <Transition name="fade-down">
      <div  v-if="showProductNav" class="product-nav">
        <ul>
          <li><router-link to="/product">全系列商品</router-link></li>
          <p>|</p>
          <li>依膚質選擇：</li>
          <li><router-link to="/skin-type/sensitive">敏弱肌</router-link></li>
          <li><router-link to="/skin-type/dry">乾性肌</router-link></li>
          <li><router-link to="/skin-type/combination">混合肌</router-link></li>
          <li><router-link to="/skin-type/fragile">特殊美容</router-link></li>
        </ul>
      </div>
    </Transition>
  </header>

  <header id="nav" :class="{ 'mobile-nav': true, 'spread': hasSpread }" v-else>
    <div v-if="LayoutSetting.showNavTopNews && LayoutStore.layoutDict['Nav-Top']" class="mobile-nav-top-news">
        <span>{{ LayoutStore.layoutDict['Nav-Top'].layout_content }}</span>
      <div @click="hideNavTopNews"><Icon icon="x" /></div>
    </div>
    <div class="nav-container">
      <i :class="{ 'nav-icon': true, 'spread': hasSpread }" @click="toggleSpread">
        <i class="top"></i>
        <i class="mid"></i>
        <i class="bot"></i>
      </i>
      
      <router-link class="brand-logo-mobile" to="/">
        <img :src="BrandLogoMImg" alt />
      </router-link>

      <router-link class="shopping-cart" to="/payment">
        <Icon icon="cart-fill"></Icon>
        <span v-if="PaymentStore.cartItems.length" class="badge">{{ PaymentStore.cartItems.length }}</span>
      </router-link>
    </div>
    

    <nav :class="{ 'collapse': true, 'spread': hasSpread, 'hadLogin': LoginStore.currentUser, down: LayoutSetting.showNavTopNews }" @click="toggleSpread">
      <ul>
        <li class="title">系列產品</li>
        <li><router-link class="title" to="/product">全部商品</router-link></li>
        <li class="product-route title">- 依膚質分類 -</li>
        <li class="product-route"><router-link to="/skin-type/sensitive">敏弱肌</router-link></li>
        <li class="product-route"><router-link to="/skin-type/dry">乾肌</router-link></li>
        <li class="product-route"><router-link to="/skin-type/combination">混合肌</router-link></li>
        <li class="product-route"><router-link to="/skin-type/fragile">特殊美容</router-link></li>
        <li>
          <router-link to="/about">品牌故事</router-link>
        </li>
        <li>
          <router-link to="/blog">保養知識</router-link>
        </li>
        <li>
          <router-link to="/vip-rights">會員權益</router-link>
        </li>
        <!-- <li>
          <router-link to="/tattoo-care">刺青保養</router-link>
        </li> -->
        <li v-if="!LoginStore.currentUser">
          <router-link to="/login">會員登入</router-link>
        </li>
        <template v-else>
          <li>
            <router-link to="/member">會員專區</router-link>
          </li>
          <li class="logout" @click.stop="logout">
            <p>登出</p>
          </li>
        </template>
      </ul>
    </nav>
  </header>
</template>
