<script setup>
import { onMounted, computed, onBeforeUnmount, ref, inject, reactive, watch } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { useProductStore } from '../store/product'
import { useIngredientStore } from '../store/ingredient'
import { useCommentStore } from '../store/comment' 
import Icon from '../components/Icon.vue'
import 'swiper/components/navigation/navigation.scss'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { usePaymentStore } from '../store/payment'
import { useLoginStore } from '../store/login'
import { getElementPositionYWithLoop, getElementPositionYWithLoopRWD, timestampParse, roundDecimal } from '@/utils'
import { gaEvent } from '@/utils/event'
import IconMember from '@/assets/img/icon_member.svg'
import IconVip from '@/assets/img/icon_vip.svg'
import IconStarFill from '@/assets/img/icon_star_fill.svg'
import IconStar from '@/assets/img/icon_star.svg'
import { useLayoutStore } from '../store/layout'

const SKIN_TYPE_DICT = {
  dry: '乾肌',
  sensitive: '敏弱肌',
  fragile: '特殊美容',
  oil: '油肌',
  combination: '混合肌',
  rough: '毛孔粗大',
  acne: '痘肌',
  others: '其他'
}
const USER_AGE_GROUP_DICT = ['20歲以下', '21~30歲', '31~40歲', '41~50歲', '51~60歲', '60歲以上']
const ProductStore = useProductStore()
const IngredientStore = useIngredientStore()
const CommentStore = useCommentStore()
const LayoutStore = useLayoutStore()
const ScreenState = inject('ScreenProvider');
const LayoutSetting = inject('LayoutSetting')
const Router = useRouter()
const route = useRoute()
const showFooterAction = ref(false)
const scrollLock = ref(true)
const scrollPercent = ref(0)

let func = () => {
  const windowScrollY = document.documentElement.scrollTop
  const windowHeight = window.innerHeight
  const bodyHeight = document.body.clientHeight
  if (!scrollLock.value) {
    // console.log('windowHeight', windowHeight);
    // console.log('bodyHeight', bodyHeight);
    let newScrollPercent = Math.round((windowScrollY + windowHeight) / bodyHeight * 100) / 100
    if (newScrollPercent > scrollPercent.value) scrollPercent.value = newScrollPercent
    // console.log(scrollPercent.value );
  }
  if (windowScrollY >= window.innerHeight * 0.4) showFooterAction.value = true
  else showFooterAction.value = false
}

const commentAvarageInfo = computed(() => roundDecimal(CommentStore.focusCommentList.reduce((acc, c) => acc+parseInt(c.comment_stars), 0) / CommentStore.focusCommentList.length, 1))
const commentStatistics = computed(() => CommentStore.focusCommentList.reduce((acc, c) => {
  acc[c.comment_stars - 1] += 1
  return acc
}, [0, 0, 0 ,0, 0]))

onMounted(async () => {
  window.scrollTo(0, 0)
  setTimeout(() => {
    scrollLock.value = false
  }, 1000);
  document.addEventListener('scroll', func)
  if (!ProductStore.productList.length) ProductStore.queryProduct()
  if (!IngredientStore.ingredientList.length) IngredientStore.queryIngredient()
  const productName = route.params.productName
  ProductStore.focusProductName = productName

  if (!CommentStore.commentDict[ProductStore.focusProduct.product_id]) {
    CommentStore.focusProductId = ProductStore.focusProduct.product_id
    CommentStore.queryCommentOfProduct(ProductStore.focusProduct.product_id)
  }
})
onBeforeUnmount(() => document.removeEventListener('scroll', func))

const Route = useRoute()
watch(Route, () => {
  if (route.params.productName) {
    ProductStore.focusProductName = route.params.productName
    window.scrollTo({ top: 0 })
    CommentStore.focusProductId = ProductStore.focusProduct.product_id
    if (!CommentStore.commentDict[ProductStore.focusProduct.product_id]) {
      CommentStore.queryCommentOfProduct(ProductStore.focusProduct.product_id)
    }
  }
})

const firstSwiper = ref()
const secondSwiper = ref()
function setFirstSwiper(swiper) {
  if (ScreenState.mode === 'COMPUTER') swiper.disable()
  firstSwiper.value = swiper
}
function setSecondSwiper(swiper) {
  if (ScreenState.mode === 'COMPUTER') swiper.disable()
  secondSwiper.value = swiper
}
function swiperTo (next=true) {
  firstSwiper.value[next ? 'slideNext' : 'slidePrev']()
}
function swiperTo2 (next=true) {
  secondSwiper.value[next ? 'slideNext' : 'slidePrev']()
}

const currentProductImgIndex = ref(0)
const productAmount = ref(1)
const changeCurrentImgIndex = index => currentProductImgIndex.value = index

const focusSizeIndex = ref(0)
const dropdownExpreaded = ref(false)
const toggleDropDown = () => dropdownExpreaded.value = !dropdownExpreaded.value
const changeFocusSizeIndex = index => {
  focusSizeIndex.value = index
  dropdownExpreaded.value = false
}

const newItemInNewTab = productName => {
  const href = Router.resolve(`/product/${productName}`).href
  window.open(href, '_blank');
}

const $message = inject('$message')
const LoginStore = useLoginStore()
const PaymentStore = usePaymentStore()
const addIntoCart = () => {
  if (isNaN(productAmount.value)) {
    return $message.warning('請確認商品數量為數字！')
  }
  if (parseInt(productAmount.value) > 99) {
    return $message.warning('超過單次只能購買上限 99 個品項！')
  }
  const productIndex = PaymentStore.cartItems.map(e => e.product_id).indexOf(ProductStore.focusProduct.product_id)
  if (productIndex === -1 || (PaymentStore.cartItems[productIndex] && PaymentStore.cartItems[productIndex].product_price !== 'MEMBER')) {
    PaymentStore.addProductIntoCart({
      product_id: ProductStore.focusProduct.product_id,
      product_quantity: parseInt(productAmount.value),
      product_price: 'MEMBER'
    })
  }
  else PaymentStore.editProductQuantity(ProductStore.focusProduct.product_id, parseInt(productAmount.value))
  
  //google analytics event
  gaEvent('add_to_cart', {
    location_url: route.fullPath,
    product_uid: ProductStore.focusProduct.product_uid,
    number_of_products: productAmount.value,
    scroll_percent: scrollPercent.value
  })
  $message.success('已加入購物車')
}

const addInfoWishlist = async id => {
  if (LoginStore.currentUser?.user_wishlist.includes(id)) {
    await LoginStore.removeFromWishlist(id)
    console.log('remove');
    return $message.success('已從願望清單中移除')
  }
  console.log('add');
  await LoginStore.addWishlist(id)

  //google analytics event
  gaEvent('add_to_wishlist', {
    location_url: route.fullPath,
    product_uid: ProductStore.focusProduct.product_uid,
    scroll_percent: scrollPercent.value
  })
  $message.success('已加入願望清單')
}

const activeMode = ref('DETAIL')
const productInfoREF = ref(null)
const changeMode = mode => {
  activeMode.value = mode
  let productInnerHeight = productInfoREF.value.clientHeight
  let amendment = 84 
  return window.scrollTo({ top: productInnerHeight - amendment})
}

const freezeClick = ref(false)
const freezeTimeout = ref(null)
function checkSelect() {
  const selection = document.getSelection().toString()
  if (selection) {
    freezeClick.value = true
    if (freezeTimeout.value) clearTimeout(freezeTimeout.value)
    freezeTimeout.value = setTimeout(() => freezeClick.value = false, 1000);
  } else freezeClick.value = false
  
}

const ingredientSpread = ref(false)
function toggleCollapse() {
  if (!freezeClick.value) ingredientSpread.value = !ingredientSpread.value
}

const ProductActionREF = ref(null)
const wantBuy = () => {
  if (ScreenState.mode === "MOBILE") {
    let actionElementY = getElementPositionYWithLoop(ProductActionREF.value)
    window.scrollTo({ top: actionElementY-64-20 })
  }
}

const newCommentFormData = reactive({
  comment_stars: 0,
  comment_gender: null,
  comment_age_range: null,
  comment_skin_type: null,
  comment_title: '',
  comment_content: '',
  comment_only_show_firstname: false
})

const showNewCommentForm = ref(false)
const chooseAgeRange = index => newCommentFormData.comment_age_range = index
const chooseSkin = skin => newCommentFormData.comment_skin_type = skin
const chooseScore = score => newCommentFormData.comment_stars = score
const toggleShowFirstName = () => newCommentFormData.comment_only_show_firstname = !newCommentFormData.comment_only_show_firstname

const handleCommentSubmit = async () => {
  if (newCommentFormData.comment_stars === 0) {
    return $message.warning('請給予此產品評分')
  }
  await CommentStore.createComment(ProductStore.focusProduct.key_id, newCommentFormData)
  $message.success('已送出評分！感謝您的回饋！')
  showNewCommentForm.value = false

  newCommentFormData.comment_stars= 0,
  newCommentFormData.comment_gender= null,
  newCommentFormData.comment_age_range= null,
  newCommentFormData.comment_skin_type= null,
  newCommentFormData.comment_title= '',
  newCommentFormData.comment_content= '',
  newCommentFormData.comment_only_show_firstname= false
}
const showCommentForm = () => {
  if (LoginStore.currentUser) {
    showNewCommentForm.value = true
  } else {
    $message.warning('請先登入會員')
    setTimeout(() => {
      window.open('/login')
    }, 1300);
  }
}
const handleCancel = () => showNewCommentForm.value = false


const CommentREF = ref(null)
const backToTopOfComment = () => {
  let actionElementY = getElementPositionYWithLoopRWD(
    CommentREF.value,
    ScreenState.mode,
    LayoutSetting.showNavTopNews,
    ScreenState.mode==='COMPUTER' ? 84 : 48
  )
  window.scrollTo({ top: actionElementY })
}

const SorterDict = {
  comment_star_desc: {
    name: '評分由高至低排序',
    method: (a, b) => b.comment_stars - a.comment_stars
  },
  comment_star_asc: {
    name: '評分由低至高排序',
    method: (a, b) => a.comment_stars - b.comment_stars,
  },
  comment_create_at_desc: {
    name: '時間由近至遠排序',
    method: (a, b) => b.comment_create_at - a.comment_create_at
  }
}
const commentSorter = ref('comment_star_desc')
const showDropdownMenu = ref(false)
const toggleShowDropdown = bool => bool === 'toggle' 
  ? showDropdownMenu.value = !showDropdownMenu.value
  : showDropdownMenu.value = bool
const chooseCommentSorter = sorter => {
  commentSorter.value = sorter
  toggleShowDropdown(false)
  resetPagination()
}

const commentFilter = ref({ column: '', value: null })
const showFilter = ref(false)
const toggleShowFilter = bool => showFilter.value = bool
const chooseFilter = (column , value) => {
  commentFilter.value = { column, value }
  showFilter.value = false
  resetPagination()
  backToTopOfComment()
}
const resetFilter = () => {
  commentFilter.value = { column: '', value: null }
  showFilter.value = false
}

const pagination = ref(0)
const changePagination = index =>{
  pagination.value = index
  backToTopOfComment()
}
function resetPagination() {
  pagination.value = 0
}
function incrementPage(step) {
  pagination.value += step
  if(step!=0) backToTopOfComment()
}

const computedCommentList = computed(() => {
  let newCommentList = []
  if (CommentStore.focusCommentList) {
    newCommentList = JSON.parse(JSON.stringify(CommentStore.focusCommentList))
      .filter(c => c.comment_title || c.comment_content);
  }
  if (commentFilter.value) {
    newCommentList = newCommentList.filter(c => c[commentFilter.value.column] == commentFilter.value.value)
    console.log(commentFilter.value, newCommentList);
  }
  if (commentSorter.value) newCommentList.sort(SorterDict[commentSorter.value].method)
  return newCommentList
})
</script>

<template>
  <div class="view-product" v-if="ProductStore.focusProduct">
    <header class="product-info" ref="productInfoREF" :id="`product-info-${ProductStore.focusProduct.product_id}`">
      <div class="wrapper">
        <div class="info-section info">
          <h1>{{ ProductStore.focusProduct.product_name_en }}</h1>
          <h2>{{ ProductStore.focusProduct.product_name_zh }}</h2>
          <span class="line-1" />
          <span class="description">
            {{ ProductStore.focusProduct.product_summary }}
            <span
              class="set-items"
              v-if="ProductStore.focusProduct.product_type === 'SET' && ProductStore.focusProduct.product_set_items"
            >
              <p>組合產品：</p>
              <a
                v-for="itemId in ProductStore.focusProduct.product_set_items"
                :key="`${ProductStore.focusProduct.product_id}_set_item_${itemId}`"
                @click="newItemInNewTab(ProductStore.productDict[itemId].product_name_en.replace(/\s/g, '-').toLowerCase())"
              >
                <p>{{ ProductStore.productDict[itemId].product_name_zh }} {{ ProductStore.productDict[itemId].product_size[0] }}ml</p>
                <p>${{ ProductStore.productDict[itemId].product_price.ORIGIN }}</p>
              </a>
            </span>
          </span>
          <ul class="four-point">
            <li>歐盟 GMPISO 認證</li>
            <li>不做動物實驗認證 Peta Cruelty-free</li>
            <li>2018 德國最佳獎 DEUTSCHLANDS BESTE</li>
            <li>全球超過 5000 間美容院指定選用</li>
          </ul>
          <span class="line-1" />
          <div class="skin-type" v-if="ProductStore.focusProduct.product_skin_type.length">
            <div class="skin"
              v-for="skin in ProductStore.focusProduct.product_skin_type" 
              :key="`${ProductStore.focusProduct.product_id}_${skin}`"
            >
              <img :src="require(`@/assets/img/${skin}_skin_icon.svg`)" :alt="`skin_icon_${skin}`">
              <p>{{ SKIN_TYPE_DICT[skin] }}</p>
            </div>
          </div>
          <p class="from-and-ingredient">
            產地：Germany
            <i class="germany"></i>
          </p>
          <span class="line-1" />
        </div>
        <div class="info-section images">
          <div class="display">
            <img
              v-if="ProductStore.focusProduct.product_images"
              :src="`/img/product/${ProductStore.focusProduct.product_images[currentProductImgIndex]}`"
              :alt="`${ProductStore.focusProduct.product_name_en}-currentProductImgIndex`"
            />
          </div>
          <div class="swiper" :key="`product_focus_${ProductStore.focusProduct.product_uid}`">
            <swiper :slides-per-view="3" :space-between="24" :loop="true">
              <swiper-slide
                v-for="imgSrc, index in ProductStore.focusProduct.product_images"
                :key="`product_img_swiper_${index}`"
                style="width: 100px;"
                @click="changeCurrentImgIndex(index)"
              >
                <div>
                  <img :src="`/img/product/${imgSrc}`" :alt="imgSrc" />
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div ref="ProductActionREF" class="info-section action">
          <div v-if="ProductStore.focusProduct.product_type !== 'SET'">
            <p>容量</p>
            <div :class="{ 'dropdown': true, 'hide': !dropdownExpreaded }">
              <header>{{ ProductStore.focusProduct.product_size[focusSizeIndex] }} ml</header>
              <ul>
                <li
                  v-for="size, index in ProductStore.focusProduct.product_size"
                  :key="`product_size_${index}`"
                  @click="changeFocusSizeIndex(index)"
                >{{ size }} ml</li>
              </ul>
            </div>
            <i :class="{ 'arrow': true, 'hide': !dropdownExpreaded }" @click="toggleDropDown">
              <i></i>
            </i>
          </div>
          <div>
            <p>數量</p>
            <label>
              <icon icon="dash" @click="productAmount > 1 ? productAmount -= 1 : 1"/>
              <input v-model="productAmount" readonly/>
              <icon icon="plus" @click="productAmount >= 99 ? 99 : productAmount += 1"/>
            </label>
          </div>

          <h4 :class="{discount: true}">NT${{ productAmount * ProductStore.focusProduct.product_price.ORIGIN }}</h4>
          <h3>NT${{ productAmount * ProductStore.focusProduct.product_price.MEMBER }}</h3>
          <!-- <span>
            目前本商品只有在合作實體通路販售
            <br />如有購買需求請洽 Line@
          </span>-->
          <div class="button-group">
            <button @click="addIntoCart">加入購物車</button>
            <button
              v-if="LoginStore.currentUser"
              :class="{
                'add-to-wish': true,
                'included': LoginStore.currentUser?.user_wishlist.includes(ProductStore.focusProduct.product_id)
              }"
              @click="addInfoWishlist(ProductStore.focusProduct.product_id)"
            >
              <icon icon="heart-fill" />
            </button>
          </div>

          <section class="score-info" v-if="CommentStore.focusCommentList.length">
            <div class="score">
              <div class="background">
                <img :src="IconStar" />
                <img :src="IconStar" />
                <img :src="IconStar" />
                <img :src="IconStar" />
                <img :src="IconStar" />
              </div>
              <div class="fill" :style="{ width: `${commentAvarageInfo/5*100}%` }">
                <img :src="IconStarFill" />
                <img :src="IconStarFill" />
                <img :src="IconStarFill" />
                <img :src="IconStarFill" />
                <img :src="IconStarFill" />
              </div>
            </div>

            <p>{{ commentAvarageInfo }} ({{ CommentStore.focusCommentList.length }})</p>
          </section>

          <ul>
            <li><icon icon="check-lg" /><p>單筆滿1000元免運費</p></li>
            <li><icon icon="check-lg" /><p>{{ ProductStore.focusProduct.product_uid === '2012' ? '買50ml送25ml' : '附贈市價250元試用品'}}</p></li>
            <li><icon icon="check-lg" /><p>單筆消費滿1元就送德國美容院旅行組</p></li>
            <li><icon icon="check-lg" /><p>單筆消費滿3000送植萃多酚柔膚水200ml(市價$1550)</p></li>
          </ul>
        </div>
      </div>

      <section :class="{ spread: ingredientSpread }" v-if="ProductStore.focusProduct.product_ingredients" class="product-ingredient" @click="toggleCollapse">
        <h3>產品全成分資訊</h3>
        <div class="content-wrapper" v-html="ProductStore.focusProduct.product_ingredients" @mouseup="checkSelect" ></div>
        <footer><span><icon icon="chevron-down" /></span></footer>
      </section>

      <section
        v-if="Object.keys(ProductStore.productDict).length && ProductStore.focusProduct.product_flow.length"
        class="products-use-flow"
      >
        <h3>推薦保養順序</h3>
        <div class="swiper">
          <swiper
          class="flow"
          @swiper="setFirstSwiper"
          :slides-per-view="ScreenState.mode === 'COMPUTER' ? 4 : 1"
          :space-between="ScreenState.mode === 'COMPUTER' ? 0 : 48"
          :loop="ScreenState.mode === 'MOBILE'"
        >
          <swiper-slide
            class="product"
            v-for="{ product_id, must }, index in ProductStore.focusProduct.product_flow"
            :key="`product_${ProductStore.focusProduct.product_id}_flow_${product_id}`"
            @click="Router.push(`/product/${ProductStore.productDict[product_id].product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
          >
            <h3>{{ index+1 }}.{{ ProductStore.productDict[product_id].product_function }}</h3>
            <router-link
              class="img-wrapper"
              :to="`/product/${ProductStore.productDict[product_id].product_name_en.replace(/\s/g, '-').toLowerCase()}`"
            >
              <img
                :src="`/img/product/${ProductStore.productDict[product_id].product_uid}/${LayoutStore.layoutDict['PRODUCT_AVATAR_NAME']?.layout_content || 'avatar'}.webp`"
                :alt="ProductStore.productDict[product_id].product_name_zh"
                @click="Router.push(`/product/${ProductStore.productDict[product_id].product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
              >
              <div v-if="ScreenState.mode === 'COMPUTER'" class="mask">查看詳情</div>
              <span 
                v-if="must !== undefined" 
                :class="{ badge: true, must: must }"
              >
                {{ must ? '必須' : '非必須' }}
              </span>
            </router-link>
            <p class="product-subtitle" v-if="ProductStore.productDict[product_id].product_sub_title">
              {{ ProductStore.productDict[product_id].product_sub_title }}
            </p>
            <h2>
              {{ ProductStore.productDict[product_id].product_name_zh }} {{ ProductStore.productDict[product_id].product_size[0] }}ml
            </h2>
            <span class="product-price">
              <p :class="{ 'original-price': true }">
                NT${{ ProductStore.productDict[product_id].product_price.ORIGIN }}
              </p>
              <p>NT${{ ProductStore.productDict[product_id].product_price.MEMBER }}</p>
            </span>
            <button
              @click.stop="addIntoCart(ProductStore.productDict[product_id].product_id)"
            >加入購物車</button>
          </swiper-slide>
        </swiper>
        </div>

        <template v-if="ScreenState.mode === 'MOBILE'">
          <span class="control left" @click="swiperTo(false)"><icon icon="caret-left-fill" /></span>
          <span class="control right" @click="swiperTo"><icon icon="caret-right-fill" /></span>
        </template>
      </section>
    </header>
    <nav :class="{ 'down': LayoutSetting.showNavTopNews }">
      <ul>
        <li :class="{ active: activeMode === 'DETAIL' }" @click="changeMode('DETAIL')">產品詳細</li>
        <li :class="{ active: activeMode === 'SHIPPING' }" @click="changeMode('SHIPPING')">配送資訊</li>
        <li :class="{ active: activeMode === 'COMMENT' }" @click="changeMode('COMMENT')">使用心得</li>
      </ul>
    </nav>
    <main>

      <section class="product-detail-images" v-if="activeMode==='DETAIL'">
        <div class="img-wrapper">
          <template
            v-for="img in ProductStore.focusProduct.product_detail_images"
            :key="`${ProductStore.focusProduct.product_id}_detail_${img}`"
          >
            <router-link
              v-if="img.split('|').length === 2"
              :to="img.split('|')[1]"
              target="_blank"
            >
              <img
                :src="`/img/product/${ProductStore.focusProduct.product_uid}/detail_${img.split('|')[0].includes('.gif')
                  ? img.split('|')[0]
                  : (img.split('|')[0]+'.webp')
                }`" 
                :alt="`${ProductStore.focusProduct.product_uid}_detail_img/${img.split('|')[0]}.webp`"
              >
            </router-link>
            <img
              v-else
              :src="`/img/product/${ProductStore.focusProduct.product_uid}/detail_${img.includes('.gif') ? img : (img+'.webp')}`" 
              :alt="`${ProductStore.focusProduct.product_uid}_detail_img/${img}.webp`"
            >
        </template>
          
        </div>
      </section>
      <section v-if="activeMode==='SHIPPING'" class="product-shipping">
        <div class="content wrapper">
          <!-- <div class="chinese-new-year-extra">
            <h4>※因春節影響，送貨（到貨）時間可能延遲</h4>
            <p>由於春節全台貨物運送量增加，配送可能會有延遲的狀況，故於1月12日～1月29日出貨之商品，恕可能無法在三天配送日期內完成。</p>
            <div>
              <p class="disc">- 黑貓宅急便營業所春節營業時間調整如下1/20、1/21、1/23~1/28：9:00~17:001/22、1/29不營業。</p>
              <p class="disc">- 超商取貨服務時間調整如下1/19(四)～1/28(六)期間，超商取貨服務將暫時停止。</p>
            </div>
            <p>造成您的不便，敬請見諒。如有任何疑問，歡迎透過官方Line＠或是來信客服信箱諮詢。</p>
          </div> -->
          <h2>配送須知</h2>
          <h4>完成結帳後1-3個工作天(不含週休及國定例假日)進行出貨，出貨後配送約須2-3個工作天。</h4>

          <ol>
            <li>
              <header>配送方式</header>
              <span>目前提供超商取貨與宅配（黑貓宅急便）兩種方式。</span>
            </li>
            <li>
              <header>運費計算方式</header>
              <div class="table" v-if="ScreenState.mode === 'COMPUTER'">
                <div class="th">配送方式</div>
                <div class="th">地點</div>
                <div class="th">訂單金額</div>
                <div class="th">運費</div>
                <div class="td g2">超商取貨</div>
                <div class="td g2">本島各地各縣市、澎湖部分地區、金門部分地區(大金門、小金門）、小琉球、馬祖全部地區、綠島。</div>
                <div class="td g1">未滿 1000 元</div>
                <div class="td g1">120 元</div>
                <div class="td g1">1000 元以上</div>
                <div class="td g1">免運費</div>
                <div class="td g4">宅配到府</div>
                <div class="td g4">本島各地各縣市、澎湖部分地區、金門部分地區(大金門、小金門）、小琉球、馬祖全部地區、綠島。</div>
                <div class="td g1">未滿 1000 元</div>
                <div class="td g1">120 元</div>
                <div class="td g1">1000 元以上</div>
                <div class="td g1">免運費</div>
              </div>
              
              <template v-else>
                <p class="shipping-type">超商取貨</p>
                <div class="table">
                  <div class="th">地點</div>
                  <div class="th">訂單金額</div>
                  <div class="th">運費</div>
                  <div class="td g2">本島各地各縣市、澎湖部分地區、金門部分地區(大金門、小金門）、小琉球、馬祖全部地區、綠島。</div>
                  <div class="td g1">未滿 1000 元</div>
                  <div class="td g1">120 元</div>
                  <div class="td g1">1000 元以上</div>
                  <div class="td g1">免運費</div>
                </div>

                <p class="shipping-type">宅配到府</p>
                <div class="table">
                  <div class="th">地點</div>
                  <div class="th">訂單金額</div>
                  <div class="th">運費</div>
                  <div class="td g2">本島各地各縣市、澎湖部分地區、金門部分地區(大金門、小金門）、小琉球、馬祖全部地區、綠島。</div>
                  <div class="td g1">未滿 1000 元</div>
                  <div class="td g1">120 元</div>
                  <div class="td g1">1000 元以上</div>
                  <div class="td g1">免運費</div>
                </div>
              </template>
            </li>
            <!-- <li>
              <header>指定配送時段</header>
              <span>如選擇宅配，可選擇以下時段：上午(8:00-14:00)/下午(14:00-22:00)</span>
              <span class="extra">
                <p>- 部份區域無法指定配貨時段</p>
                <p>- 受天候、交通狀況影響或假日訂單較多時可能會延遲出貨，敬請見諒</p>
              </span>
            </li> -->
          </ol>

          <div class="line"></div>

          <h2 class="pay-type-header">付款方式</h2>
          <h4>Chris Farrell 支援以下方式進行付款</h4>
          <ol class="pay-type">
            <li>
              <header>信用卡付款</header>
              <p>Chris Farrell Taiwan 接受以下信用卡付款：</p>
              <ul>
                <li>Visa</li><li>MasterCard</li><li>JCB</li>
              </ul>
              <span>信用卡授權後，款項將會被預留，直至交易完成為止，出貨後即會進付款</span>
            </li>
            <li>
              <header>貨到付款</header>
              <p>貨到後，由宅配物流業者或超商門市進行代收款項</p>
            </li>
            <li>
              <header>匯款</header>
              <p>Chris Farrell Taiwan 官網目前無匯款服務，如需使用匯款方式，請直接聯繫我們的官方 Line@，將會有專人為您服務</p>
            </li>
          </ol>
        </div>
      </section>

      <section v-if="activeMode==='COMMENT'" class="product-comments">
        <header v-if="CommentStore.focusCommentList.length">
          <div class="total">
            <div>
              <img :src="IconStarFill">
              <p>{{ commentAvarageInfo || '-' }} / 5</p>
            </div>
            <h5>{{ CommentStore.focusCommentList.length }} 則評分</h5>
          </div>
          <div class="chart">
            <ul>
              <li v-for="commentCount, index in commentStatistics" :key="`${ProductStore.focusProduct.key_id}_cst_${index}`">
                <img :src="IconStarFill">
                <p>{{ index+1 }}</p>
                <span class="bar">
                  <i :style="{ width: `${commentCount / CommentStore.focusCommentList.length*100}%` }" />
                </span>
                <a @click="chooseFilter('comment_stars', index+1)">{{ commentCount }}則評論</a>
              </li>
            </ul>
          </div>
        </header>

        <div class="container" ref="CommentREF">
          <div class="comment-nav">
            <h3>商品心得</h3>
            <div class="action">
              <button class="reset" v-if="ScreenState.mode === 'COMPUTER'" @click="resetFilter">重置分類結果</button>
              <span class="dropdown-wrapper">
                <button class="dropdown" @click="toggleShowDropdown('toggle')">
                  {{ SorterDict[commentSorter].name }}
                <icon icon="chevron-down" /></button>
                <ul v-if="showDropdownMenu">
                  <li @click="chooseCommentSorter('comment_star_desc')">評分由高至低排序</li>
                  <li @click="chooseCommentSorter('comment_star_asc')">評分由低至高排序</li>
                  <li @click="chooseCommentSorter('comment_create_at_desc')">時間由近至遠排序</li>
                </ul>
              </span>
              <Icon icon="sliders" @click="toggleShowFilter(true)" />
            </div>

            <div :class="{ 'filters': true, 'show': showFilter }">
              <header>
                <h3>選擇特定分類評分</h3>
                <span @click="toggleShowFilter(false)"><Icon icon="x-lg" /></span>
              </header>

              <main>
                <section>
                  <h4>分數</h4>
                  <div class="button-group">
                    <button
                      v-for="i, index in Array(5).fill()"
                      :key="`comment-filters-star-${index}`"
                      @click="chooseFilter('comment_stars', index+1)"
                    >
                      <img :src="IconStarFill">
                      <p>{{ index+1 }}</p>
                    </button>
                  </div>
                </section>
                <section>
                  <h4>性別</h4>
                  <div class="button-group">
                    <button @click="chooseFilter('comment_gender', 'MALE')">生理男</button>
                    <button @click="chooseFilter('comment_gender', 'FEMALE')">生理女</button>
                    <button @click="chooseFilter('comment_gender', 'OTHERS')">其他</button>
                  </div>
                </section>
                <section>
                  <h4>年齡</h4>
                  <div class="button-group">
                    <button 
                      v-for="age, index in USER_AGE_GROUP_DICT" 
                      :key="`comment-filters-age-${index}`"
                      @click="chooseFilter('user_age_group', index)"
                    >
                      {{ age }}
                    </button>
                  </div>
                </section>
                <section>
                  <h4>膚質</h4>
                  <div class="button-group">
                    <button 
                      v-for="skin in Object.keys(SKIN_TYPE_DICT)" 
                      :key="`comment-filters-skin-${skin}`"
                      @click="chooseFilter('comment_skin_type', skin)"
                    >
                      {{ SKIN_TYPE_DICT[skin] }}
                    </button>
                  </div>
                </section>
                <button class="block-button reset" v-if="ScreenState.mode === 'MOBILE'" @click="resetFilter">重置評分結果</button>
              </main>
            </div>
          </div>
          <ul class="comment-list">
            <li
              v-for="comment in computedCommentList.slice(pagination*5, (pagination+1)*5)"
              :key="`comment_${comment.comment_id}`"
            >
              <header>
                <div>
                  <img :src="comment.user_group === 'ORIGINAL' ? IconVip : IconMember">
                  <b>{{ comment.user_name }}</b>
                </div>
                <ul>
                  <li>{{ SKIN_TYPE_DICT[comment.comment_skin_type] }}</li>
                  <li>{{ USER_AGE_GROUP_DICT[comment.user_age_group] }}</li>
                  <li>{{ comment.user_gender }}</li>
                </ul>
              </header>
              <main>
                <h5>{{ comment.comment_title }}</h5>
                <span>{{ comment.comment_content }}</span>
              </main>
              <footer>
                <div class="score">
                  <div class="background">
                    <img :src="IconStar" />
                    <img :src="IconStar" />
                    <img :src="IconStar" />
                    <img :src="IconStar" />
                    <img :src="IconStar" />
                  </div>
                  <div class="fill" :style="{ width: `${comment.comment_stars / 5 * 100}%` }">
                    <img :src="IconStarFill" />
                    <img :src="IconStarFill" />
                    <img :src="IconStarFill" />
                    <img :src="IconStarFill" />
                    <img :src="IconStarFill" />
                  </div>
                </div>
                <p>{{ timestampParse(comment.comment_create_at).format('YYYY.MM.DD') }}</p>
              </footer>
            </li>
            <li class="new-comment">
              <transition name="collapse-comment">
                <div v-if="!showNewCommentForm" class="icons" @click="showCommentForm">
                  <span><Icon icon="plus-lg" /></span>
                  <p>分享使用心得</p>
                </div>
              </transition>
              
              <transition name="collapse-comment-form">
                <div v-if="showNewCommentForm" class="new-form">
                  <header>
                    <div>
                      <img :src="LoginStore.currentUser.user_group === 'ORIGINAL' ? IconVip : IconMember">
                      <b>{{ newCommentFormData.comment_only_show_firstname
                        ? LoginStore.currentUser.user_name[0]+'〇〇'
                        : LoginStore.currentUser.user_name[0]+'〇'+LoginStore.currentUser.user_name[2]
                      }}</b>
                      <button @click="toggleShowFirstName">只顯示姓氏</button>
                    </div>
                    <div class="score">
                      <p>給予評分</p>
                      <img
                        v-for="i, index in Array(5).fill()"
                        :src="newCommentFormData.comment_stars>index ? IconStarFill : IconStar"
                        :key="`new-comment-form-score${ProductStore.focusProduct.key_id}-${index}`"
                        @click="chooseScore(index+1)"
                      >
                    </div>
                  </header>
                  <main>
                    <div class="gender" v-if="!LoginStore.currentUser.user_gender">
                      <h5>選擇性別</h5>
                      <div class="button-group">
                        <button
                          :class="{choosed:newCommentFormData.comment_gender === 'MALE'}"
                          @click="chooseAgeRange(0)"
                        >生理男</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_gender === 'FEMALE'}"
                          @click="chooseAgeRange(0)"
                        >生理女</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_gender === 'OTHERS'}"
                          @click="chooseAgeRange(0)"
                        >其他</button>
                      </div>
                    </div>
                    <div class="age-range" v-if="!LoginStore.currentUser.user_birthday">
                      <h5>選擇年齡區間</h5>
                      <div class="button-group">
                        <button
                          :class="{choosed:newCommentFormData.comment_age_range === 0}"
                          @click="chooseAgeRange(0)"
                        >20歲以下</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_age_range === 1}"
                          @click="chooseAgeRange(1)"
                        >21~30歲</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_age_range === 2}"
                          @click="chooseAgeRange(2)"
                        >31~40歲</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_age_range === 3}"
                          @click="chooseAgeRange(3)"
                        >41~50歲</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_age_range === 4}"
                          @click="chooseAgeRange(4)"
                        >51~60歲</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_age_range === 5}"
                          @click="chooseAgeRange(5)"
                        >61歲以上</button>
                      </div>
                    </div>
                    <div class="skin_type">
                      <h5>選擇膚質</h5>
                      <div class="button-group">
                        <button
                          :class="{choosed:newCommentFormData.comment_skin_type === 'dry'}"  
                          @click="chooseSkin('dry')"
                        >乾性肌</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_skin_type === 'oil'}"  
                          @click="chooseSkin('oil')"
                        >油肌</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_skin_type === 'combination'}"  
                          @click="chooseSkin('combination')"
                        >混合肌</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_skin_type === 'sensitive'}"  
                          @click="chooseSkin('sensitive')"
                        >敏感肌</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_skin_type === 'fragile'}"  
                          @click="chooseSkin('fragile')"
                        >特殊美容</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_skin_type === 'tough'}"  
                          @click="chooseSkin('tough')"
                        >毛孔粗大</button>
                        <button
                          :class="{choosed:newCommentFormData.comment_skin_type === 'others'}"    
                          @click="chooseSkin('others')"
                        >其他</button>
                      </div>
                    </div>

                    <div class="content">
                      <label for="comment-title">標題
                        <input
                          name="comment-title"
                          type="text"
                          placeholder="最多可輸入30字"
                          maxlength="30"
                          v-model="newCommentFormData.comment_title"
                        >
                      </label>

                      <label for="comment-content">心得內文
                        <textarea
                        name="comment-content"
                        maxlength="200"
                        placeholder="最多200字以內請輸入正文"
                        v-model="newCommentFormData.comment_content"
                      ></textarea>
                      </label>
                    </div>
                  </main>
                  
                  <button @click="handleCancel">取消</button>
                  <button class="submit" @click="handleCommentSubmit">送出</button>
                </div>
              </transition>
            </li>
          </ul>
          <div class="pagination" v-if="computedCommentList.length">
            <ul>
              <li @click="incrementPage(pagination>0 ? -1 : 0)"><icon icon="chevron-left"/></li>
              <li
                v-for="i, index in Array(Math.ceil(computedCommentList.length/5)).fill()"
                :key="`comment-page-${index}`"
                :class="{ 'focus': pagination === index }"
                @click="pagination != index ? changePagination(index) : void 8"
              >{{ index+1 }}</li>
              <li @click="incrementPage(pagination<Math.ceil(computedCommentList.length/5)-1 ? 1 : 0)">
                <icon icon="chevron-right"/>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>

    <section
        v-if="Object.keys(ProductStore.productDict).length && ProductStore.focusProduct.product_flow.length"
        class="products-use-flow"
      >
        <h3>推薦保養順序</h3>
        <div class="swiper">
          <swiper
          class="flow"
          @swiper="setSecondSwiper"
          :slides-per-view="ScreenState.mode === 'COMPUTER' ? 4 : 1"
          :space-between="ScreenState.mode === 'COMPUTER' ? 0 : 48"
          :loop="ScreenState.mode === 'MOBILE'"
        >
          <swiper-slide
            class="product"
            v-for="{ product_id, must }, index in ProductStore.focusProduct.product_flow"
            :key="`product_${ProductStore.focusProduct.product_id}_flow_${product_id}`"
            @click="Router.push(`/product/${ProductStore.productDict[product_id].product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
          >
            <h3>{{ index+1 }}.{{ ProductStore.productDict[product_id].product_function }}</h3>
            <router-link
              class="img-wrapper"
              :to="`/product/${ProductStore.productDict[product_id].product_name_en.replace(/\s/g, '-').toLowerCase()}`"
            >
              <img
                :src="`/img/product/${ProductStore.productDict[product_id].product_uid}/avatar.webp`"
                :alt="ProductStore.productDict[product_id].product_name_zh"
                @click="Router.push(`/product/${ProductStore.productDict[product_id].product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
              >
              <div v-if="ScreenState.mode === 'COMPUTER'" class="mask">查看詳情</div>
              <span 
                v-if="must !== undefined" 
                :class="{ badge: true, must: must }"
              >
                {{ must ? '必須' : '非必須' }}
              </span>
            </router-link>
            <p class="product-subtitle" v-if="ProductStore.productDict[product_id].product_sub_title">
              {{ ProductStore.productDict[product_id].product_sub_title }}
            </p>
            <h2>
              {{ ProductStore.productDict[product_id].product_name_zh }} {{ ProductStore.productDict[product_id].product_size[0] }}ml
            </h2>
            <span class="product-price">
              <p :class="{ 'original-price': true }">
                NT${{ ProductStore.productDict[product_id].product_price.ORIGIN }}
              </p>
              <p>NT${{ ProductStore.productDict[product_id].product_price.MEMBER }}</p>
            </span>
            <button
              @click.stop="addIntoCart(ProductStore.productDict[product_id].product_id)"
            >加入購物車</button>
          </swiper-slide>
        </swiper>
        </div>

        <template v-if="ScreenState.mode === 'MOBILE'">
          <span class="control left" @click="swiperTo2(false)"><icon icon="caret-left-fill" /></span>
          <span class="control right" @click="swiperTo2"><icon icon="caret-right-fill" /></span>
        </template>
      </section>

    <div :class="{'footer-action': true, 'show': showFooterAction}">
      <div v-if="ScreenState.mode === 'COMPUTER'">
        <img :src="`/img/product/${ProductStore.focusProduct.product_uid}/avatar.webp`" alt="">
        <h1>{{ ProductStore.focusProduct.product_name_zh }}</h1>
      </div>
      <div  v-if="ScreenState.mode === 'COMPUTER'">
        <div class="counter">
          <span @click="productAmount > 1 ? productAmount -= 1 : 1"><icon icon="dash"/></span>
          <input v-model="productAmount" />
          <span  @click="productAmount += 1"><icon icon="plus"/></span>
        </div>
        <p class="price-amount">
          NT${{ productAmount * ProductStore.focusProduct.product_price.MEMBER }}
        </p>
        <button @click="addIntoCart">加入購物車</button>
      </div>
      <div v-else class="want-buy" @click="wantBuy">立即購買 </div>
    </div>
  </div>
</template>
