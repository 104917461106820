import { defineStore } from "pinia";
import { timestampParse } from '@/utils'
import { useLoginStore } from './login'
import database from '@/utils/database'
import { getLocal } from "../utils";
import moment from 'moment'

export const useCouponStore = defineStore({
  id: 'Coupon',
  state: () => ({
    couponList: []
  }),
  getters: {
    couponDict() {
      if (this.couponList.length) {
        return this.couponList.reduce((acc, c) => ({
          ...acc,
          [c.coupon_uid]: ({ ...c })
        }), {})
      }
      return {}
    },
    couponIdDict() {
      if (this.couponList.length) {
        return this.couponList.reduce((acc, c) => ({
          ...acc,
          [c.coupon_id]: ({ ...c })
        }), {})
      }
      return {}
    },
    memberCoupons() {
      return this.couponList.length ? this.couponList.filter(c => c.coupon_scope === 'MEMBER') : []
    }
  },
  actions: {
    async queryCoupon() {
      const LoginStore = useLoginStore()
      console.log(LoginStore.currentUser);
      if (LoginStore.ownCouponIds) {
        const res = await database.table('coupon')
          .where('coupon_id', 'in', LoginStore.ownCouponIds)
          .get()
        this.couponList = res.data.map(e => ({
          ...e,
          coupon_product: e.coupon_product ? e.coupon_product.split(',') : null,
          coupon_giveaway: e.coupon_giveaway ? e.coupon_giveaway.split(',') : null
        }))
        const localCoupon = getLocal('couponUsed') ?  getLocal('couponUsed').filter(c => c.coupon_scope === 'ALL') : []
        this.couponList = [...this.couponList, ...localCoupon]
      }
    },
    async getCouponeWithUid(uid, scope="ALL") {
      const LoginStore = useLoginStore()
      const userCouponsUsed = LoginStore.currentUser?.user_coupons_used || []
      const result = this.couponList.filter(c => c.coupon_uid === uid)
      if (result.length) {
        return { state: 200, coupon: result[0] }
      }
      const publicCouponResult = await database.table('coupon')
        .where('coupon_uid', '=', uid)
        .where('coupon_scope', '=', scope)
        .get()
      if (publicCouponResult.data.length) {
        const coupon = publicCouponResult.data.map(e => ({
          ...e,
          coupon_product: e.coupon_product ? e.coupon_product.split(',') : null,
          coupon_giveaway: e.coupon_giveaway ? e.coupon_giveaway.split(',') : null
        }))[0]
        console.log(userCouponsUsed.filter(id => id === coupon.coupon_id).length, coupon.coupon_id);
        if (coupon.coupon_quantity !== null && coupon.coupon_quantity <= 0) return { state: 500, coupon }
        if (coupon.coupon_exp && coupon.coupon_exp < moment().unix()) return { state: 501 }
        if (coupon.coupon_max_usage && userCouponsUsed.filter(id => id === coupon.coupon_id).length >= coupon.coupon_max_usage) return { state: 502 }
        this.couponList.push(coupon)
        return { state: 200, coupon}
      }
      
      return { state: 400 }
    },
    async usePublicCoupon(idArray) {
      await database.table('coupon')
        .where('coupon_id', 'in', idArray)
        .update({ coupon_quantity: database.FieldValue.increment(-1) })
    }
  }
})
