<script setup>
import ProductExploreBabnner from '@/assets/img/productExploreBabnner.webp'
import CVS_FAMI_WEBP from '@/assets/img/CVS_FAMI.webp'
import CVS_UNIMART_WEBP from '@/assets/img/CVS_UNIMART.webp'
import { onMounted, ref, inject, nextTick, reactive } from "vue";
import { useGroupStore } from "@/store/group";
import { useRoute, useRouter } from "vue-router";
import { checkEmail, checkPhoneNumber, getLocal, saveToLocal, getElementPositionYWithLoop, fetchApi, timestampParse } from '@/utils'
import Icon from '@/components/Icon'
import moment from 'moment'

const Route = useRoute()
const Router = useRouter()
const GroupStore = useGroupStore()
const focus_group_project = ref(null)
const CVSInfo = ref({})
const userInfo = reactive({ name: '', phone: '', email: '' })
const productQuantity = ref(1)
const ScreenState = inject('ScreenProvider')
const $message = inject('$message')
const PageStep = ref('BUY')
const CART = ref(null)
onMounted(async () => {
  await GroupStore.queryGroupProjects()
  if (Route.params.address && GroupStore.group_project_list.map(p => p.group_project_address).includes(Route.params.address) ) {
    console.log(GroupStore.group_project_list);
    focus_group_project.value =  GroupStore.group_project_list.filter(p => p.group_project_address === Route.params.address)[0]
    productQuantity.value = getLocal(`group_${focus_group_project.value.group_project_id}_product_quantity`) || 1
  }
  else {
    Router.replace('/404')
  }

  if (Route.query.fromcvs === '1') {
    const { CVSStoreID, CVSStoreName, CVSAddress, LogisticsSubType } = Route.query
    CVSInfo.value = {
      CVSStoreID, CVSStoreName, CVSAddress, LogisticsSubType
    }
    const positionY = getElementPositionYWithLoop(CART.value)
    window.scrollTo({ top: ScreenState.mode === "MOBILE" ? positionY - 52  : positionY - 112, behavior: 'smooth' })
  }
});

function scrollToCart() {
  const positionY = getElementPositionYWithLoop(CART.value)
  window.scrollTo({ top: ScreenState.mode === "MOBILE" ? positionY - 52  : positionY - 112, behavior: 'smooth' })
}

async function handleQuantityChange(bool) {
  if (bool) {
    productQuantity.value++
  } else {
    if (productQuantity.value > 1) {
      productQuantity.value--
    }
  }
  saveToLocal(`group_${focus_group_project.value.group_project_id}_product_quantity`, productQuantity.value)
}

const ecpayMapREF = ref(null)
const ecpayMapHtml = ref()
async function handleChooseCVS(LogisticsSubType) {
  ecpayMapHtml.value = true
  await nextTick()
  console.log(ecpayMapREF.value);
  const form = ecpayMapREF.value.getElementsByTagName('form')[0]
  form.setAttribute('action', 'https://logistics.ecpay.com.tw/Express/map') //上線前修改成正式環境
  console.log(window.location.hostname);
  form.innerHTML = `
    <input name="ServerReplyURL" value="https://${window.location.hostname}/api/map-back-group/">
    <input name="MerchantID" value="${process.env.VUE_APP_ECPAY_LOGISTICS_C2C_MID}">
    <input name="IsCollection" value="Y">
    <input name="LogisticsType" value="CVS">
    <input name="LogisticsSubType" value="${LogisticsSubType}">
    <input name="Device" value="${ScreenState.mode === 'MOBILE' ? '1' : '0'}">
    <input name="ExtraData" value="${Route.params.address}">
  `//上線前修改成正常網域
  console.log(form);
  form.submit()
}

const loading = ref(false)
async function handleOrderSubmit() {
  if (!userInfo.name) return $message.warning('請輸入姓名')
  if (!checkEmail(userInfo.email)) return $message.warning('Email格式錯誤')
  if (!checkPhoneNumber(userInfo.phone)) return $message.warning('手機號碼格式錯誤')
  if (!CVSInfo.value.CVSStoreID) return $message.warning('請選擇門市')
  loading.value = true
  const orderUidRes = await fetchApi('/api/genUid/', {});
  if (orderUidRes.id) {
    console.log(CVSInfo);
    console.log(CVSInfo.value.LogisticsSubType);
    const logisticRes = await fetchApi('/api/ecpay/', { api:'/Express/Create', shippingInfo: {
      MerchantTradeDate: moment().format('yyyy/MM/DD HH:mm:ss'),
      LogisticsType: 'CVS',
      LogisticsSubType: CVSInfo.value.LogisticsSubType,
      ReceiverStoreID: CVSInfo.value.CVSStoreID,
      ReturnStoreID: '177418', //7-11 指定退貨門市為新北大門市
      IsCollection: 'Y',
      GoodsAmount:  productQuantity.value * 500 > 999 ? productQuantity.value * 500 : productQuantity.value * 500 + 70,
      GoodsName: '保養品',
      SenderName: '顏爾佑',
      SenderCellPhone: '0988879595',
      ReceiverName: userInfo.name,
      ReceiverCellPhone: userInfo.phone,
      ReceiverEmail: userInfo.email,
      TradeDesc: 'Chris Farrell Taiwan 保養品',
      ServerReplyURL: 'https://api.chris-farrell.com.tw/index.php/order/ecpay'
    }})
    console.log(logisticRes);
    if (logisticRes.state === '1') {
      const orderRes = await GroupStore.createGroupOrder({
        group_project_id: focus_group_project.value.group_project_id,
        group_order_uid: orderUidRes.id,
        group_order_email: userInfo.email,
        group_order_name: userInfo.name,
        group_order_phone: userInfo.phone,
        group_order_amount: productQuantity.value * 500 > 999 ? productQuantity.value * 500 : productQuantity.value * 500 + 70,
        group_order_product: JSON.stringify({
          product_id: '14',
          product_quantity: productQuantity.value
        }),
        group_order_logistics_id: logisticRes.data.MerchantTradeNo,
        group_order_logistics_info: JSON.stringify(logisticRes.data)
      })
      console.log(orderRes);
      PageStep.value = 'RESULT'
      $message.success('已成功建立訂單')
    } else {
      $message.error('物流系統出現問題，請聯繫客服')
      sendError(
        'ORDER_LOGISTICS',
        'https://logistics-stage.ecpay.com.tw/Express/Create',
        JSON.stringify([PaymentStore.shippingInfo, PaymentStore.cvsInfo]),
        JSON.stringify(logisticRes)
      )
    }
  } else {
    $message.error('訂單生成系統出現問題，請聯繫客服')
    sendError(
      'ORDER_UID',
      '/api/genUid/',
      JSON.stringify(orderUidRes)
    )
  }
  loading.value = false
}

function toProducts() {
  Router.push('/product')
}
</script>

<template>
  <div class="view-group view-product" v-if="GroupStore.group_project_list && GroupStore.group_project_list.length">
    <header class="hero" :style="{ backgroundImage: `url(${ProductExploreBabnner})` }">
      <div class="mask">
        <div class="content" v-if="focus_group_project">
          <h2>絲蛋白護手霜</h2>
          <h1>單瓶 $500 團購活動</h1>
          <p
            v-if="focus_group_project && timestampParse(focus_group_project.group_project_end).unix() > moment().unix()"
          >活動期間：{{timestampParse(focus_group_project.group_project_start).format('YYYY/MM/DD')}} ~ {{timestampParse(focus_group_project.group_project_end).format('YYYY/MM/DD')}}</p>
          <p v-else>活動已截止</p>
        </div>
      </div>
      <div class="desc">
        <p v-if="focus_group_project && timestampParse(focus_group_project.group_project_end).unix() > moment().unix()">
          本活動期限至 {{ timestampParse(focus_group_project.group_project_end).format('YYYY/MM/DD') }} 晚上12:00 截止，未滿 $1000 須額外負擔運費 NT$70
        </p>
        <p v-else>本活動已於 {{ timestampParse(focus_group_project.group_project_end).format('YYYY/MM/DD') }} 截止</p>
      </div>
    </header>

    <main v-if="focus_group_project && timestampParse(focus_group_project.group_project_end).unix() > moment().unix()">
      <header>
        <h3>團購商品</h3>
      </header>
      <section class="products">
        <div class="product">
          <div class="card">
            <div class="image-wrapper"><img :src="require(`@/assets/img/star_product_3.png`)" /></div>
            <div class="tag">＃保持水嫩雙手肌膚</div>
            <div class="price">＄500（原價$800）</div>
            <p>Silky Hand</p>
            <h2>絲蛋白護手霜</h2>
          </div>
          <div class="content-wrapper">
            <h4>每次不到7元<br/>就能享受德國美容院級的手部保養</h4>
            <p>強化防護 - 羊毛脂能為肌膚形成保護膜，抵禦來自環境的多重傷害</p>
            <p>留住水分 - 植萃精華中的天然油脂能夠預防水分流失，避免粗糙、龜裂</p>
            <p>柔嫩肌膚 - 軟化粗糙肌膚，讓肌膚重拾彈性</p>
            <p>抗老抗皺 - 乳木果油、抗壞血酸棕櫚酸酯等成分能有效延緩皮膚老化</p>

            <button @click="scrollToCart">我要下單</button>
          </div>
        </div>
      </section>
    
      <section ref="CART" class="action" v-if="PageStep === 'BUY'">
        <div class="cart-wrapper">
          <h2>團購購物車</h2>
          <ul>
            <li>
              <img src="/img/product/2080/avatar.webp">
              <div class="info">
                <h4>Silky Hand</h4>
                <h3>絲蛋白護手霜</h3>
                <p>50ml * {{ productQuantity }}</p>
              </div>
              <div class="quantity">
                <button @click="handleQuantityChange(false)"><icon icon="dash" /></button>
                <span>{{ productQuantity }}</span>
                <button @click="handleQuantityChange(true)"><icon icon="plus" /></button>
              </div>
              <div class="price">
                <span>{{ productQuantity }} x NT$500</span>
                <p>NT${{ productQuantity * 500 }}</p>
              </div>
            </li>
            <li>
              <img src="/img/product/9901/avatar.webp">
              <div class="info">
                <h4>German Salon Travel Set</h4>
                <h3>德國美容院旅行組</h3>
                <p>隨單贈送1組</p>
              </div>
              <div>
                <h3 :style="{paddingLeft: ScreenState.mode === 'COMPUTER' ? '60px' : 0}">1</h3>
              </div>
              <div class="price">
                <p>贈</p>
              </div>
            </li>
          </ul>
        </div>

        <div class="shipping">
          <h2>出貨資訊</h2>
          <div class="form">
            <header>
              <div class="circle"></div>
              <h3>超商取貨付款</h3>
              <div class="button-group">
                <button @click="handleChooseCVS('UNIMARTC2C')"><img :src="CVS_UNIMART_WEBP">選擇 7-11 門市</button>
                <button @click="handleChooseCVS('FAMIC2C')"><img :src="CVS_FAMI_WEBP">選擇全家門市</button>
              </div>
            </header>
            <main>
              <input type="text" v-model="userInfo.name" placeholder="收件人姓名">
              <input type="text" v-model="userInfo.phone" placeholder="手機號碼">
              <input type="text" v-model="userInfo.email" placeholder="電子郵件">
              <input
                type="text"
                :value="CVSInfo.LogisticsSubType ? `${CVSInfo.LogisticsSubType === 'UNIMARTC2C' ? '7-11' : '全家'} - ${CVSInfo.CVSStoreName}` : ''"
                placeholder="門市名稱" readonly
              >
              <input type="text" v-model="CVSInfo.CVSAddress" placeholder="未選擇門市地址" readonly
              >
              <span class="extra"></span>
            </main>
            <footer>
              <h3>總結帳金額：NT${{ productQuantity * 500 }} {{ productQuantity * 500 >= 999 ? '' : `+ 運費 NT$70 = NT$${productQuantity * 500 + 70}` }}</h3>
              <button :class="{ 'loading-button': true, 'loading': loading || null }" @click="handleOrderSubmit">送出訂單</button>
            </footer>
          </div>
        </div>
      </section>
      <div v-else class="view-result">
        <section>
          <icon icon="bag-check-fill"/>
          <h2>已建立團購訂單</h2>
          <p>我們已收到您的訂單，我們將儘速為您安排出貨</p>
          <button @click="toProducts">逛逛其他商品</button>
        </section>
      </div>
    </main>
  </div>
  <div v-else>
    <p>NONE</p>
  </div>
  <div class="ecpay-map-modal" v-if="ecpayMapHtml">
      <main>
        <section ref="ecpayMapREF" class="map">
          <form action="" method="POST"></form>
        </section>
      </main>
    </div>
</template>
