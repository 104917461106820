<script setup>
import { onMounted, ref, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import { usePostStore } from '../store/post';
import { getElementPositionY, getElementPositionYWithLoop } from '@/utils'
import Icon from '@/components/Icon'
const PostStore = usePostStore()
const route = useRoute()
const ScreenState = inject('ScreenProvider')

const postContentREF = ref()
const navREF = ref()
const sectionHeaders = ref([])

onMounted(async () => {
  if (!PostStore.postList.length) PostStore.queryPost()
  PostStore.currentPostTitle = route.params.postTitle
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

watch(postContentREF, async newVal => {
  if (newVal) {
    //確認所有圖片都已經 Loading 載入完畢
    const promises = Array.from(newVal.getElementsByTagName('img')).map(e => new Promise((res) => {
      if (e.complete) res()
      e.onload = () => res()
    }))
    await Promise.all(promises)

    //計入所有章首Ｙ軸位置並 Render Nav
    sectionHeaders.value = []
    const sectionHeaderElements = newVal.getElementsByClassName('chapter-header')
    console.log(sectionHeaderElements);
    for (let el of sectionHeaderElements) {
      let positionY = getElementPositionYWithLoop(el)
      console.log(positionY);
      const name = Array.from(el.childNodes).filter(node => node.className === 'section-header')[0].id
      sectionHeaders.value.push({ name, yStart: positionY, yEnd: positionY + el.scrollHeight })
    }
  }
})


const windowHeight = window.innerHeight
const scrollTo = (yStart, removeNavHeight=true) => {
  const navHeight = removeNavHeight ? navREF.value.offsetHeight : 0
  window.scrollTo({ top:ScreenState.mode === "MOBILE" ? yStart - 52 + navHeight  : yStart - 112 + navHeight, behavior: 'smooth' })
}

const titleRender = title => {
  return title.replace(/[\:：]/g, '：<br>')
}
</script>

<template>
  <div class="view-post">
    <article v-if="PostStore.postList.length && PostStore.currentPostTitle">
      <header>
        <div class="mask">
          <h1 v-html="titleRender(PostStore.currentPost.post_title)"/>
          <span class="line" />
          <div class="desc" v-html="PostStore.currentPost.post_summary.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp')"/>
          <button @click="scrollTo(windowHeight, false)">
            <p>More</p>
            <span>
              <icon icon="arrow-down-short" />
            </span>
          </button>
        </div>
        <!-- <img
          :src="`../${PostStore.currentPost.post_bannerPath}`"
          :alt="PostStore.currentPost.post_banner"
        /> -->
      </header>

      <nav ref="navREF" v-if="sectionHeaders.length">
        <h2>Table of Contents</h2>
        <ul>
          <li
            v-for="section in sectionHeaders"
            :key="section.name"
            @click="scrollTo(section.yStart)"
          >{{ section.name }}</li>
        </ul>
      </nav>

      <section class="content-wrapper">
        <div class="content" ref="postContentREF" v-html="PostStore.currentPost.post_content"></div>
      </section>
    </article>
  </div>
</template>
