<script setup>
import Icon from '@/components/Icon.vue'
import moment from 'moment'
import { inject, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useLoginStore } from '../store/login'
const loginStore = useLoginStore()
const router = useRouter()

const loginInfo = reactive({ account: '', password: '' })
const showPassword = ref(false)
const $message = inject('$message')

const toRegister = () => router.push('/register')
const loading = ref(false)
const handleLogin = async () => {
  if (!!loginStore.currentUser) return $message.warning('目前已登入會員帳號，若要登入其他帳號請先登出')
  loading.value = true
  if (loginInfo.account && loginInfo.password) {
    const result = await loginStore.loginWithEmail(loginInfo)
    if (result.status === 200) {
      $message.success('登入成功')
      router.push('/product')
    } else {
      alert(result.msg)
      loading.value = false
    }
  } else {
    alert('請確認帳號與密碼欄位是否為空')
    loading.value = false
  }
}

const handleLineLogin = () => {
  if (!!loginStore.currentUser) return $message.warning('目前已登入會員帳號，若要登入其他帳號請先登出')
  const lineLoginUri = 'https://access.line.me/oauth2/v2.1/authorize'
  const urlObj = new URL(window.location)
  const timestamp = moment().valueOf()
  const randomNum = parseInt(Math.random()*10000)
  const loginRequest = {
    response_type: 'code',
    client_id: process.env.VUE_APP_LINE_CHANNEL_ID,
    redirect_uri: `https://test.chris-farrell.com.tw/api/line/login`,
    scope: 'profile%20openid%20email',
    nonce: 'ChrisFarrellLineLogin',
    state: `${timestamp}${randomNum}`
  }
  const combiUri = `${lineLoginUri}?${Object.entries(loginRequest).map(entries => entries[0]+'='+entries[1]).join('&')}`
  window.location = combiUri
}

const handleFBLogin = async () => {
  if (!!loginStore.currentUser) return $message.warning('目前已登入會員帳號，若要登入其他帳號請先登出')
  const result = await loginStore.loginWithFB()
  if (result.state === 200) {
    $message.success('登入成功')
    router.push('/product')
  }
  if (result.state === 405) {
    $message.warning('FB 登入錯誤，請重新整理頁面')
  }
  else [
    router.push(`/register?login=fb&cus=${result.user_id}`)
  ]
}
</script>

<template>
  <div class="view-login">
    <section class="login">
      <div class="email">
        <h3>帳號登入</h3>
        <input type="text" name="email" placeholder="Email" v-model="loginInfo.account" />
        <input
          :type="showPassword ? 'text' : 'password'"
          placeholder="密碼"
          name="password"
          v-model="loginInfo.password"
          @keydown.enter="handleLogin"
        />
        <a @click="router.push('/new-password')">忘記密碼</a>
        <button :class="{'anime-button': true, 'loading': loading}" @click="handleLogin">登入</button>
      </div>
      <div class="social-media">
        <h3>社群登入</h3>
        <div class="button-group">
          <button class="line-login" @click="handleLineLogin"><icon icon="line" /> Line 登入</button>
          <button class="fb-login" @click="handleFBLogin"><icon icon="facebook" /> Facebook 登入</button>
        </div>
      </div>
    </section>
    <section class="sign-up">
      <header>
        <h2>立即註冊</h2>
        <p>建立會員帳戶只需填妥相關資料，即可享有 Chris Farrell 臺灣官網獨家優惠</p>
      </header>
      <ul>
        <li>
          <Icon icon="cash-coin"></Icon>
          <p>註冊即享臺灣官網全站會員價優惠</p>
        </li>
        <li>
          <Icon icon="hourglass-split"></Icon>
          <p>記住您的帳單和送貨資訊，迅速完成結帳</p>
        </li>
      </ul>
      <button @click="toRegister">建立帳戶</button>
      <a href="/help/privacy" target="_blank">隱私權政策</a>
    </section>
  </div>
</template>
