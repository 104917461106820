<script setup>
import { CfInput, CfCheckbox } from '@/components/Form'
import DirectPay from './components/DirectPay'
import { fetchApi, completePhoneNumber } from '@/utils'
import { sendError } from '@/utils/error'
import { getZipCode } from '@/utils/zip'
import { createLogisticOrder } from '@/utils/ecpay'
import { inject, onMounted, onBeforeUnmount, ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { usePaymentStore } from '../../store/payment'
import { useOrderStore } from '../../store/order'
import { useLoginStore } from '../../store/login'
import moment from 'moment'
import { useProductStore } from '../../store/product'
import { useEmailStore } from '../../store/email'
import { genProductTableVariables, genGiveawayTableVariables } from "./utils/email"
import { gaEvent } from '@/utils/event'
import TappayLogo from '@/assets/img/TapPay_logo.webp'

const receiptType = [
  { text: '一般發票', value: 'NORMAL' },
  { text: '開立統一編號', value: 'UNIFORM_NUMBER' },
]

const $message = inject('$message')
const Props = defineProps({ nextStep: Function, lastStep: Function })
const loading = ref(false)
const dropdownExpreaded = ref(false)
const currentReceiptType = ref({ text: '一般發票', value: 'NORMAL' })
const firmName = ref('')
const unicode = ref('')
const agreeTerms = ref(false)
const directPayComponentRef = ref(null)

let stageTimeoutInternalInst
const stageTimeout = ref(0)
const infoErrorTimes = ref(0)
onMounted(() => {
  stageTimeoutInternalInst = setInterval(() => {
    stageTimeout.value += 1
    console.log(stageTimeout.value);
  }, 1000);
})
onBeforeUnmount(() => {
  clearInterval(stageTimeoutInternalInst)
})

const toggleDropDown = () => dropdownExpreaded.value = !dropdownExpreaded.value
const chooseType = type => {
  currentReceiptType.value = type
  dropdownExpreaded.value = false
}

const PaymentStore = usePaymentStore()
const LoginStore = useLoginStore()
const OrderStore = useOrderStore()
const ProductStore = useProductStore()
const EmailStore = useEmailStore()
const getPrice = async () => {
  const API = '/api/getPrice/'
  if (PaymentStore.cartItems.length) {
    console.log(PaymentStore.couponUsed);
    const res = await fetchApi(API, {
      items: { ...PaymentStore.cartItems },
      coupons: [...PaymentStore.couponUsed],
      vouchers: PaymentStore.voucherUsed
    })
    return res
  }
}

async function sendOrderEmail(orders_id, order_products, order_amount, coupons_used) {
  const products = order_products.map(item => {
    console.log(item);
    console.log(ProductStore.productDict[item.product_id]);
    const { product_name_zh, product_name_en, product_images } = ProductStore.productDict[item.product_id]
    return {
      product_name_zh, product_name_en, product_img: `https://www.chris-farrell.com.tw/img/product/${product_images[0]}`,
      product_price: item.product_price, product_quantity: item.product_quantity
    }
  })

  const giveaways = coupons_used.filter(c => c.coupon_type === 'GIVEAWAY').reduce((acc, coupon) => {
    console.log(coupon);
    return [
      ...acc,
      ...coupon.coupon_items.map(i => ({ ...ProductStore.productUidDict[i.product_uid], coupon_name: coupon.coupon_name }))
    ]
  }, [])
  console.log(giveaways);

  const emailTemplateUse = PaymentStore.shippingInfo.logisticsType === 'CVS' ? "ORDER_CREATE_CVS" : "ORDER_CREATE_HOME"
  let variables = {
    ordersId: orders_id,
      receiverName: PaymentStore.shippingInfo.firstName+PaymentStore.shippingInfo.lastName, 
      orderCreateAt: moment().format('YYYY/MM/DD'),
      receiverPhone: completePhoneNumber(PaymentStore.shippingInfo.user_phone), 
      shippingCost: PaymentStore.freeShippingCost, 
      discount: PaymentStore.amount - order_amount, 
      amount: order_amount, 
      sumOfCartPrice: PaymentStore.amount, 
      orderProductTable: genProductTableVariables(products),
      orderGiveawayTable: genGiveawayTableVariables(giveaways)
  }
  if (PaymentStore.shippingInfo.logisticsType === "CVS") { //超商取貨模板
    variables = {
      ...variables,
      cvsSubtype: PaymentStore.cvsInfo.LogisticsSubType === "FAMIC2C" ? '全家FamilyMart' : '統一超商', 
      cvsStore: PaymentStore.cvsInfo.CVSStoreName,
      cvsAddress: PaymentStore.cvsInfo.CVSAddress,
    }
  } else { //宅配模板
    variables = {
      ...variables,
      receiverAddress: PaymentStore.shippingInfo.user_address
    }
  }

  const recipients = [{
    name: PaymentStore.shippingInfo.firstName+PaymentStore.shippingInfo.lastName,
    address: PaymentStore.shippingInfo.user_email,
    variables
  }]
  console.log(recipients);
  const res = await EmailStore.sendEmail({
    subject: EmailStore.systemEmailDict[emailTemplateUse].email_template_subject,
    contentId: EmailStore.systemEmailDict[emailTemplateUse].email_template_id
  }, recipients)
  console.log(res);
}

const handleCodPay = async () => {
  loading.value = true
  if (!agreeTerms.value) {
    $message.warning('請確認同意「條件及條款」')
    infoErrorTimes.value += 1
    return loading.value = false
  }
  const priceRes = await getPrice()
  if (priceRes.state ===200 && priceRes.price === PaymentStore.sumOfCartPrice + PaymentStore.freeShippingCost) {
    const orderUidRes = await fetchApi('/api/genUid/', {});
    const order_coupon_used = PaymentStore.couponUsed.map(({coupon_id, coupon_uid, coupon_name, coupon_type, items}) => ({
      coupon_id, coupon_uid, coupon_name, coupon_type, coupon_items: items
    }))
    if (orderUidRes.id) {
      const { firstName, lastName, user_address, user_city, user_urban_area, user_phone, user_email, logisticsType } = PaymentStore.shippingInfo
      let logisticRes
      let order = {
          orders_id: orderUidRes.id,
          user_id: LoginStore.currentUser ? LoginStore.currentUser.user_id : undefined,
          order_phone: PaymentStore.shippingInfo.user_phone,
          order_pay_type: 'COD',
          order_receipt_type: currentReceiptType.value.value,
          order_receipt_unicode: unicode.value || undefined,
          order_receipt_company: firmName.value || undefined,
          order_logistics_info: JSON.stringify({
            AllPayLogisticsID: "",
            BookingNote: "",
            LogisticsSubType: "TCAT",
            LogisticsType: "COD",
            ReceiverAddress: user_city + user_urban_area + user_address,
            ReceiverCellPhone: user_phone,
            ReceiverEmail: user_email,
            ReceiverName: firstName + lastName
          }),
          order_logistics_type: "HOME",
          order_logistics_subtype: "TCAT",
          order_logistics_state: '[]',
          order_amount: priceRes.price,
          order_products: JSON.stringify(priceRes.products),
          order_create_at: 'PHP_SERVER_TIMESTAMP',
          order_coupon_used: JSON.stringify(order_coupon_used),
          order_voucher_used: priceRes.vouchers || undefined
        }
      if (logisticsType === 'CVS') {
        logisticRes = await createLogisticOrder(PaymentStore, 'COD', priceRes.price)
        if (logisticRes.state === '1') {
          order = {
            ...order,
            order_logistics_info: JSON.stringify({
              ...logisticRes.data,
            }),
            order_logistics_id: logisticRes.data.MerchantTradeNo,
            order_logistics_type: "CVS",
            order_logistics_subtype: logisticRes.data.LogisticsSubType,
            order_logistics_cvs_info: JSON.stringify(PaymentStore.cvsInfo),
          }
        } else {
          $message.error('物流系統出現問題，請聯繫客服')
          sendError(
            'ORDER_LOGISTICS',
            'https://logistics-stage.ecpay.com.tw/Express/Create',
            JSON.stringify([PaymentStore.shippingInfo, PaymentStore.cvsInfo]),
            JSON.stringify(logisticRes)
          )
        }
      }

      if (parseInt(priceRes.vouchers) > 0) {
        const voucherRes = await LoginStore.voucherCost(priceRes.vouchers)
      }
      const orderRes = await OrderStore.createOrder(order)
      //google analytics event
      gaEvent('purchase', {
        order_values: priceRes.price,
        voucher_used: priceRes.vouchers,
        shipping_values: PaymentStore.freeShippingCost,
        discount_values: PaymentStore.amount - priceRes.price,
        pay_type: 'CASH_ON_DELIVERY'
      })
      const emailRes = await sendOrderEmail(orderUidRes.id, priceRes.products, priceRes.price, order_coupon_used)
      $message.success('成功建立訂單')
      Router.push(`/result?order_number=${orderUidRes.id}&type=cod`)
    } else {
      $message.error('訂單生成系統出現問題，請聯繫客服')
      sendError(
        'ORDER_UID',
        '/api/genUid/',
        JSON.stringify(orderUidRes)
      )
    }
  } else {
    $message.error('金額驗證系統出現問題，請聯繫客服')
    sendError(
      'ORDER_PRICE_CHECK',
      'https://dev.chris-farrell.com.tw/api/getPrice',
      JSON.stringify([PaymentStore.shippingInfo, PaymentStore.cvsInfo]),
      JSON.stringify({ backend: priceRes, frontEnd: PaymentStore.sumOfCartPrice, shipping: PaymentStore.freeShippingCost  })
    )
  }
}

const Router = useRouter()
async function handlePay() {
  loading.value = true
  if (!agreeTerms.value) {
    $message.warning('請確認同意「條件及條款」')
    infoErrorTimes.value += 1
    return loading.value = false
  }
  const priceRes = await getPrice()
  if (currentReceiptType.value.value === 'UNIFORM_NUMBER') {
    if (unicode.value.length !== 8) {
      $message.warning('統一編號格式錯誤，須為完整8位數編號')
      infoErrorTimes.value += 1
      return loading.value = false
    }
    if (!firmName.value) {
      $message.warning('請輸入公司抬頭')
      infoErrorTimes.value += 1
      return loading.value = false
    }
  }
  if (priceRes.state ===200 && priceRes.price === PaymentStore.sumOfCartPrice + PaymentStore.freeShippingCost) {
    const tappayRes = await directPayComponentRef.value.handlePay(priceRes.price)
    if (!tappayRes) {
      console.log('shut down');
      infoErrorTimes.value += 1
      loading.value = false
      await sendError(
        'ORDER_TAPPAY',
        'https://prod.tappaysdk.com/tpc/payment/pay-by-prime',
        JSON.stringify(priceRes),
      )
      return
    }
    //google analytics event
    gaEvent('add_payment_info', {
      stage_timeout: stageTimeout.value,
      info_error_times: infoErrorTimes.value
    })
    const logisticRes = await createLogisticOrder(PaymentStore)
    console.log(tappayRes);
    console.log(logisticRes);
    if (tappayRes && logisticRes.state === '1') {
      const order_coupon_used = PaymentStore.couponUsed.map(({coupon_id, coupon_uid, coupon_name, coupon_type, items}) => ({
        coupon_id, coupon_uid, coupon_name, coupon_type, coupon_items: items
      }))
      const orderRes = await OrderStore.createOrder({
        orders_id: tappayRes.data.order_number,
        user_id: LoginStore.currentUser ? LoginStore.currentUser.user_id : undefined,
        order_phone: logisticRes.data.ReceiverCellPhone,
        order_pay_type: 'CREDIT',
        order_payment_url: tappayRes.data.payment_url,
        order_bank_trade_id: tappayRes.data.bank_transaction_id,
        order_rec_trade_id: tappayRes.data.rec_trade_id,
        order_receipt_type: currentReceiptType.value.value,
        order_receipt_unicode: unicode.value || undefined,
        order_receipt_company: firmName.value || undefined,
        order_logistics_id: logisticRes.data.MerchantTradeNo,
        order_logistics_info: JSON.stringify({
          ...logisticRes.data
        }),
        order_logistics_type: logisticRes.data.LogisticsType,
        order_logistics_subtype: logisticRes.data.LogisticsSubType,
        order_logistics_state: '[]',
        order_logistics_cvs_info: logisticRes.data.LogisticsType === 'CVS' ? JSON.stringify(PaymentStore.cvsInfo) : undefined,
        order_amount: priceRes.price,
        order_products: JSON.stringify(priceRes.products),
        order_create_at: 'PHP_SERVER_TIMESTAMP',
        order_coupon_used: JSON.stringify(order_coupon_used),
        order_voucher_used: priceRes.vouchers || undefined
      })
      if (parseInt(priceRes.vouchers) > 0) {
        const voucherRes = await LoginStore.voucherCost(priceRes.vouchers)
      }
      //google analytics event
      gaEvent('purchase', {
        order_values: priceRes.price,
        voucher_used: priceRes.vouchers,
        shipping_values: PaymentStore.freeShippingCost,
        discount_values: PaymentStore.amount - priceRes.price,
        pay_type: 'CREDIT_CARD'
      })
      const emailRes = await sendOrderEmail(tappayRes.data.order_number, priceRes.products, priceRes.price, order_coupon_used)
      if (tappayRes.data.payment_url) window.location = tappayRes.data.payment_url
      else {
        $message.success('付款成功')
        await OrderStore.updateOrder({ order_payment_status: 'SUCCESS' })
        Router.push(`/result?order_number=${tappayRes.data.order_number}`)
      }
    } else {
      $message.error('金物流系統出現問題，請聯繫客服')
      console.log('付款；', tappayRes);
      console.log('物流；', logisticRes);
      if (!tappayRes) sendError(
        'ORDER_TAPPAY',
        'https://prod.tappaysdk.com/tpc/payment/pay-by-prime',
        JSON.stringify(priceRes)
      )
      if (logisticRes.state !== 1) sendError(
        'ORDER_LOGISTICS',
        'https://logistics.ecpay.com.tw/Express/Create',
        JSON.stringify([PaymentStore.shippingInfo, PaymentStore.cvsInfo]),
        JSON.stringify(logisticRes)
      )
    }
  } else {
    $message.error('金額驗證系統出現問題，請聯繫客服')
    console.log(priceRes.price, PaymentStore.sumOfCartPrice);
    console.log('付款故障；', priceRes);
    sendError(
      'ORDER_PRICE_CHECK',
      'https://www.chris-farrell.com.tw/api/getPrice',
      JSON.stringify([PaymentStore.shippingInfo, PaymentStore.cvsInfo]),
      JSON.stringify({ backend: priceRes, frontEnd: PaymentStore.sumOfCartPrice, shipping: PaymentStore.freeShippingCost  })
    )
  }
  loading.value = false
}
</script>

<template>
  <div class="view-pay">
    <section class="pay-info">
      <header>
        <h2>付款方式</h2>
      </header>
      <main class="pay-type-radio">
        <div class="radio-wrapper" v-if="PaymentStore.payType != 'COD'">
          <div class="left">
            <div :class="{ 'radio': true, 'choosed': true }">
              <span class="picked"></span>
            </div>
            <p>信用卡付款</p>
          </div>

          <div class="icons">
            <i class="fa-brands fa-cc-visa"></i>
            <i class="fa-brands fa-cc-mastercard"></i>
            <i class="fa-brands fa-cc-jcb"></i>
          </div>
        </div>
        <div class="radio-wrapper cod" v-else>
          <div class="left">
            <div :class="{ 'radio': true, 'choosed': true }">
              <span class="picked"></span>
            </div>
            <div>
              <h3>貨到付款</h3>
              <p>到貨時由物流公司或超商門市代為收款</p>
            </div>
          </div>
        </div>
        <DirectPay ref="directPayComponentRef" v-if="PaymentStore.payType != 'COD'" />
      </main>
    </section>
    <section class="receipt">
      <header></header>
      <main>
        <div :class="{ 'dropdown': true, 'hide': !dropdownExpreaded }">
          <header @click="toggleDropDown">
            {{ currentReceiptType?.text || '選擇發票類型' }}
            <i
              :class="{ 'arrow': true, 'hide': !dropdownExpreaded }"
            >
              <i></i>
            </i>
          </header>

          <ul>
            <li
              v-for="option, index in receiptType"
              :key="`receipt_type_${index}`"
              @click="chooseType(option)"
            >{{ option.text }}</li>
          </ul>
        </div>

        <div class="uniform-number" v-if="currentReceiptType?.value === 'UNIFORM_NUMBER'">
          <cf-input placeholder="公司抬頭" v-model="firmName" />
          <cf-input placeholder="統一編號" v-model="unicode" maxlength="8" />
        </div>

        <cf-checkbox v-model="agreeTerms">
          我同意
          <a href="/help/termsAndConditions" target="_blank">條款及條件*</a>
        </cf-checkbox>
        <span class="extra">＊請於付款前再次確認訂單內容與出貨資訊，送出後即無法再修改</span>

        <section class="info-safety-statement">
          <img :src="TappayLogo" alt="">
          <span>
            本公司採用喬睿科技 TapPay SSL 2048 bit 交易系統，消費者刷卡時直接在銀行端系統中交易，本公司絕不留下您的信用卡資料，以保障您的權益。TapPay 交易系統通過 PCI-DSS 國際信用卡組織安全稽核，周全保護您的信用卡資料安全。
          </span>
        </section>

        <div class="button-group">
          <button @click="lastStep">上一步</button>
          <button
            v-if="PaymentStore.payType != 'COD'"
            :class="{ loading, 'anime-button': true }" 
            @click="handlePay"
            :disabled="loading"
          >
            {{ !loading ? '付款' : '處理中'}}
          </button>
          <button
            v-else
            :class="{ loading, 'anime-button': true }" 
            @click="handleCodPay"
            :disabled="loading"
          >
            {{ !loading ? '送出訂單' : '處理中'}}
          </button>
        </div>
      </main>
    </section>
  </div>
</template>
