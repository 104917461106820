<script setup>
import ProductExploreBabnner from '@/assets/img/productExploreBabnner.webp'
import ProductKV2 from '@/assets/img/ProductKV2.webp'
import ProductKV3 from '@/assets/img/ProductKV3.webp'
import { inject, onMounted } from '@vue/runtime-core';
import { useIngredientStore } from '../store/ingredient';
import { useProductStore } from '../store/product';
import { useLoginStore } from '../store/login'
import { usePaymentStore } from '../store/payment'
import { useRouter, useRoute } from 'vue-router';
import { VGtag } from '@/directives'
import Icon from '@/components/Icon'
import { useLayoutStore } from '../store/layout';
import { useCommentStore } from '../store/comment'

const ScreenState = inject('ScreenProvider');
const router = useRouter()
const route = useRoute()
const ProductStore = useProductStore()
const IngredientStore = useIngredientStore()
const LayoutStore = useLayoutStore()
const CommentStore = useCommentStore()
const SKIN_TAG_DICT = {
  dry: { tag: '乾', color: '#B58B71' },
  oil: { tag: '油', color: '#E29A27' },
  sensitive: { tag: '敏', color: '#C94848' },
  combination: { tag: '混', color: '#C99748' },
}

onMounted(async () => {
  window.scrollTo(0,0)
  if (!ProductStore.productList.length) ProductStore.queryProduct()
  if (!CommentStore.commentList.length) CommentStore.queryComment()
})

const $message = inject('$message')
const LoginStore = useLoginStore()
const PaymentStore = usePaymentStore()
const addIntoCart = product_id => {
  const productIndex = PaymentStore.cartItems.map(e => e.product_id).indexOf(product_id)
  if (productIndex === -1 || (PaymentStore.cartItems[productIndex] && PaymentStore.cartItems[productIndex].product_price !== 'MEMBER')) {
    PaymentStore.addProductIntoCart({
      product_id: product_id,
      product_quantity: 1,
      product_price: 'MEMBER'
    })
  }
  else PaymentStore.editProductQuantity(product_id, 1)
  $message.success('已加入購物車')
}
</script>

<template>
  <div class="view-product-explore">
    <header>
      <div class="hero-banner" :style="{backgroundImage: `url(${ProductExploreBabnner})`}">
        <div class="content-wrapper">
          <h1>系列產品</h1>
          <span>
            Chris Farrell 系列包括保濕、美白、修護、調理、控油等功能，品項包含臉部清潔的洗面乳、臉部保養的化妝水/精華液/面霜/護手霜等保養品，基於生理學打造，用人體仿生技術為您的肌膚提供最適合的照顧
          </span>
        </div>
      </div>
      <nav></nav>
    </header>

    <section v-if="ProductStore.productAndSet.PRODUCT.length">
      <header>所有商品</header>
      <ul>
        <li
          v-for="product in ProductStore.productAndSet.PRODUCT"
          :key="product.product_uid"
          v-gtag:click.view_product="{ location_url: route.fullPath, product_uid: product.product_uid }"
          @click.stop="router.push(`/product/${product.product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
        >
          <router-link
            class="product-image-wrapper"
            :to="`/product/${product.product_name_en.replace(/\s/g, '-').toLowerCase()}`"
          >
            <div class="mask" v-if="ScreenState.mode === 'COMPUTER'">
              查看詳情
            </div>
            <div class="skin-tag" v-if="product.product_function">{{ product.product_function }}</div>
            <img :src="`img/product/${product.product_uid}/${LayoutStore.layoutDict['PRODUCT_AVATAR_NAME']?.layout_content || 'avatar'}.webp`" :alt="product.product_name_zh" />
          </router-link>
          <div class="product-info">
            <p class="product-subtitle" v-if="product.product_sub_title">{{ product.product_sub_title }}</p>
            <h2>{{ product.product_name_zh }}<br v-if="ScreenState.mode === 'MOBILE'" /> {{ product.product_size[0] }}ml</h2>
            <span class="product-price">
              <p :class="{ 'orginal-price': true}">NT${{ product.product_price.ORIGIN }}</p>
              <p>NT${{ product.product_price.MEMBER }}</p>
            </span>
            <div class="score-info" v-if="CommentStore.filterByProduct[product.product_id]">
            <div class="score">
              <div class="background">
                <icon icon="star-fill" />
                <icon icon="star-fill" />
                <icon icon="star-fill" />
                <icon icon="star-fill" />
                <icon icon="star-fill" />
              </div>
              <div class="fill" :style="{ width: `${CommentStore.filterByProduct[product.product_id].reduce((acc, c) => acc + parseInt(c.comment_stars), 0)/CommentStore.filterByProduct[product.product_id].length/5*100}%` }">
                <icon icon="star-fill" />
                <icon icon="star-fill" />
                <icon icon="star-fill" />
                <icon icon="star-fill" />
                <icon icon="star-fill" />
              </div>
            </div>

            <p>{{ Math.round(CommentStore.filterByProduct[product.product_id].reduce((acc, c) => acc + parseInt(c.comment_stars), 0)/CommentStore.filterByProduct[product.product_id].length*100)/100 }} ({{ CommentStore.filterByProduct[product.product_id].length }})</p>
          </div>
            <button
              v-gtag:click.add_to_cart="{
                location_url: route.fullPath,
                product_uid: product.product_uid,
                number_of_products: 1,
                scroll_percent: null
              }" 
              @click.stop="addIntoCart(product.product_id)"
            >加入購物車</button>
          </div>
        </li>
      </ul>
    </section>

    <section class="garantee">
      <img :src="ProductKV3" alt="">
      <div class="content-wrapper">
        <h2>試用保障，只給你最適合的產品</h2>
        <span>
          在這裡我們必須要老實說，即便是使用人體仿生成分的保養品，都無法完全排除皮膚過敏的可能性，因為人體皮膚的構造極為複雜，沒有任何一個品牌敢保證他的產品絕對不會造成過敏，因為每個人都是獨一無二的存在，對物質的過敏程度也大不相同
        </span>
        <span>
          因此我們在您在訂購 Chris Farrell 的產品時，會額外附上該產品的試用品，您可以先透過試用品確認產品適合您的皮膚且不會造成過品反應，如果造成過敏等皮膚不適，我們將全額退費給您全額退費給您以保障您的權益。<br>(*詳細退貨規則以官網公布的退款條款為準)
        </span>
      </div>
    </section>

    <section class="set-items-wrapper" v-if="ProductStore.productAndSet.SET.length">
      <header>保養組合</header>
      <ul>
        <li
          v-for="product in ProductStore.productAndSet.SET"
          :key="product.product_uid"
          @click.stop="router.push(`/product/${product.product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
        >
          <router-link class="product-image-wrapper" :to="`/product/${product.product_name_en.replace(/\s/g, '-').toLowerCase()}`">
            <img :src="`../img/product/${product.product_images[0]}`" :alt="product.product_name_zh" />
          </router-link>
          <div class="product-info">
            <p class="product-subtitle" v-if="product.product_sub_title">{{ product.product_sub_title }}</p>
            <h2>{{ product.product_name_zh }}</h2>
            <span class="product-price">
              <p :class="{ 'orginal-price': true }">NT${{ product.product_price.ORIGIN }}</p>
              <p>NT${{ product.product_price.MEMBER }}</p>
            </span>
            <button
              v-gtag:click.add_to_cart="{
                location_url: route.fullPath,
                product_uid: product.product_uid,
                number_of_products: 1,
                scroll_percent: null
              }" 
              @click.stop="addIntoCart(product.product_id)"
            >加入購物車</button>
          </div>
        </li>
      </ul>
    </section>

    <section class="origin-member">
      <img :src="ProductKV2" alt="">
      <div class="content-wrapper">
        <h2>前1000名消費滿3000元會員<br>即升級為創始會員身份</h2>
        <ul>
          <li>成長型推薦碼購物金回饋</li>
          <li>消費 1.5% 購物金回饋</li>
          <li>享有專屬生日禮，由特約美容師針對膚況提供專屬生日組合</li>
          <li>更多專屬優惠</li>
        </ul>
        <button @click="router.push('/vip-rights')">深入瞭解</button>
      </div>
    </section>

    <section class="we-insist">
        <h1 class="section-title">我們對產品的堅持</h1>
        <span>
          克莉斯瑞的產品配方在這50多年來隨著科技研究的創新不斷進展，根據最新的皮膚分析檢測指數資料來進行配方的調整。如果我們無法獲得產品所需要的成分，在經實驗找到更好的替代成分之前，我們會將產線停產，直到產品成分恢復供應。
        </span>
        <p>在採購產品原料後我們還會進行以下流程</p>
        <ul class="flow">
          <li><img :src="require('@/assets/img/Inist_Flow_1.svg')" alt=""><p>化驗</p></li>
          <icon icon="caret-right-fill" />
          <li><img :src="require('@/assets/img/Inist_Flow_2.svg')" alt=""><p>微生物檢驗</p></li>
          <icon icon="caret-right-fill" />
          <li><img :src="require('@/assets/img/Inist_Flow_3.svg')" alt=""><p>毒物學評鑑</p></li>
          <icon icon="caret-right-fill" />
          <li><img :src="require('@/assets/img/Inist_Flow_4.svg')" alt=""><p>製成成品</p></li>
          <icon icon="caret-right-fill" />
          <li><img :src="require('@/assets/img/Inist_Flow_5.svg')" alt=""><p>再次毒物檢驗</p></li>
        </ul>

        <span>
          所有 Chris Farrell 的保養品皆在德國原廠完成製造並空運來台，製造的流程皆遵循最嚴格的WHO規範，直到成品包裝完成前，所有製造過程皆受到嚴密監控與把關。
        </span>
      </section>
  </div>
</template>
