import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Home2 from '../views/Home2'
import About from '../views/About'
import About2 from '../views/About2'
import Login from '../views/Login'
import TattooCare from '../views/TattooCare/'
import Product from '../views/Product'
import ProductExplore from '../views/ProductExplore'
import PostExplore from '../views/PostExplore'
import Post from '../views/Post'
import Privacy from '../views/Privacy'
import Register from '../views/Register'
import TermsAndConditions from '../views/TermsAndConditions'
import NotFound from '../views/404NotFound'
import Member from '../views/Member'
import MemberInfo from '../views/Member/Info'
import MemberOrder from '../views/Member/Order'
import MemberCoupon from '../views/Member/Coupon'
import MemberFollow from '../views/Member/Follow'
import MemberWishlist from '../views/Member/Wishlist'
import CorporateGifts from '../views/CorporateGifts'
import Payment from '../views/Payment'
import Result from '../views/Payment/Result'
import Questionnaire from '../views/Questionnaire'
import SearchOrder from '../views/SearchOrder'
import ResetPassword from '../views/ResetPassword'
import ProductInstruction from '../views/ProductInstruction'
import Help from '../views/Help'
import HelpReturnOrder from '../views/Help/ReturnOrder'
import HelpSitemap from '../views/Help/Sitemap'
import HelpPaymentOptions from '../views/Help/PaymentOptions'
import HelpCsLanding from '../views/Help/CsLanding'
import SkinType from '../views/SkinType'
import VipRight from '../views/VipRight'
import Group from '../views/Group/'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home2
  },
  {
    path: '/about',
    name: 'About',
    component: About2
  },
  {
    path: '/tattoo-care',
    name: 'TattooCare',
    component: TattooCare
  },
  {
    path: '/skin-type/:type',
    name: 'SkinType',
    component: SkinType
  },
  {
    path: '/product',
    name: 'ProductExplore',
    component: ProductExplore
  },
  {
    path: '/product/:productName',
    name: 'Product',
    component: Product
  },
  {
    path: '/blog',
    name: 'Blog',
    component: PostExplore
  },
  {
    path: '/blog/:postTitle',
    name: 'Post',
    component: Post
  },
  {
    path: '/group/:address',
    name: 'Group',
    component: Group
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/new-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/member',
    name: 'Member',
    component: Member,
    redirect: '/member/order',
    children: [
      {
        path: 'info',
        name: 'Member-Info',
        component: MemberInfo
      },
      {
        path: 'coupon',
        name: 'Member-Coupon',
        component: MemberCoupon
      },
      {
        path: 'order',
        name: 'Member-Order',
        component: MemberOrder
      },
      {
        path: 'follow',
        name: 'Member-Follow',
        component: MemberFollow
      },
      {
        path: 'wishlist',
        name: 'Member-Wishlist',
        component: MemberWishlist
      }
    ]
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    meta: {
      keepAlive: true // 需要被缓存
    }
  },
  {
    path: '/result',
    name: 'Payment-Result',
    component: Result
  },
  {
    path: '/corporate-gifts',
    name: 'CorporateGifts',
    component: CorporateGifts
  },
  {
    path: '/questionnaire/:customUrl',
    name: 'Questionnaire',
    component: Questionnaire
  },
  {
    path: '/search-order',
    name: 'SearchOrder',
    component: SearchOrder
  },
  {
    path: '/product-instruction',
    name: 'ProductInstruction',
    component: ProductInstruction
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    redirect: '/help/cs-landing',
    children: [
      {
        path: 'cs-landing',
        name: 'Help-CS-Landing',
        name_zh: '客服中心',
        component: HelpCsLanding
      },
      {
        path: 'payment-options',
        name: 'Help-Payment-Options',
        name_zh: '付款選項',
        component: HelpPaymentOptions
      },
      {
        path: 'return-order',
        name: 'Help-Return-Order',
        name_zh: '退換貨須知',
        component: HelpReturnOrder
      },
      {
        path: 'termsAndConditions',
        name: 'Help-TermsAndConditions',
        name_zh: '服務條款',
        component: TermsAndConditions
      },
      // {
      //   path: 'sitemap',
      //   name: 'Help-Sitemap',
      //   name_zh: '網站地圖',
      //   component: HelpSitemap
      // },
      {
        path: 'privacy',
        name: 'Help-Privacy',
        name_zh: '隱私權政策',
        component: Privacy
      },
    ]
  },
  {
    path: '/vip-rights',
    name: 'Vip-Right',
    component: VipRight
  },
  {
    path: '/:pathMatch(.*)',
    name: '404NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
