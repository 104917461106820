import { timestampParse, fetchApi } from '@/utils'
import { getZipCode } from '@/utils/zip'
import moment from 'moment'

export async function createLogisticOrder(PaymentStore, payType='CREDIT', price) {
  const ReceiverAddress = PaymentStore.shippingInfo.user_city + PaymentStore.shippingInfo.user_urban_area + PaymentStore.shippingInfo.user_address
  const zipInfo = await getZipCode(ReceiverAddress)
  try {
    const res = await PaymentStore.createEcpayShippingOrder({
      MerchantTradeDate: moment().format('yyyy/MM/DD HH:mm:ss'),
      LogisticsType: PaymentStore.cvsInfo ? 'CVS' : 'HOME',
      LogisticsSubType: PaymentStore.cvsInfo?.LogisticsSubType ? PaymentStore.cvsInfo.LogisticsSubType : 'TCAT',
      ReceiverStoreID: PaymentStore.cvsInfo ? PaymentStore.cvsInfo.CVSStoreID : undefined,
      ReturnStoreID: PaymentStore.cvsInfo ? '177418': undefined, //7-11 指定退貨門市為新北大門市
      IsCollection: PaymentStore.cvsInfo ? payType === 'COD' ? 'Y' : 'N' : undefined,
      GoodsAmount:  payType === 'COD' ? price : PaymentStore.amount,
      GoodsName: '保養品',
      GoodsWeight: 1.245,
      SenderName: '顏爾佑',
      SenderCellPhone: '0988879595',
      SenderZipCode: '237021',
      SenderAddress: '新北市三峽區大觀路168號13樓',
      ReceiverName: PaymentStore.shippingInfo.firstName + PaymentStore.shippingInfo.lastName,
      ReceiverCellPhone: `0${PaymentStore.shippingInfo.user_phone}`,
      ReceiverZipCode: '237021',
      ReceiverAddress: PaymentStore.shippingInfo.user_city + PaymentStore.shippingInfo.user_urban_area + PaymentStore.shippingInfo.user_address,
      ReceiverEmail: PaymentStore.shippingInfo.user_email,
      Temperature: '0001',
      Distance: '00',
      Specification: '0001',
      ScheduledPickupTime: '4',
      ScheduledDeliveryTime: '4',
      TradeDesc: 'Chris Farrell Taiwan 保養品',
      ServerReplyURL: 'https://api.chris-farrell.com.tw/index.php/order/ecpay'
    })
    return res
  } catch (error) {
    throw error
  }
}

