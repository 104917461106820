<script setup>
import { CfInput } from '@/components/Form'
import { genTapPayField, TapPayByPrime, CARD_TYPE } from '@/utils/tappay.js'
import { onMounted, ref, reactive, defineExpose, nextTick, inject } from 'vue'
import { usePaymentStore } from '../../../store/payment'
import { fetchApi } from '@/utils'
import { sendError } from  '@/utils/error'
import TAPPAY_STATUS_MSG from '@/utils/tappayStatusMsg.json'

const PaymentStore = usePaymentStore()
const payByPrimeData = reactive({
  "prime": null,
  "partner_key": process.env.VUE_APP_TAPPAY_PARTNER_KEY,
  "merchant_id": process.env.VUE_APP_TAPPAY_MERCHANT_ID,
  "details": "TapPay Chris Farrell Test",
  "amount": null,
  "cardholder": {
    "phone_number": "+886923456789",
    "name": "",
    "email": "LittleMing@Wang.com",
    "zip_code": "100",
    "address": "台北市天龍區芝麻街1號1樓",
    "national_id": "A123456789"
  },
  "three_domain_secure": true,
  "result_url": {
    "frontend_redirect_url": "https://test.chris-farrell.com.tw/result",
    "backend_notify_url": "https://api.chris-farrell.com.tw/index.php/order/result",
    "go_back_url": "https://test.chris-farrell.com.tw/result"
  },
  "remember": true
})

onMounted(() => {
  if (PaymentStore.shippingInfo) {
    payByPrimeData.cardholder = {
      "phone_number": '+886' + PaymentStore.shippingInfo.user_phone,
      "name": PaymentStore.shippingInfo.firstName + PaymentStore.shippingInfo.lastName || '',
      "email": PaymentStore.shippingInfo.user_email,
      "zip_code": "100",
      "address": `${PaymentStore.shippingInfo.user_city}${PaymentStore.shippingInfo.user_urban_area}${PaymentStore.shippingInfo.user_address}`,
      "national_id": "A123456789"
    }
  }
})

const cardType = ref(null)
onMounted(() => {
  console.log(genTapPayField(['number', 'expirationDate', 'ccv']));
  TPDirect.card.setup(genTapPayField(['number', 'expirationDate', 'ccv']))
  TPDirect.card.onUpdate(status => {
    if (status.cardType) cardType.value = status.cardType
  })
})

const getPrime = () => {
  const fieldStatus = TPDirect.card.getTappayFieldsStatus()
  if (fieldStatus.canGetPrime) TPDirect.card.getPrime((result) => {
    console.log(result);
    payByPrimeData.prime = result.card.prime
  })
}

const payByPrime = async () => {
  if (!payByPrimeData.prime) return
  const API = "/tappay/tpc/payment/pay-by-prime"
  const response = await TapPayByPrime(API, payByPrimeData)
  console.log(response);
  if (response.status === 0) {
    return response
  }
  if (response.status === 0 && response.payment_url) window.location = response.payment_url
}

const getPrimePromise = () => new Promise((res, rej) => {
  const fieldStatus = TPDirect.card.getTappayFieldsStatus()
  console.log(fieldStatus);
  if (fieldStatus.canGetPrime) {
    TPDirect.card.getPrime((result) => {
      res(result)
    })
  } else {
    rej({ canGetPrime: fieldStatus.canGetPrime })
  }
})

const $message = inject("$message")
const handlePay = async price => {
  try {
    const result = await getPrimePromise()
    console.log(result);
    payByPrimeData.prime = result.card.prime
    const API = "/api/tappay/"
    const uidRes = await fetchApi('/api/genUid/')
    const response = await fetchApi(API, { payInfo: { ... payByPrimeData, order_number: uidRes.id, amount: price} })
    console.log(response);
    if (response.status === 200 && response.data.status === 0) {
      return response
    } else {
      await sendError(
        'ORDER_TAPPAY',
        'https://prod.tappaysdk.com/tpc/payment/pay-by-prime',
        JSON.stringify(payByPrimeData),
        JSON.stringify(response.data)
      )
      $message.error(`[${response.data.status}] - ${TAPPAY_STATUS_MSG[response.data.status] || response.data.msg}`)
    }
  } catch (error) {
    console.log(error);
    $message.warning('請確實填入信用卡資訊')
    return
  }
  
}
defineExpose({ handlePay })
</script>

<template>
  <div class="pay-direct">
    <div class="tpfield">
      <div id="card-number"></div>
      <span>
        <i :class="cardType ? CARD_TYPE[cardType] : 'fas fa-credit-card'"></i>
      </span>
    </div>
    <cf-input customClass="holder-name" v-model="payByPrimeData.cardholder.name" placeholder="持卡人姓名"></cf-input>
    <div class="tpfield">
      <div id="card-expirationDate"></div>
    </div>
    <div class="tpfield">
      <div id="card-ccv"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pay-direct {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 8px;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin-top: 24px;
  }
}

.tpfield {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  width: calc(100% - 24px);
  border: 1px solid #707070;
  padding: 0 12px;
  background-color: #fff;

  @media screen and (max-width: 900px) {
    position: relative;

    &::after {
      display: block;
        position: absolute;
        transform: scale(0.9);
        font-size: 10px;
        padding: 3px 12px;
        top: -18px;
        background-color: #707070;
        color: #fff;
    }

    &:nth-child(1)::after {
      content: '信用卡號';
    }
    &:nth-child(3)::after {
      content: '到期日';
    }
    &:nth-child(4)::after {
      content: '安全碼';
    }
  }

  div {
    height: 100%;
  }

  span {
    font-size: 20px;
  }
}

.tappay-field-focus {
  border-color: #66afe9;
  outline: 0;
}

button {
  background-color: #292d36;
  color: #fff;
  border: none;
  padding: 6px 0;
  margin-top: 4px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
</style>
