<script setup>
import Icon from '../components/Icon'
import BirthdayInputGroup from '@/components/BirthdayInputGroup'
import { CfCheckbox } from '../components/Form'
import { CfInput } from '@/components/Form'
import { useLoginStore } from '../store/login';
import { computed, inject, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useEmailStore } from '../store/email';
import { clearData } from '@/utils'
import moment from 'moment';

const router = useRouter()
const route = useRoute()
const LoginStore = useLoginStore()
const EmailStore = useEmailStore()
const $message = inject('$message')

const showPassord = ref(false)
const toggleShow = () => showPassord.value = !showPassord.value

const newUserInfo = reactive({
  user_email: '',
  password: '',
  firstName: '',
  lastName: '',
  user_phone: '',
  birthday: {
    y: '',
    m: '',
    d: '',
  }
})
const bindingCode = ref('')
const emailCheck = ref()
const passwordCheck = ref()
const readedPrivacy = ref(false)
const loading = ref(false)
const checkPass = computed(() => {
  const passArray = [passwordCheck.value === newUserInfo.password, readedPrivacy.value]
  return passArray.every(e => e)
})

onMounted(async () => {
  window.scrollTo(0, 0)
  if (!!LoginStore.currentUser && LoginStore.currentUser.user_name) {
    $message.warning('目前已登入會員帳號，若要註冊帳號請先登出')
    return router.replace('/')
  }
  const { state, login, cus } = route.query
  if (state === '200' && login === 'line') {
    loading.value = true
    await LoginStore.queryUserWithId(cus)
    loading.value = false
  }

  const recipients = [{
      name: result.user_name,
      address: result.user_email,
      // variables: {
      //   registeDate: moment().format('YYYY/MM/DD'),
      //   userAccount: result.user_email
      // }
    }]
    await EmailStore.sendEmail({
      subject: EmailStore.systemEmailDict['REGISTER_SUCCESS'].email_template_subject,
      contentId: EmailStore.systemEmailDict['REGISTER_SUCCESS'].email_template_id
    }, recipients)
})

const handleRegister = async () => {
  loading.value = true
  const { birthday, ...info } = newUserInfo
  if (!info.firstName || !info.lastName) {
    $message.warning('請確認姓名是否為空')
    loading.value = false
    return
  }
  if (!Object.values(birthday).every(e => e)) {
    $message.warning('請輸入您的生日')
    loading.value = false
    return
  }
  const user_birthday = moment(Object.values(birthday).map((e, index) => index === 1 ? parseInt(e) -1 : parseInt(e))).unix()
  console.log(user_birthday);
  if (isNaN(user_birthday)) {
    $message.warning('日期格式有誤請檢查年月日皆為正常日期範圍')
    loading.value = false
    return
  }
  if (!readedPrivacy.value) {
    loading.value = false
    return $message.warning('請閱讀隱私權政策後，勾選已閱讀')
  }

  let result;
  //Line, FB 補齊會員資料
  if (route.query.login && route.query.cus) {
    const { firstName, lastName, ...othersData } = info
    const res = await LoginStore.updateUser({ ...clearData(othersData), user_birthday, user_name: firstName+lastName })
    await LoginStore.distributeRegisterCoupon(route.query.cus)
    result = { state: 200, ...res.data, user_id: route.query.cus, user_email: LoginStore.currentUser.user_email }
    await LoginStore.genInviteCode({ user_id: route.query.cus, user_name: result.user_name })
  } else {
    result = await LoginStore.registerUser({ ...info, user_birthday })
  }
  
  if (bindingCode.value) {
    const bindingResult = await LoginStore.bindUserAtResgister(bindingCode.value, result.user_id)
    if (bindingResult.state === 200) {
      console.log('已成功綁定!');
    } else {
      console.log('找不到此推薦碼，請確認是否正確');
    }
  }

  console.log(result);

  if (result.state === 200) {
    const recipients = [{
      name: result.user_name,
      address: result.user_email,
      // variables: {
      //   registeDate: moment().format('YYYY/MM/DD'),
      //   userAccount: result.user_email
      // }
    }]
    await EmailStore.sendEmail({
      subject: EmailStore.systemEmailDict['REGISTER_SUCCESS'].email_template_subject,
      contentId: EmailStore.systemEmailDict['REGISTER_SUCCESS'].email_template_id
    }, recipients)
    $message.success('註冊成功')
    loading.value = false
    if (route.query.redirect && route.query.redirect.payment) router.push('/payment')
    else window.location = '/';
    
  } else {
    loading.value = false
    switch (result.code) {
      case 'auth/email-already-in-use': return $message.error('此 Email 已被註冊')
      default: return $message.error(result.code);
    }
  }
}

const PASSWORD_STRENGTH_CHECK = [
  { text: '最少需 6 個字元', regex: /\S{6,}/g, required: true },
  { text: '含英文字母', regex: /[a-zA_Z]+/g },
  { text: '含有數字', regex: /\d+/g },
]
const checkPassword = regex => {
  const Regex = new RegExp(regex)
  return Regex.test(newUserInfo.password)
}
const checkListCount = computed(() => {
  return PASSWORD_STRENGTH_CHECK.filter(check => checkPassword(check.regex)).length
})
const passwordCheckListExpread = ref(false)
const togglePasswordCheckListExpread = () => passwordCheckListExpread.value = !passwordCheckListExpread.value

</script>

<template>
  <div class="view-register">
    <header>
      <h1>{{ !route.query.login ? '註冊會員帳號' : '填寫會員資料' }}</h1>
    </header>

    <form>
      <cf-input v-model="newUserInfo.firstName" placeholder="姓氏 (出貨資訊使用，須與身分證相符)" required></cf-input>
      <cf-input v-model="newUserInfo.lastName" placeholder="名字 (出貨資訊使用，須與身分證相符)" required></cf-input>
      <cf-input
        v-if="!route.query.login"
        v-model="newUserInfo.user_email"
        type="email"
        placeholder="電子郵件"
        required
      ></cf-input>

      <cf-input
        v-if="!route.query.login"
        v-model="newUserInfo.password"
        :type="showPassord ? 'text' : 'password'"
        placeholder="密碼"
        required
        @focus="togglePasswordCheckListExpread"
        @blur="togglePasswordCheckListExpread"
      >
        <div class="iconButton" @click.stop="toggleShow">
          <Icon :icon="showPassord ? 'eye-slash-fill' : 'eye-fill'" />
        </div>
      </cf-input>

      <div :class="{ 'password-strength': true, 'expread': passwordCheckListExpread }">
        <p>我們建議妳使用包含以下字元的密碼:</p>
        <ul class="strength-checklist">
          <li v-for="check, index in PASSWORD_STRENGTH_CHECK" :key="`password_check_${index}`">
            <icon icon="check-square-fill" :class="{ checked: checkPassword(check.regex) }" />
            {{ check.text }}{{ check.required ? ' (必須)' : '' }}
          </li>
        </ul>
        <p class="strength">密碼強度：{{ ['弱', '中', '中高', '高'][checkListCount - 1] }}</p>
        <div class="strength-progress">
          <div
            class="pace"
            :style="{ width: `${checkListCount * 25}%`, maxWidth: `${checkListCount * 25}%` }"
          ></div>
        </div>
      </div>

      <cf-input
        v-if="!route.query.login"
        v-model="passwordCheck"
        :type="showPassord ? 'text' : 'password'"
        placeholder="確認密碼"
        required
      >
        <template #extra v-if="passwordCheck && passwordCheck !== newUserInfo.password">密碼不相符</template>
      </cf-input>

      <cf-input v-model="newUserInfo.user_phone" addonBefore="+886" placeholder="電話號碼" required></cf-input>
      <!-- <cf-input customClass="birthday" label="生日" fixLabel>
        <input
          data-cid="y"
          @input="handleBirthdayInput"
          v-model="newUserInfo.birthday.y" type="text" placeholder="西元年" maxlength="4">/
        <input
          data-cid="m"
          ref="birthdayMonthREF"
          @input="handleBirthdayInput"
          v-model="newUserInfo.birthday.m" type="text" placeholder="月份" maxlength="2">/
        <input
          ref="birthdayDayREF"
          @input="handleBirthdayInput"
          v-model="newUserInfo.birthday.d" type="text" placeholder="日期" maxlength="2">
          
        <template #extra>
          <span class="birthday-extra">每年生日將贈送生日禮</span>
        </template>
      </cf-input> -->
      <BirthdayInputGroup :birthday="newUserInfo.birthday" />

      <cf-input v-model="bindingCode" placeholder="綁定推薦碼(非必填)" extra="綁定其他 Chris Farrell 好友的推薦碼即可獲得 $100 折價優惠券"></cf-input>
      <cf-checkbox v-model="readedPrivacy">
        <p>
          請先閱讀
          <a href="/help/privacy" target="_blank">隱私權政策</a>
        </p>
      </cf-checkbox>

      <button
        v-if="!route.query.login"
        :class="{ loading, disabled: !checkPass }"
        @click.prevent="handleRegister"
        :disabled="loading || !checkPass"
      >建立帳戶</button>
      <button
        v-else
        :class="{ loading }"
        @click.prevent="handleRegister"
        :disabled="loading"
      >送出會員資料</button>
    </form>
  </div>
</template>
