import { getElementPositionY } from '../utils'
import { gaEvent } from '../utils/event'

let scrollFadeingFunctionSet = []
function genScrollFadeInFunc(el) {
  let func = () => {
    const windowHeight = window.innerHeight;
    const positionY = getElementPositionY(el)
    if ((positionY - windowHeight * 0.3) <= document.documentElement.scrollTop + (windowHeight / 3) * 2) {
      el.classList.remove('fade')
      document.removeEventListener('scroll', func)
    }
  }
  return func
}

export const VScrollFadein = {
  mounted(el) {
    el.classList.add('fade')
    el.dataset.scrollAnime = true
    let index = scrollFadeingFunctionSet.length
    scrollFadeingFunctionSet.push(genScrollFadeInFunc(el))
    el.dataset.vFadeIndex = index
    document.addEventListener('scroll', scrollFadeingFunctionSet[index])
  },
  beforeUnmount(el) {
    const index = el.dataset.vFadeIndex
    document.removeEventListener('scroll', scrollFadeingFunctionSet[index])
    scrollFadeingFunctionSet = []
  }
}

export const VGtag = {
  mounted(el, binding) {
    const { arg, value, modifiers } = binding
    const eventName = Object.keys(modifiers)[0]
    el.addEventListener(arg, () => gaEvent(eventName, { ...value }))
  },
  beforeUnmount(el, binding) {
    const { arg, value, modifiers } = binding
    const eventName = Object.keys(modifiers)[0]
    el.removeEventListener(arg, () => gaEvent(eventName, { ...value }))
  }
}

export const VCollapse = {
  mounted(el) {
    el.addEventListener('click', () => el.classList.toggle('spread'))
  }
}
