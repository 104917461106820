<script>
import { computed, ref, defineEmits, onMounted } from 'vue'
export default {
  name: 'CF-Checkbox'
}
</script>

<script setup>
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  value: String,
  modelValue: [Boolean, Object],
  checked: Boolean
})
const checkProxy = ref(false)
const isChecked = computed({
  get: () => {
    return props.checked
  },
  set: (value) => {
    checkProxy.value = value
  }
})

onMounted(() => {
  if (typeof(props.modelValue) === 'boolean') {
    checkProxy.value = props.modelValue
  }
})

const onChange = () => {
  if (props.value && Array.isArray(props.modelValue)) {
    const index = props.modelValue.indexOf(props.value)
    const newModelvalue = JSON.parse(JSON.stringify(props.modelValue))
    if (checkProxy.value) newModelvalue.push(props.value)
    else newModelvalue.splice(index, 1)
    emits('update:modelValue', newModelvalue)
    return
  }
  emits('update:modelValue', checkProxy.value)
}
</script>

<template>
  <label class="cf-checkbox">
    <input
      type="checkbox"
      style="display:none;"
      :value="value"
      v-model="isChecked"
      @change="onChange"
    />
    <div class="checkbox-outer">
      <div :class="{ 'checked-inner': true, checked: checkProxy }"></div>
    </div>

    <p>
      <slot></slot>
    </p>
  </label>
</template>

