<script setup>
import BG from '@/assets/img/member_bg.webp'
import Icon from '@/components/Icon'
import { onMounted, ref, inject } from 'vue'

const VIP_FEEDBACK = ['生日禮', '購物金無上限折抵', '好友推薦回饋金', '肌膚諮詢', '換季禮']
const MEMBER_TABLE = [
  {
    exclusive: false,
    row:['會員條件', '於官網註冊會員', '首1000名消費\n滿 $3000 之會員'],
  },
  {
    exclusive: false,
    row:['會員效期', '永久', '最後一次消費日起\n算365天'],
  },
  {
    exclusive: false,
    row:['首購禮', '200元折價', ''],
  },
  {
    exclusive: false,
    row:['生日禮', '50元購物金', '美容師客製化組合禮*'],
  },
  {
    exclusive: true,
    row:['消費回饋', '', '1.5% 購物金'],
  },
  {
    exclusive: true,
    row:['好友推薦', '使用好友推薦碼\n可得 100 元折價券', '3% 綁定推薦碼好友\n結帳金額之購物金'],
  },
  {
    exclusive: false,
    row:['季節贈禮', '', '不定期驚喜'],
  },
  {
    exclusive: true,
    row:['專屬服務', '', '美容師肌膚諮詢'],
  }
]
const FQA = [
  {
    q: "我有一組好友推薦碼，可以給多少個好友使用？",
    a: "可以給無數個好友使用哦！而每個會員只能使用一組他人的好友推薦碼，例如：A會員可以將推薦碼給B、C、D會員做使用，B會員一旦使用過A會員的推薦碼，則不能再使用其他人的推薦碼。"
  },
  {
    q: "如何成為Chris Farrell好友或創始會員呢？",
    a: "只要在Chris Farrell Taiwan官網成功註冊會員即可成為Chris Farrell好友；創始會員需為Chris Farrell Taiwan官網首1000名消費滿3000元的會員，也就是說，全球只會有1000名創始會員資格，第1001名完成購買的會員則無法成為創始會員"
  },
  {
    q: "在哪裡消費可以獲得購物金回饋呢？",
    a: "所有的會員回饋、購物金回饋等僅限克莉斯瑞台灣官方網站及直營實體通路"
  },
  {
    q: "生日禮該如何領取？",
    a: "註冊會員時請務必填寫生日等相關資料，以維護會員生日禮的權益，生日當月若有於官網下訂單將隨單附贈。例如生日是在 2月28日，則於2月期間消費即可獲得生日禮，但生日禮每年只限領取一次"
  },
]

onMounted(() => {
  window.scrollTo(0, 0)
})
const ScreenState = inject('ScreenProvider')
const scrollToExplore = () => window.scrollTo({top: 1080*0.7-100})

const showMemberGroup = ref('CF_Friend')
const chooseGroup = group => showMemberGroup.value = group

const showQuestion = ref()
const chooseQuestion = index => {
  if (showQuestion.value === index) return showQuestion.value = ''
  showQuestion.value = index
}

const toLinke = link => window.open(link)
</script>

<template>
  <div class="view-vip-right">
    <header class="hero" :style="{ backgroundImage: `url(${BG})` }">
      <div class="mask">
        <div class="content-wrapper">
          <h1>克莉斯瑞</h1>
          <h2>展開屬於您的人體仿生保養旅程</h2>
          <button @click="scrollToExplore">
            <p>全新專屬會員回饋</p>
            <span>
              <icon icon="arrow-down-short" />
            </span>
          </button>
        </div>
      </div>
    </header>

    <main>
      <section class="feedback">
        <h2>會員專屬 5 大回饋</h2>
        <ul>
          <li v-for="feedback, index in VIP_FEEDBACK" :key="`feedback_${index}`">
            <img :src="require(`@/assets/img/vip_feedback${index+1}.svg`)" alt="">
            <p>{{ feedback }}</p>
          </li>
        </ul>
      </section>

      <section class="member-table">
        <div class="container">
          <header>
            <h2>克莉斯瑞<br>會員獨享回饋</h2>
          </header>

          <p class="button-info" v-if="ScreenState.mode ==='MOBILE'">＊點擊按鈕了解不同會員福利</p>
          <div class="button-group" v-if="ScreenState.mode ==='MOBILE'">
            <button @click="chooseGroup('CF_Friend')" :class="{active: showMemberGroup === 'CF_Friend'}">Chris Farrell 好友</button>
            <button @click="chooseGroup('CF_Origin')" :class="{active: showMemberGroup === 'CF_Origin'}">創始會員</button>
          </div>
          
          <div class="table-container">
            <div class="head-row" v-if="ScreenState.mode !=='MOBILE'">
              <div class="cell"></div>
              <div class="cell"><p>Chris Farrell 好友</p></div>
              <div class="cell"><p>創始會員</p></div>
            </div>
            <div class="row" v-for="row, index in MEMBER_TABLE" :key="`m_table_${index}`">
              <div class="cell"><p>{{row.row[0]}}</p></div>
              <div class="cell" v-if="ScreenState.mode !=='MOBILE' || showMemberGroup === 'CF_Friend'">
                <p v-html="row.row[1].replace(/\n/g, '<br>')"></p>
                <span class="extra" v-if="index === 2">＊使用期限為註冊後 7 天內</span>
              </div>
              <div class="cell" v-if="ScreenState.mode !=='MOBILE' || showMemberGroup === 'CF_Origin'">
                <p v-html="row.row[2].replace(/\n/g, '<br>')"></p>
                <img v-if="row.exclusive" :src="require('@/assets/img/exclusive_icon.svg')" alt="">
              </div>
            </div>
            <span class="extra">*創始會員生日禮須於生日當月於官網下訂單，我們將隨單附贈生日禮</span>
          </div>
        </div>

        <div class="member-cash">
          <header>
            <img :src="require('@/assets/img/vip_feedback2.svg')" alt="">
            <h2>關於購物金</h2>
          </header>

          <ul>
            <li>購物金的使用期限為自存入起365天，透過好友推薦碼所獲取的購物金，會在好友完成訂單且收到貨的7天後入帳。</li>
            <li>購物金可使用於任何產品、優惠組合且得與任何活動優惠搭配使用，沒有折抵的門檻上限</li>
          </ul>
          <span class="extra">*克莉斯瑞保有最終調整活動和贈品更改之權益，請依網站公告為準</span>
        </div>
      </section>

      <section class="member-gift">
        <h2>創始會員專屬會員禮</h2>
        <ul>
          <li>
            <img :src="require('@/assets/img/vip_feedback3.svg')" alt="">
            <h3>好友推薦碼</h3>
            <span>
              每個創始會員皆有屬於自己的一組好友推薦碼，將此推薦碼給好友使用將可以獲得該好友的結帳金額3%的購物金，一個人的推薦碼可以給無數個好友使用
            </span>
          </li>
          <li>
            <img :src="require('@/assets/img/member_gift.svg')" alt="">
            <h3>美容師客製禮</h3>
            <span>
              克莉斯瑞在全球合作超過10000名美容師，在創始會員生日時，我們會請美容師依照會員的皮膚狀況客製專屬的生日禮
            </span>
          </li>
          <li>
            <img :src="require('@/assets/img/vip_feedback4.svg')" alt="">
            <h3>美容師肌膚諮詢</h3>
            <span>
              克莉斯瑞在台灣有固定合作的美容師，若創始會員有任何肌膚狀況需要諮詢，我們將免費提供會員1對1的線上諮詢服務
            </span>
          </li>
        </ul>
      </section>

      <section class="fqa">
        <h2>克莉斯瑞會員<br>常見問題</h2>
        <ul>
          <li
            :class="{hide: showQuestion!=index+1}"
            v-for="question, index in FQA"
            :key="`FQA_${index+1}`"
            @click="chooseQuestion(index+1)"
          >
            <header>
              <h4>
                <p>Q：</p>
                <p>{{ question.q }}</p>
              </h4>

              <div class="action">
                <span></span>
                <span></span>
              </div>
            </header>
            <div class="ans">
              <p>A：</p>
              <p>{{ question.a }}</p>
            </div>
          </li>
        </ul>
      </section>

      <section class="qrcode">
        <h2>除此之外若有其他問題</h2>
        <p>請聯繫我們的官方LINE@或Facebook粉絲團，將有專人為您解答</p>

        <ul>
          <li>
            <img :src="require('@/assets/img/qrcode_fb.svg')" alt="">
            <button @click="toLinke('https://www.facebook.com/chrisfarrelltw')">前往 Facebook粉專
              <icon v-if="ScreenState.mode === 'MOBILE'" icon="arrow-right-short" />
            </button>
          </li>
          <li>
            <img :src="require('@/assets/img/qrcode_line.svg')" alt="">
            <button @click="toLinke('https://page.line.me/369ljfeu')">前往官方 Line@
              <icon v-if="ScreenState.mode === 'MOBILE'" icon="arrow-right-short" />
            </button>
          </li>
        </ul>
      </section>
    </main>
  </div>
</template>
