import { defineStore } from "pinia";
import { useLoginStore } from './login'
import { timestampParse, fetchApi } from '@/utils'
import database from '@/utils/database'
import { useEmailStore } from "./email";
import { genProductTableVariables } from './utils/email'
import { useProductStore } from "./product";
import { useCouponStore } from "./coupon";
import ecpayLogisticsState from "@/utils/ecpayLogisticsState.json"
import moment from "moment";

async function clearPaymentSession() {
  const res = await fetchApi('/api/session/', { unset: ['shippingInfo', 'cvsInfo'] })
  localStorage.removeItem('couponUsed')
  localStorage.removeItem('cartItems')
  console.log(res);
}

export const useOrderStore = defineStore({
  id: 'Order',
  state: () => ({
    orderList: [],
    focusOrderId: null,
    loading: true
  }),
  getters: {
    focusOrder() {
      if (this.orderList.length && this.focusOrderId) {
        return this.orderList.filter(e => e.orders_id === this.focusOrderId)[0]
      }
      return null
    }
  },
  actions: {
    async queryOrders() {
      const loginStore = useLoginStore()
      if (loginStore.currentUser) {
        const res = await database.table('orders')
          .where('user_id', '=', loginStore.currentUser.user_id)
          .orderBy('order_create_at', 'desc')
          .get()
        let orderList = res.data.map(e => ({ 
          ...e, 
          order_products: JSON.parse(e.order_products),
          order_coupon_used: JSON.parse(e.order_coupon_used),
          order_logistics_info: JSON.parse(e.order_logistics_info),
          order_logistics_cvs_info: JSON.parse(e.order_logistics_cvs_info),
          order_logistics_state: JSON.parse(e.order_logistics_state) || []
        }))
        const result = await fetchApi("/api/ecpay/getLogisticsState/", { orders: orderList.filter(o => {
          if (o.order_pay_type === "COD") return false 
          if (!o.order_logistics_state.length) return true
          let newestState = o.order_logistics_state[o.order_logistics_state.length-1].code
          switch (o.order_logistics_subtype) {
            case "TCAT": return !["3003", "20000"].includes(newestState)
            case "UNIMARTC2C": return !["2067", "20000"].includes(newestState)
            case "FAMIC2C": return !["3022", "20000"].includes(newestState)
          }
        }).map(o => ({
          MerchantTradeNo: o.order_logistics_id,
          TimeStamp: moment().unix()
        }))})

        const logisticsStateStoreRule = Object.keys(ecpayLogisticsState).reduce((acc, type) => ({
          ...acc, [type]: Object.keys(ecpayLogisticsState[type])
        }), logisticsStateStoreRule)
        this.orderList = result.response.reduce((acc, state) => {
          let index = acc.map(o => o.order_logistics_id).indexOf(state.MerchantTradeNo)
          let logisticsSubtype = state.LogisticsType.split("_")[1]
          acc.splice(index, 1, {
            ...acc[index],
            order_logistics_state: [
              ...acc[index].order_logistics_state,
              { code: state.LogisticsStatus }
            ].sort((a, b) => logisticsStateStoreRule[logisticsSubtype].indexOf(a.code) - logisticsStateStoreRule[logisticsSubtype].indexOf(b.code))
          })
          return acc
        }, orderList)
        this.loading = false
        return res.data
      }
    },
    async searchOrder(orderId) {
      const res = await database.table('orders').where('orders_id', '=', orderId).get()
      console.log(res)
      if (res.data.length) {
        return res.data.map(e => ({
          ...e,
          order_products: JSON.parse(e.order_products),
          order_coupon_used: JSON.parse(e.order_coupon_used),
          order_logistics_info: JSON.parse(e.order_logistics_info),
          order_logistics_cvs_info: JSON.parse(e.order_logistics_cvs_info),
          order_logistics_state: JSON.parse(e.order_logistics_state) || []
        }))[0]
      }
      return null
    },
    async createOrder(payload) {
      const loginStore = useLoginStore()
      const CouponStore = useCouponStore()
      const { order_products, order_logistics_info, order_coupon_used } = payload
      const res = await database.table('orders').set({ ...payload })
      this.orderList = [ {
        ...res.data,
        order_products: JSON.parse(res.data.order_products),
        order_coupon_used: JSON.parse(res.data.order_coupon_used),
        order_logistics_info: JSON.parse(res.data.order_logistics_info),
        order_logistics_cvs_info: res.data.order_logistics_cvs_info ? JSON.parse(res.data.order_logistics_cvs_info) : {},
        order_logistics_state: JSON.parse(res.data.order_logistics_state) || []
      }, ...this.orderList]

      //刪除使用過的優惠券
      if (loginStore.currentUser) {
        const couponUsedIds = JSON.parse(order_coupon_used).map(e => e.coupon_id)
        const lastCoupons = loginStore.currentUser.user_own_coupons.filter(c => !couponUsedIds.includes(c.coupon_id))
        console.log(couponUsedIds, lastCoupons);
        await loginStore.updateUser({
          user_own_coupons: JSON.stringify(lastCoupons),
          user_coupons_used: [...loginStore.currentUser.user_coupons_used, ...couponUsedIds].join(',')
        })
        await CouponStore.usePublicCoupon(couponUsedIds)
      }
      clearPaymentSession()
    },
    async updateOrder(payload) {
      const res = await database.table('orders').update({ ...payload })
    },
    // async checkOrderLogisticState(id, payload) {
    //   const API = '/api/ecpay/'
    //   const res = await fetchApi(API, { api: '/Helper/QueryLogisticsTradeInfo/V3', shippingInfo: { ...payload } })
    //   if (res.data) {
    //     const index = this.orderList.map(o => o.orders_id).indexOf(id)
    //     console.log(index, res.data);
    //     this.orderList.splice(index, 1, { ...this.orderList[index], order_logistics_state_sync: res.data.LogisticsStatus })
    //   }
    //   console.log(res);
    // },
    changeFocusOrder(id) {
      this.focusOrderId = id
    },
    async returnApply(orderId, returnInfo) {
      const res = await database.table('orders')
        .where('orders_id', '=', orderId)
        .update({order_type: 'RETURN_APPLY', order_return_info: JSON.stringify(returnInfo) })
      const returnOrderRes = await database.table('return_order').set({
        orders_id: orderId,
        return_order_info: JSON.stringify(returnInfo),
        return_order_create_at: 'PHP_SERVER_TIMESTAMP'
      })
      console.log(res);
      console.log(returnOrderRes);
      if (res.status === 200) {
        const index = this.orderList.map(e => e.orders_id).indexOf(orderId)
        this.orderList.splice(index, 1, { ...this.orderList[index], order_type: 'RETURN_APPLY', order_return_info: returnInfo })
      }
      return res
    }
  }
})
