<script setup>
import { usePaymentStore } from "../../store/payment";
import { useProductStore } from "../../store/product";
import Icon from '@/components/Icon'
import { useRouter } from "vue-router";
import { inject, defineExpose } from "vue";
import { useLoginStore } from "../../store/login";
import { VGtag } from '@/directives'

const Props = defineProps({ nextStep: Function })
const ScreenProvider = inject('ScreenProvider')
const router = useRouter()
const ScreenState = inject('ScreenProvider')
const PaymentStore = usePaymentStore()
const ProductStore = useProductStore()
const LoginStore = useLoginStore()
const $message = inject('$message')

function addAdditionalProduct(product) {
  if (PaymentStore.cartItems.length) PaymentStore.addProductIntoCart({
    product_id: product.product_id,
    product_quantity: 1,
    product_price: 'ADDITIONAL',
  })
  else $message.warning('請先加入主要產品')
}

function cartIncludesAdditionalProduct(product_id) {
  return PaymentStore.cartItems.filter(e => e.product_price === 'ADDITIONAL').map(e => e.product_id).includes(product_id)
}

const toShipping = () => {
  PaymentStore.saveInfoToSession('CART')
  if (PaymentStore.cartItems.length) {
    if (ScreenProvider.mode === "COMPUTER") Props.nextStep()
    return true
  }
  else {
    $message.warning('購物車中沒有產品，請先加入你想購買的商品喔～')
    return false
  }
}

defineExpose({ handleNext: toShipping })
</script>

<template>
  <div class="view-cart">
    <div class="bill">
      <header>
        <h2>我的購物車</h2>
      </header>
      <table>
        <tr class="table-head">
          <td>品項</td>
          <td>產品資訊</td>
          <td v-if="ScreenState.mode ==='COMPUTER'">單價</td>
          <td>數量</td>
          <td>總金額</td>
          <td></td>
        </tr>

        <tr class="item" v-for="item in PaymentStore.cartItems" :key="item.product_id">
          <td>
            <div>
              <img
                v-gtag:click.select_item="{
                  product_uid: ProductStore.productDict[item.product_id].product_uid,
                  number_of_products: item.product_quantity
                }"
                :src="ProductStore.productDict[item.product_id].product_type === 'SET'
                  ? `/img/product/${ProductStore.productDict[item.product_id].product_images[0]}`
                  : `/img/product/${ProductStore.productDict[item.product_id].product_uid}/avatar.webp`
                "
                alt
                @click="router.push(`/product/${ProductStore.productDict[item.product_id].product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
              />
            </div>
          </td>
          <td
            v-gtag:click.select_item="{
              product_uid: ProductStore.productDict[item.product_id].product_uid,
              number_of_products: item.product_quantity
            }"
            @click="router.push(`/product/${ProductStore.productDict[item.product_id].product_name_en.replace(/\s/g, '-').toLowerCase()}`)"
          >
            <h3>{{ ProductStore.productDict[item.product_id].product_name_en }}</h3>
            <h4>{{ ProductStore.productDict[item.product_id].product_name_zh }}</h4>
            <span class="product-price-type" v-if="item.product_price === 'ADDITIONAL'">加價購</span>
          </td>
          <td v-if="ScreenState.mode ==='COMPUTER'">
            <p>${{ ProductStore.productDict[item.product_id].product_price[item.product_price] }}</p>
          </td>
          <td class="product-quantity">
            <div>
              <button v-if="item.product_price !== 'ADDITIONAL'" @click="PaymentStore.editProductQuantity(item.product_id, -1)">
                <icon icon="dash"></icon>
              </button>
              <p>{{ item.product_quantity }}</p>
              <button v-if="item.product_price !== 'ADDITIONAL'" @click="PaymentStore.editProductQuantity(item.product_id, 1)">
                <icon icon="plus"></icon>
              </button>
            </div>
          </td>
          <td>
            <p>
              ${{
                ProductStore.productDict[item.product_id].product_price[item.product_price] * item.product_quantity
              }}
            </p>
          </td>
          <td class="delete">
            <icon
              icon="x-square-fill"
              @click="item.product_price !== 'ADDITIONAL'
                ? PaymentStore.removeProductFromCart(item.product_id)
                : PaymentStore.removeAdditionalProduct(item.product_id) 
              "
            ></icon>
          </td>
        </tr>
        <template v-if="PaymentStore.couponItemList.length">
          <tr class="item giveaway" v-for="item in PaymentStore.couponItemList" :key="`cart-table-coupon-item-${item.product_id}`">
            <td>
              <div>
                <img :src="`/img/product/${ProductStore.productDict[item.product_id].product_uid}/avatar.webp`"/>
              </div>
            </td>
            <td>
              <h4>{{ ProductStore.productDict[item.product_id].product_name_zh }}</h4>
              <span class="product-price-type">{{ ScreenState.mode ==='COMPUTER' ? '贈品：' : '' }}{{ item.coupon_name }}</span>
              <span class="giveaway-desc">{{ item.coupon_desc }}</span>
            </td>
            <td  v-if="ScreenState.mode ==='COMPUTER'">贈</td>
            <td>1</td>
            <td>贈</td>
            <td></td>
          </tr>
        </template>
      </table>
      <div class="empty" v-if="!PaymentStore.cartItems.length">目前購物車是空的喔～</div>
      <div class="freeship">
        <p v-if="LoginStore.currentUser?.user_group != 'ORIGINAL'">累計消費滿 $3,000 即可升級為創始會員 | </p>單筆訂單總額滿 NT$1,000 即可享免運費
        <span
          v-if="PaymentStore.amount < PaymentStore.freeShippingThreshold"
        >還差 ${{ PaymentStore.freeShippingThreshold - PaymentStore.sumOfCartPrice }} 即可享免運費優惠！</span>
      </div>
      <!-- <div class="giveaway-list" v-if="PaymentStore.couponItemList.length">
        <h3 class="giveaway-header">贈品</h3>
        <ul>
          <li v-for="item in PaymentStore.couponItemList" :key="`cart-coupon-item-${item.product_id}`">
            <div class="img-wrapper">
              <img
                :src="`/img/product/${ProductStore.productDict[item.product_id].product_uid}/avatar.webp`"
                alt
              />
            </div>
            <div>
              <h4>{{ item.product_name_en }}</h4>
              <h4>{{ item.product_name_zh }}</h4>
              <span>{{ item.coupon_name }}</span>
            </div>
          </li>
        </ul>
      </div> -->
      <div class="giveaway-list additional-list" v-if="ProductStore.additionalProductList.length">
        <h3 class="additional-header">加價購專區</h3>
        <ul>
          <li v-for="product in ProductStore.additionalProductList" :key="`cart-additional-item-${product.product_id}`">
            <div class="img-wrapper">
              <img
                :src="`/img/product/${ProductStore.productDict[product.product_id].product_uid}/avatar.webp`"
                alt
              />
            </div>
            <div>
              <h5>{{ product.product_sub_title }}</h5>
              <h4>{{ product.product_name_zh }}</h4>
              <span>加購價 ${{ product.product_price.ADDITIONAL }}</span>
              <p class="origin-price"> 原價 ${{ product.product_price.ORIGIN }}</p>
              <p class="diff">(現省${{ product.product_price.ORIGIN - product.product_price.ADDITIONAL }})</p>
              <button
                :class="cartIncludesAdditionalProduct(product.product_id) ? 'added' : '' "
                @click="addAdditionalProduct(product)" :disabled="cartIncludesAdditionalProduct(product.product_id)"
              >
                {{ cartIncludesAdditionalProduct(product.product_id) ? '已加購' : '加入購物車' }}
              </button>
            </div>
          </li>
        </ul>
      </div>
      <footer>
        <button class="backToChoose" @click="() => router.push('/product')">繼續選購商品</button>
        <button class="next" @click="toShipping">下一步</button>
      </footer>
    </div>
  </div>
</template>
