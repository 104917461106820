<script setup>
import { ref, inject, onMounted, onBeforeUnmount } from "vue"
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useProductStore } from "../store/product"
import { getElementPositionYWithLoop } from '@/utils'
import Icon from '@/components/Icon'
import ProductInstructionData from "@/utils/productInstruction.json"
import ProductKV3 from '@/assets/img/ProductKV3.webp'
import SummerIcon from '@/assets/img/summer_icon.svg'
import WinterIcon from '@/assets/img/winter_icon.svg'

const ScreenState = inject('ScreenProvider')
const ProductStore = useProductStore()

const allProducts = ProductInstructionData.reduce((acc, c) => [...acc, ...c.products], [])
const renderProductSection = section => {
  switch (section) {
    case "BOTH": return "日夜皆可使用"
    case "DAY": return "建議日間使用"
    case "NIGHT": return "建議夜間使用"
  }
}

const showAside = ref(false)
const navBarFocus = ref(null)
function func() {
  const windowScrollY = document.documentElement.scrollTop
  const windowHeight = window.innerHeight

  //control whether scroll navbar show
  if (windowScrollY >= window.innerHeight - 100) showAside.value = true
  else {
    showAside.value = false
    navBarFocus.value = null
  }

  //control focus navbar section
  for (const section of productRefs.value) {
    if (windowScrollY >= section.yStart -100 && windowScrollY < section.yEnd - 100) {
      console.log(windowScrollY, section.uid);
      navBarFocus.value = section.uid
      break
    } else continue
  }
}

const showMobileNav = ref(false)
const toggleMobileNav = (bool=true) => showMobileNav.value = bool

const productRefs = ref([])
onMounted(async () => {
  window.scrollTo(0, 0)
  if (document.documentElement.scrollTop >= window.innerHeight - 100) {
    showAside.value = true
  }
  //確認所有圖片都已經 Loading 載入完畢
  const promises = Array.from(document.getElementsByTagName('img')).map(e => new Promise((res) => {
    if (e.complete) res()
    e.onload = () => res()
    e.onerror = () => {
      e.src = require('@/assets/img/404_img.svg')
      console.log(e);
    }
  }))
  await Promise.all(promises)
  //計入所有章首Ｙ軸位置並 Render Nav
    setTimeout(() => {
      productRefs.value = []
      const sectionHeaderElements = document.getElementsByClassName('product')
      console.log(sectionHeaderElements);
      for (let el of sectionHeaderElements) {
        console.log(el);
        let positionY = getElementPositionYWithLoop(el)
        console.log(positionY);
        const uid = el.id
        productRefs.value.push({ uid, yStart: positionY, yEnd: positionY + el.scrollHeight })
      }
    }, 1000);

  document.addEventListener('scroll', func)
})
onBeforeUnmount(() => document.removeEventListener('scroll', func))

const scrollView = () =>{
  window.scrollTo({ top: ScreenState.mode === "MOBILE" ? window.innerHeight : window.innerHeight - 100, behavior: 'smooth' })
}
const scrollToProduct = (uid) => {
  toggleMobileNav(false)
  console.log(productRefs.value);
  const productYStart = productRefs.value.filter(p => p.uid === uid)[0].yStart
  window.scrollTo({ top: ScreenState.mode === "MOBILE" ? productYStart - 160  : productYStart - 100, behavior: 'smooth' })
}

const productSwipers = ref({})
const setSwiper = (swiperInst, uid) => {
  if (ScreenState.mode === 'COMPUTER') swiperInst.disable()
  productSwipers.value[uid] = swiperInst
}
const swipeNext = (uid, isNext=true) => {
  productSwipers.value[uid][isNext ? 'slideNext' : 'slidePrev']()
}
</script>

<template>
  <div class="view-product-instruction-container">
    <button
      v-if="ScreenState.mode === 'MOBILE'"
      :class="{'mobile-nav-toggle': true, hide: showMobileNav}"
      @click="toggleMobileNav"
    >
      產品快捷列
    </button>

    <section class="banner">
      <div class="bg" :style="`background-image: url(${ProductKV3})`">
        <div class="mask"></div>
      </div>
      <div class="title-wrapper">
        <h1>產品使用說明</h1>
        <button :class="{ 'from-left': ScreenState.mode === 'COMPUTER' }" @click="scrollView">
          <p>查看說明</p>
          <span>
            <icon icon="arrow-down-short" />
          </span>
        </button>
      </div>
    </section>
    <nav :class="{ show: showMobileNav }">
      <span class="close" @click="toggleMobileNav(false)">
        <icon icon="x" />
      </span>
      <header v-if="ScreenState.mode === 'COMPUTER'">選擇產品</header>
      <div v-else>
        <b>產品編號說明</b>
        <p>依照編號小到大使用，例如你擁有03, 05, 06 等產品<br>則從03開始使用，接著使用05，最後使用06</p>
      </div>
      <ul>
        <li
          v-for="product in allProducts" :key="`product_instruction_nav_${product.product_uid}`"
          @click="scrollToProduct(product.product_uid)"
        >
          <img :src="`/img/product/${product.product_uid}/avatar.webp`" alt="">
          <h4>{{ ProductStore.productUidDict[product.product_uid]?.product_name_zh }}</h4>
          <p>{{ ProductStore.productUidDict[product.product_uid]?.product_name_en }}</p>
        </li>
      </ul>
    </nav>

    <aside :class="{ show: showAside }">
      <ul>
        <li
          v-for="product in allProducts" :key="`product_instruction_aside_${product.product_uid}`"
          :class="{ focus: product.product_uid === navBarFocus }"
          @click="scrollToProduct(product.product_uid)"
        >
          <div><p>{{ ProductStore.productUidDict[product.product_uid]?.product_name_zh }}</p>
          <p>{{ ProductStore.productUidDict[product.product_uid]?.product_name_en }}</p></div>
        </li>
      </ul>
    </aside>

    <section class="category" v-for="cate in ProductInstructionData" :key="`instruction_${cate.category}`">
      <header>
        <span class="line"></span>
        <h2 v-html="ScreenState.mode === 'COMPUTER' ? cate.category : cate.category.slice(0, 2)+'<br>'+cate.category.slice(2)" />
        <span class="line"></span>
      </header>

      <div
        class="product" 
        :id="product.product_uid"
        v-for="product in cate.products" :key="`${cate.category}-${product.product_uid}`"
      >
        <div class="info-wrapper">
          <img :src="`/img/product/${product.product_uid}/avatar.webp`" alt="avatar_4001.webp">
          <div class="info">
            <header>
              <h2>{{ product.product_no }}</h2>
              <h2>{{ ProductStore.productUidDict[product.product_uid]?.product_name_zh }}</h2>
              <h3>{{ ProductStore.productUidDict[product.product_uid]?.product_name_en }}</h3>
            </header>

            <ul>
              <li>
                <h4 class="function">功能
                  <span class="tooltips">
                    <button>產品編號說明</button>
                    <div>
                      <b>產品編號說明</b>
                      <p>依照編號小到大使用，例如你擁有03, 05, 06 等產品<br>則從03開始使用，接著使用05，最後使用06</p>
                    </div>
                  </span>
                </h4>
                <span v-html="product.function.replace(/\n/g, '<br>')"></span>
              </li>
              <li>
                <h4 v-html="product.sections[0].title.replace(/\n/g, '<br>')"></h4>
                <p v-if="product.sections[0].summer"><img :src="SummerIcon">
                  春夏：
                  <span>{{ renderProductSection(product.sections[0].summer) }}</span></p>
                <p v-if="product.sections[0].winter"><img :src="WinterIcon">
                  秋冬：
                  <span>{{ renderProductSection(product.sections[0].winter) }}</span></p>
                <p v-if="product.sections[0].other">{{ product.sections[0].other }}</p>
              </li>
              <li v-if="product.sections[1]">
                <h4>{{ product.sections[1].title }}</h4>
                <p v-if="product.sections[1].summer"><img :src="SummerIcon">
                  春夏：
                  <span>{{ renderProductSection(product.sections[1].summer) }}</span></p>
                <p v-if="product.sections[1].winter"><img :src="WinterIcon">
                  秋冬：
                  <span>{{ renderProductSection(product.sections[1].winter) }}</span></p>
                <p v-if="product.sections[1].other">{{ product.sections[1].other }}</p>
              </li>
            </ul>
          </div>
        </div>

        <footer>
          <h4>使用步驟</h4>
          <div class="swiper-container">
            <div class="swiper-control" @click="swipeNext(product.product_uid, false)">
              <icon v-if="ScreenState.mode === 'MOBILE'" icon="caret-left-fill"/>
            </div>
            <swiper
              @swiper="(swiperInst) => setSwiper(swiperInst, product.product_uid)"
              :slides-per-view="ScreenState.mode === 'COMPUTER' ? 4 : 1"
              :space-between="ScreenState.mode === 'COMPUTER' ? 16 : 24"
            >
              <swiper-slide
                v-for="stepIndex in Object.keys(Array(product.steps.length).fill())"
                :key="`${cate.category}_${product.product_uid}_${stepIndex}`"
              >
                <div class="step-num">{{ parseInt(stepIndex)+1 }}</div>
                <img :src="`/img/product/${product.product_uid}/step${parseInt(stepIndex)+1}.webp`" alt="">
                <p v-html="product.steps.contents[stepIndex].replace(/\n/g, '<br>')"></p>
              </swiper-slide>
            </swiper>
            <div class="swiper-control" @click="swipeNext(product.product_uid)">
              <icon v-if="ScreenState.mode === 'MOBILE'" icon="caret-right-fill"/>
            </div>
          </div>
        </footer>
      </div>
    </section>
  </div>
</template>
