import { defineStore } from "pinia";
import { timestampParse, fetchApi, getLocal, saveToLocal, removeFromLocal } from '@/utils'
import database from '@/utils/database'
import Decimal from 'decimal.js';
import { useProductStore } from "./product";
import { useCouponStore } from './coupon'
import { gaEvent } from '@/utils/event'

async function setSession(key, value) {
  const res = await fetchApi('/api/session/', { set: { [key]: value }})
  console.log(res);
  return res
}

export const usePaymentStore = defineStore({
  id: 'Payment',
  state: () => ({
    cartItems: [],
    couponUsed: [],
    voucherUsed: 0,
    payType: 'CREDIT',
    shippingInfo: null,
    cvsInfo: null,
    freeShippingThreshold: 1000
  }),
  getters: {
    amount() {
      const ProductStore = useProductStore()
      if (ProductStore.productList.length) {
        return this.cartItems.reduce((acc, item) => {
          return acc + Number(ProductStore.productDict[item.product_id].product_price[item.product_price]) * item.product_quantity
        }, 0)
      }
      return 0
    },
    sumOfCartPrice() {
      const CouponStore = useCouponStore()
      let sumPrice = JSON.parse(JSON.stringify([this.amount]))[0]
      if (this.couponUsed.length && Object.keys(CouponStore.couponDict).length) {
        sumPrice = parseInt(this.couponUsed.filter(c => c.coupon_type === 'DISCOUNT').sort( function(a, b) {
          let couponA = CouponStore.couponDict[a.coupon_uid].coupon_formula
          let couponB = CouponStore.couponDict[b.coupon_uid].coupon_formula
          let index_a = '*-'.indexOf(couponA[0]), index_b = '*-'.indexOf(couponB[0])
          if (index_a === index_b) {
              if (a < b) return -1
              else if (a > b) return 1
              return 0
          } else return index_a-index_b
        }).reduce((acc, { coupon_uid }) => {
          const formula = CouponStore.couponDict[coupon_uid].coupon_formula
          if (!formula) return acc
          const [operation, power] = formula.split(' ')
          switch (operation) {
            case '*': return new Decimal(power).mul(acc)
            case '-': return acc - power
            default: return acc;
          }
        }, new Decimal(this.amount)).toFixed(0))
      }
      if (this.voucherUsed) sumPrice -= this.voucherUsed
      return sumPrice
    },
    freeShippingCost() {
      return this.freeShippingThreshold > this.amount ? 120 : 0
    },
    couponItemList() {
      if (this.couponUsed.length) {
        const giveawayCoupons = this.couponUsed.filter(c => c.coupon_type === "GIVEAWAY")
        if (giveawayCoupons.length) {
          const ProductStore = useProductStore()
          return giveawayCoupons.reduce((acc, coupon) => {
            return [...acc, ...coupon.items.map(i => ({
              ...ProductStore.productUidDict[i.product_uid],
              coupon_name: coupon.coupon_name,
              coupon_desc: coupon.coupon_desc
            }))]
          }, [])
        }
      }
      return []
    }
  },
  actions: {
    addProductIntoCart(payment) {
      if (payment.product_price === 'MEMBER') {
        const additionalIndex = this.cartItems.map(p => p.product_price).indexOf('ADDITIONAL')
        if (additionalIndex !== -1) this.cartItems.splice(additionalIndex, 0, { ...payment })
        else this.cartItems.push({ ...payment })
      } else {
        this.cartItems.push({ ...payment })
      }
      this.saveInfoToSession('CART')
    },
    removeProductFromCart(id) {
      const index = this.cartItems.map(e => e.product_id).indexOf(id)
      let product_quantity = this.cartItems[index].product_quantity
      this.cartItems.splice(index, 1)
      this.saveInfoToSession('CART')

      const ProdoctStore = useProductStore()
      //google analytics event
      gaEvent('remove_from_cart', {
        product_uid: ProdoctStore.productDict[id].product_uid,
        number_of_products: product_quantity
      })
    },
    removeAdditionalProduct(id) {
      const additionalIndex = this.cartItems.map(p => p.product_price).indexOf('ADDITIONAL')
      const additionalProductIndex = this.cartItems.filter(p => p.product_price === 'ADDITIONAL').map(p => p.product_id).indexOf(id)
      this.cartItems.splice(additionalIndex + additionalProductIndex, 1)
      this.saveInfoToSession('CART')
    },
    cleanAdditionalProduct() {
      const additionalIndex = this.cartItems.map(p => p.product_price).indexOf('ADDITIONAL')
      this.cartItems.splice(additionalIndex, this.cartItems.length - additionalIndex)
      this.saveInfoToSession('CART')
    },
    editProductQuantity(id, increase) {
      const index = this.cartItems.map(e => e.product_id).indexOf(id)
      let product_quantity = this.cartItems[index].product_quantity + increase
      if (product_quantity < 1) product_quantity = 1
      this.cartItems.splice(index, 1, { ...this.cartItems[index], product_quantity })
      this.saveInfoToSession('CART')
      
      if (increase === -1) {
        const ProdoctStore = useProductStore()
        //google analytics event
        gaEvent('decrement_from_cart', {
          product_uid: ProdoctStore.productDict[id].product_uid,
          number_of_products: 1
        })
      }
    },
    changePriceTypeForAllItems(isMember) {
      this.cartItems = this.cartItems.map(i => ({ ...i, product_price: 'MEMBER' }))
    },
    useCoupon(coupon, giveaways=[]) {
      if (this.couponUsed.map(e => e.coupon_uid).indexOf(coupon.coupon_uid) !== -1) return
      if (giveaways.length) {
        this.couponUsed.push({ ...coupon, items: [...giveaways] })
      } else {
        this.couponUsed.push(coupon)
      }
      // setSession('couponUsed', this.couponUsed)
      saveToLocal('couponUsed', this.couponUsed)
    },
    removeCoupon(couponUid) {
      const index = this.couponUsed.map(e => e.coupon_uid).indexOf(couponUid)
      if (index !== -1) {
        this.couponUsed.splice(index, 1)
        saveToLocal('couponUsed', this.couponUsed)
      }
      
    },
    useVoucher(value) {
      this.voucherUsed = value
    },
    cleanCart() {
      removeFromLocal('cartItems')
      this.cartItems = []
    },
    async saveShippingInfo(payload) {
      console.log(payload);
      try {
        // const res = await fetchApi('/api/session/', { set: { payment_shipping_info: { ...payload }} })
        // console.log(res);
        this.shippingInfo = { ...payload }
        return { status: 200 }
      } catch (error) {
        console.error(error);
      }
    },
    async createEcpayShippingOrder(payload) {
      const API = "/api/ecpay/"
      const res = await fetchApi(API, { api:'/Express/Create', shippingInfo: { ...payload } })
      console.log(res);
      return res
    },
    async saveInfoToSession(key) {
      console.log(key);
      switch (key) {
        case 'CART': saveToLocal('cartItems', this.cartItems)
          break;
        case 'SHIPPING': await setSession('shippingInfo', this.shippingInfo)
        default:
          break;
      }
    },
    cleanCVSInfo() {
      this.cvsInfo = null
    },
    async reloadSessionOrder() {
      // const res = await fetchApi('/api/session/', { get: ['shippingInfo', 'CVSInfo', 'test'] })
      // console.log(res);
      // if (res.data) {
        // const SESSION_ORDER = res.data;
        // console.log(SESSION_ORDER.cartItems)
        // console.log(SESSION_ORDER);
        this.cartItems = getLocal('cartItems') || []
        this.couponUsed = getLocal('couponUsed') || []
        // this.shippingInfo = SESSION_ORDER.shippingInfo || null
        // this.cvsInfo = SESSION_ORDER.CVSInfo || null
        // return SESSION_ORDER
      // }
      // return false
    }
  }
})
