<script setup>
import { ref, markRaw, onMounted, reactive, watch, inject, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Cart from './Cart'
import Shipping from './Shipping'
import Pay from './Pay'
import Result from './Result'
import CouponItems from './components/CouponItems'
import { usePaymentStore } from "../../store/payment";
import { useProductStore } from "../../store/product";
import Icon from '@/components/Icon'
import { useLoginStore } from '../../store/login'
import { useCouponStore } from '../../store/coupon'
import { fetchApi, completePhoneNumber } from '@/utils'
import { useLayoutStore } from '../../store/layout'
import { useOrderStore } from '../../store/order'
import { timestampParse } from '../../utils'
import moment from 'moment'

const $message = inject('$message')
const ScreenState = inject('ScreenProvider')
const PaymentStore = usePaymentStore()
const ProductStore = useProductStore()
const LoginStore = useLoginStore()
const LayoutStore = useLayoutStore()
const CouponStore = useCouponStore()
const router = useRouter()

const STEP_DICT = {
  'CART': {
    component: markRaw(Cart)
  },
  'SHIPPING': {
    component: markRaw(Shipping)
  },
  'PAY': {
    component: markRaw(Pay)
  },
  'RESULT': {
    component: markRaw(Result)
  }
}

const Route = useRoute()
const currentStep = ref('CART')
const currentComponentREF = ref()

async function useSystemCoupon(coupon_uid) {
  const result = await CouponStore.getCouponeWithUid(coupon_uid, 'SYSTEM')
  if (result.coupon) {
    const items = result.coupon.coupon_product.map(id => ({
      product_uid: ProductStore.productDict[id].product_uid,
      product_quantity: 1
    }))
    PaymentStore.useCoupon(result.coupon, items)
  }
}

function includeCoupon(coupon_uid) {
  return PaymentStore.couponUsed.map(c => c.coupon_uid).includes(coupon_uid)
}

const showMemberLoginReminder = ref(false)
function closeReminder() {
  showMemberLoginReminder.value = false
}
function toLogin() {
  Router.push('/register?redirect=payment')
}

const reminderTimeout = ref()
onMounted(async () => {
  window.scrollTo(0, 0)
  if (Route.query.fromcvs==='1') currentStep.value = 'SHIPPING'

  if (LayoutStore.layoutDict.NEW_YEAR_EVENT.layout_content && !includeCoupon('new_year_gift')) await useSystemCoupon('new_year_gift')
  if (PaymentStore.amount >= 3000  && !includeCoupon('over_3000_gift')) await useSystemCoupon('over_3000_gift')
  if (PaymentStore.cartItems.filter(p => ProductStore.productDict[p.product_id].product_uid === '2012').length && !includeCoupon('fibra_gel_75')) {
      await useSystemCoupon('fibra_gel_75')
    }
  if (!LoginStore.currentUser) reminderTimeout.value = setTimeout(() => showMemberLoginReminder.value = true, 3000)
})

LoginStore.$subscribe(async (mutation, state) => {
  if (state.currentUser) {
    clearTimeout(reminderTimeout.value)
    showMemberLoginReminder.value = false
  }
})

LayoutStore.$subscribe(async () => {
  if (LayoutStore.layoutDict.NEW_YEAR_EVENT.layout_content && !includeCoupon('new_year_gift')) await useSystemCoupon('new_year_gift')
})

PaymentStore.$subscribe(async (mutation, state) => {
  if (!state.cartItems.length) PaymentStore.removeCoupon('new_year_gift')
  if (PaymentStore.amount < 3000) PaymentStore.removeCoupon('over_3000_gift')
  if (!PaymentStore.cartItems.filter(p => ProductStore.productDict[p.product_id].product_uid === '2012').length) {
    PaymentStore.removeCoupon('fibra_gel_75')
  }
  if (PaymentStore.amount >= 3000 && !includeCoupon('over_3000_gift')) await useSystemCoupon('over_3000_gift')
  if (PaymentStore.cartItems.filter(p => ProductStore.productDict[p.product_id].product_uid === '2012').length && !includeCoupon('fibra_gel_75')) {
      await useSystemCoupon('fibra_gel_75')
    }

  //如果購物車中加購商品以外的商品都被移除，則將加購商品也移除
  if (!state.cartItems.filter(p => p.product_price !== 'ADDITIONAL').length) PaymentStore.cleanAdditionalProduct()
  if ((PaymentStore.amount < 1000)) {
    PaymentStore.removeCoupon('Welcome200')
  }
})

ProductStore.$subscribe(async (mutation, state) => {
  if (state.productList.length)  {
    if (PaymentStore.amount >= 3000 && !includeCoupon('over_3000_gift')) await useSystemCoupon('over_3000_gift')
    if (PaymentStore.amount < 3000) PaymentStore.removeCoupon('over_3000_gift')
    if (PaymentStore.cartItems.filter(p => ProductStore.productDict[p.product_id].product_uid === '2012').length && !includeCoupon('fibra_gel_75')) {
      await useSystemCoupon('fibra_gel_75')
    }
    if (!PaymentStore.cartItems.filter(p => ProductStore.productDict[p.product_id].product_uid === '2012').length) {
      PaymentStore.removeCoupon('fibra_gel_75')
    }
  }
})

const OrderStore = useOrderStore()
OrderStore.$subscribe(async (mutation, state) => {
  if (state.orderList.length && LoginStore.currentUser && LoginStore.currentUser.user_group === 'ORIGINAL') {
    const userBirthdayMonth = timestampParse(LoginStore.currentUser.user_birthday).format('M')
    if (userBirthdayMonth === moment().format('M')) {
      const thisMonthOrder = state.orderList.filter(o => moment(o.order_create_at).format('M') === moment().format('M'))
      if (!thisMonthOrder.length && !includeCoupon('original_birthday')) {
        await useSystemCoupon('original_birthday')
      }
    } else {
      PaymentStore.removeCoupon('original_birthday')
    }
  }
})

async function nextStep() {
  if (ScreenState.mode === 'MOBILE') {
    if (currentComponentREF.value.handleNext) {
      const result = await currentComponentREF.value.handleNext()
      if (!result) return
    }
    window.scrollTo(0, 0)
  }

  switch (currentStep.value) {
    case 'CART':
      currentStep.value = 'SHIPPING'
      break;
    case 'SHIPPING':
      currentStep.value = 'PAY'
      break;
    case 'PAY':
      currentStep.value = 'RESULT'
      break;
    default:
      currentStep.value = 'CART'
      break;
  }
}
const lastStep = () => {
  if (ScreenState.mode === 'MOBILE') window.scrollTo(0, 0)
  switch (currentStep.value) {
    case 'SHIPPING':
      currentStep.value = 'CART'
      break;
    case 'PAY':
      currentStep.value = 'SHIPPING'
      break;
    default:
      break;
  }
}

//偵測有會員帳號鄧入後改成使用會員價，但現在不用了統一都是會員價
// LoginStore.$subscribe((mutation, state) => {
//   console.log();
//   if (mutation.events.key === 'currentUser') PaymentStore.changePriceTypeForAllItems(Boolean(mutation.events.newValue))
// })

const loginInfo = reactive({ account: '', password: '' })
const loading = ref(false)
const login = async () => {
  loading.value = true
  if (loginInfo.account && loginInfo.password) {
    const result = await LoginStore.loginWithEmail(loginInfo)
    if (result.status === 200) $message.success('登入成功')
    else console.log(result.msg)
  }
  loading.value = false
}

const Router = useRouter()
const toRegister = () => Router.push('/register')

const showCouponModal = ref(false)
function toggleShowCouponModal(bool) {
  showCouponModal.value = bool
}

const focusCoupon = ref(null)
const couponGiveawayItems = reactive([])
const useCoupon = coupon => {
  if (coupon.coupon_super === "FIRST_BUY_200" && PaymentStore.amount < 1000) {
    return $message.warning('未達使用優惠券標準')
  }
  if (coupon.coupon_type === 'DISCOUNT') return PaymentStore.useCoupon(coupon)
  focusCoupon.value = coupon
}

const handleCouponItemUse = bool => {
  if (bool) {
    if (couponGiveawayItems.length != focusCoupon.value.coupon_max_product_chosen) {
      $message.warning(`您未選滿贈品，贈品總共可選擇${focusCoupon.value.coupon_max_product_chosen}項`)
      return
    }
    PaymentStore.useCoupon(focusCoupon.value, couponGiveawayItems)
  } 
  couponGiveawayItems.splice(0, couponGiveawayItems.length)
  focusCoupon.value = null
}
const chooseItem = item => {
  const index = couponGiveawayItems.map(e => e.product_uid).indexOf(item.product_uid)
  if (index === -1 && couponGiveawayItems.length < focusCoupon.value.coupon_max_product_chosen) {
    couponGiveawayItems.push(item)
  }
  if (index !==-1) couponGiveawayItems.splice(index, 1)
}

const couponUid = ref('')
const useCouponUid = async () => {
  const { state, coupon } = await CouponStore.getCouponeWithUid(couponUid.value)
  console.log(state, coupon);
  switch (state) {
    case 400: return $message.warning('找不到符合此優惠碼的優惠券')
    case 500: return $message.warning('此優惠券為限量，限量次數已被領完')
    case 501: return $message.warning('此優惠券已過有效期限')
    case 502: return $message.warning('您已使用過優惠券並超過該優惠券最大使用次數')
    case 200:
      if (!PaymentStore.couponUsed.filter(c => c.coupon_uid === coupon.coupon_uid).length) {
        if (coupon.coupon_type === "DISCOUNT") {
          useCoupon(coupon)
          couponUid.value = ''
          return $message.success('已套用優惠')
        }
        if (coupon.coupon_type === "GIVEAWAY") {
          toggleShowCouponModal(true)
          focusCoupon.value = coupon
          couponUid.value = ''
          return
        }        
      }
    default: break;
  }
}

const voucherInputREF = ref(null)
const showVoucherInput = ref(false)
const toggleShowVoucherInput = async (bool=true) => {
  showVoucherInput.value = bool
  if (bool) {
    await nextTick()
    voucherInputREF.value?.focus()
  }
}

const voucherUsed = ref(0)
const checkVoucherUsed = () => {
  PaymentStore.useVoucher(voucherUsed.value)
  toggleShowVoucherInput(false)
}

//購物金輸入防抖校正
const voucherTimeout = ref()
const voucherNumberEdit = newVal => {
  const intNewVal = Number(newVal.replace(/\b[0e]{1,}/g, ''))
  if (intNewVal < 0 || intNewVal === NaN || !intNewVal) return voucherUsed.value = 0
  if (intNewVal > LoginStore.activeVoucherAmount) return voucherUsed.value = LoginStore.activeVoucherAmount
  voucherUsed.value = newVal.replace(/\b[0e]{1,}/g, '')
}
const voucherDebounce = (func, wait, debounce=true) => {
  if (voucherTimeout.value) clearTimeout(voucherTimeout.value)
  if (debounce) voucherTimeout.value = setTimeout(() => func(), wait)
  else func()
}
const voucherChange = (e, debounce=true) => voucherDebounce(()=>voucherNumberEdit(e.target.value), 500, debounce)



</script>

<template>
  <div class="view-payment">
    <header class="steps">
      <div :class="{ 'step': true, 'active': currentStep === 'CART' || currentStep === 'RESULT' }">
        <div class="circle"><p v-if="currentStep !== 'RESULT'">1</p></div>
        <p>購物車</p>
      </div>
      <span class="line"></span>
      <div :class="{ 'step': true, 'active': currentStep === 'SHIPPING' || currentStep === 'RESULT' }">
        <div class="circle"><p v-if="currentStep !== 'RESULT'">2</p></div>
        <p>運送方式</p>
      </div>
      <span class="line"></span>
      <div :class="{ 'step': true, 'active': currentStep === 'PAY' || currentStep === 'RESULT' }">
        <div class="circle"><p v-if="currentStep !== 'RESULT'">3</p></div>
        <p>付款</p>
      </div>
    </header>
    <main class="step-wrapper">
      <div class="login-reminder" v-if="showMemberLoginReminder">
        <div class="wrapper" :style="{backgroundImage: `url(${require('@/assets/img/productExploreBabnner.webp')})`}">
          <div class="content">
            <span class="close" @click="closeReminder"><icon icon="x"/></span>
            <h2>馬上加入會員即可享有</h2>
            <h1>$200 首購優惠券</h1>
            <button @click="toLogin">馬上加入</button>
          </div>
        </div>
      </div>
      <component
        ref="currentComponentREF"
        :is="STEP_DICT[currentStep].component"
        class="step-comp"
        :nextStep="nextStep"
        :lastStep="lastStep"
      ></component>
      <aside>
        <section class="login">
          <header>{{ LoginStore.currentUser ? '會員資訊' : '登入' }}</header>
          <template v-if="!LoginStore.currentUser">
            <input v-model="loginInfo.account" type="text" placeholder="帳號" />
            <input v-model="loginInfo.password" @keydown.enter="login" type="password" placeholder="密碼" />
            <button @click="login" :disabled="loading">登入會員</button>

            <div class="register">
              <header>註冊會員</header>
              <p>加入會員即可享全商品會員價優惠</p>
              <button @click="toRegister">加入會員</button>
            </div>
          </template>
          <template v-else>
            <p>{{ LoginStore.currentUser?.user_name || '未設定' }} {{ LoginStore.currentUser.user_gender === 'FEMALE' ? '女士' : '先生' }}</p>
            <p>聯絡電話：{{ LoginStore.currentUser.user_phone ? completePhoneNumber(LoginStore.currentUser.user_phone) : '未設定' }}</p>
            <!-- <span class="extra">已套用會員優惠</span> -->
          </template>
        </section>
        <section class="payment-detail">
          <header>
            <h3>付款詳情</h3>
          </header>

          <main>
            <div class="coupon-use" v-if="LoginStore.currentUser">
              <input type="text" v-model="couponUid" placeholder="推薦碼/優惠碼" />
              <button @click="useCouponUid">套用</button>
            </div>

            <div class="coupon-choose">
              <a v-if="LoginStore.currentUser" @click="toggleShowCouponModal(true)">選擇優惠券</a>
              <ul class="coupon-used">
                <li v-for="coupon in PaymentStore.couponUsed" :key="`coupon_used_${coupon.coupon_uid}`">
                  <icon icon="ticket-perforated-fill" />
                  <p>{{ coupon.coupon_name }}</p>
                  <div v-if="coupon.coupon_scope!=='SYSTEM'">
                    <icon @click="PaymentStore.removeCoupon(coupon.coupon_uid)" icon="x-lg" />
                  </div>
                </li>
                <li v-if="!LoginStore.currentUser" class="register-remider2">註冊會員馬上享有$200首購優惠</li>
              </ul>
            </div>

            <ul class="amount">
              <li>
                <p>總計</p>
                <p>${{ PaymentStore.amount }}</p>
              </li>
              <li v-if="LoginStore.activeVoucherAmount > 0" class="voucher">
                <button @click="toggleShowVoucherInput">購物金折抵</button>
                <p>-${{ PaymentStore.voucherUsed }}</p>
              </li>
              <li v-if="LoginStore.activeVoucherAmount > 0" class="voucher-use">
                <p>購物金餘額 NT${{ LoginStore.activeVoucherAmount }}</p>
                <transition>
                  <div class="input-wrapper" v-if="showVoucherInput">
                    <span>
                      <input
                        ref="voucherInputREF"
                        v-model="voucherUsed"
                        type="number"
                        @keyup="voucherChange"
                        @change="(e) => voucherChange(e, false)"
                      />
                    </span>
                    <button @click="checkVoucherUsed">套用</button>
                  </div>
                </transition>
              </li>
              <li>
                <p>優惠折扣</p>
                <p>- ${{ PaymentStore.amount - PaymentStore.sumOfCartPrice - PaymentStore.voucherUsed }}</p>
              </li>
              <li>
                <p>運費</p>
                <p>${{ PaymentStore.freeShippingCost }}</p>
              </li>
              <li class="total">
                <p>付款總金額</p>
                <p>${{ PaymentStore.sumOfCartPrice + PaymentStore.freeShippingCost }}</p>
              </li>
              <i>
                <span>稅金 (已內含5%加值型營業稅)</span>
              </i>
            </ul>
          </main>
        </section>

        <div id="pptype" class="pay-and-port-type">
          <h4>可使用付款方式</h4>
          <div class="icons">
            <i class="fa-brands fa-cc-visa"></i>
            <i class="fa-brands fa-cc-mastercard"></i>
            <i class="fa-brands fa-cc-jcb"></i>
          </div>
          <!-- <h4>可選擇貨運方式</h4>
          <div class="icons">
            <p>7-11, FamilyMart, 萊爾富 超商取貨</p>
            <p>宅配到府</p>
          </div>-->
        </div>

        <footer>
          <button class="backToChoose" @click="router.push('/product')">繼續選購商品</button>
          <div class="button-group">
            <button v-if="currentStep !== 'CART'" class="last" @click="lastStep">上一步</button>
            <button v-if="currentStep !== 'PAY'" class="next" @click="nextStep">下一步</button>
          </div>
        </footer>
      </aside>
    </main>

    <div v-if="showCouponModal" class="coupon-modal" @click.self="toggleShowCouponModal(false)">
      <div class="wrapper">
        <header>
          <h3>可使用的優惠券</h3>
        </header>

        <transition name="left-in">
          <main v-if="!focusCoupon">
            <ul v-if="CouponStore.couponList.length">
              <template
                v-for="coupon in CouponStore.couponList.filter(c => c.coupon_scope === 'MEMBER')"
                :key="coupon.coupon_uid"
              >
              <li
                v-if="!PaymentStore.couponUsed.map(e => e.coupon_uid).includes(coupon.coupon_uid)"
                :class="{ used: PaymentStore.couponUsed.includes(coupon.coupon_uid) }"
              >
                <h4>{{ coupon.coupon_name }}</h4>
                <span class="desc">{{ coupon.coupon_desc }}</span>
                <button @click="useCoupon(coupon)">使用</button>
              </li>
              </template>
            </ul>
            <p class="no-coupon" v-else>目前沒有優惠券可使用</p>
          </main>
        </transition>
        <transition name="right-in">
          <main v-if="focusCoupon" class="giveaway-items">
            <h3>{{ focusCoupon.coupon_name }}優惠券
            - 贈品選擇 {{ couponGiveawayItems.length }} / {{ focusCoupon.coupon_max_product_chosen }}</h3>
            <section>
              <ul>
                <template v-if="focusCoupon.coupon_product[0] === 'ALL'">
                  <coupon-items type="PRODUCT" :couponGiveawayItems="couponGiveawayItems" @choose="chooseItem"></coupon-items>
                </template>
                <template v-if="focusCoupon.coupon_product[0] === 'SET'">
                  <coupon-items type="SET" :couponGiveawayItems="couponGiveawayItems" @choose="chooseItem"></coupon-items>
                </template>
                <template v-else>
                  <coupon-items
                    type="CUSTOM"
                    :couponGiveawayItems="couponGiveawayItems"
                    :customProdcutIds="focusCoupon.coupon_product"
                    @choose="chooseItem"
                  />
                </template>
              </ul>
            </section>
            <div class="button-group">
              <button @click="handleCouponItemUse(false)">取消</button>
              <button @click="handleCouponItemUse(true)">確定</button>
            </div>
          </main>
        </transition>
      </div>
    </div>
  </div>
</template>
