<script setup>
import { CfInput, CfCheckbox } from '@/components/Form'
import CVS_FAMI_WEBP from '@/assets/img/CVS_FAMI.webp'
import CVS_UNIMART_WEBP from '@/assets/img/CVS_UNIMART.webp'
import { nextTick, onMounted, reactive, ref, defineExpose, inject, onBeforeUnmount } from 'vue'
import { useLoginStore } from '../../store/login'
import { usePaymentStore } from '../../store/payment'
import { euqalObject } from '../../utils'
import { getZipCode } from '@/utils/zip'
import moment from 'moment'
import { useRoute } from 'vue-router'
import { gaEvent } from '@/utils/event'

const Props = defineProps({ nextStep: Function, lastStep: Function })
const ScreenProvider = inject('ScreenProvider')
const $message = inject('$message')
const phone = ref('')
const order_port_type = ref('HOME')
const order_pay_type = ref('CREDIT')
const acceptJoinMember = ref(false)
const rememberShippingInfo = ref(true)
const acceptEDM = ref(true)
const choosePayType = type => {
  if (type === "COD") {
    PaymentStore.cleanCVSInfo()
    order_port_type.value = "HOME"
  }
  order_pay_type.value = type
}
const choosePortType = type => {
  if (type === "HOME") {
    PaymentStore.cleanCVSInfo()
  }
  order_port_type.value = type
}

const LoginStore = useLoginStore()
const shippingInfo = reactive({})
const injectShippingInfo = userInfo => {
  shippingInfo.firstName = userInfo.user_name ? userInfo.user_name[0] : ''
  shippingInfo.lastName = userInfo.user_name ? userInfo.user_name.slice(1, 3) : ''
  shippingInfo.user_phone = userInfo.user_phone
  shippingInfo.user_email = userInfo.user_email
  shippingInfo.user_city = userInfo.user_city
  shippingInfo.user_urban_area = userInfo.user_urban_area
  shippingInfo.user_address = userInfo.user_address
}

let stageTimeoutInternalInst
const stageTimeout = ref(0)
const infoErrorTimes = ref(0)
const Route = useRoute()
onMounted(() => {
  if (LoginStore.currentUser) injectShippingInfo(LoginStore.currentUser)
  console.log(Object.keys(shippingInfo));
  console.log(Route.query.fromcvs);
  if (Route.query.fromcvs==='1') {
    order_port_type.value = 'CVS'
    const { CVSStoreID, CVSStoreName, CVSAddress, LogisticsSubType } = Route.query 
    PaymentStore.cvsInfo = {
      CVSStoreID, CVSStoreName, CVSAddress, LogisticsSubType
    }
  }
  if (Route.query.ExtraData === 'COD') {
    order_pay_type.value = 'COD'
  }
  stageTimeoutInternalInst = setInterval(() => {
    stageTimeout.value += 1
    console.log(stageTimeout.value);
  }, 1000);
})
onBeforeUnmount(() => {
  clearInterval(stageTimeoutInternalInst)
})

const currenUserMutated = ref(false)
LoginStore.$subscribe((mutation, newState) => {
  console.log(mutation);
  if (currenUserMutated.value) return
  if (newState.currentUser) {
    injectShippingInfo(newState.currentUser)
    currenUserMutated.value = true
  } else {
    for (const key of Object.keys(shippingInfo)) {
      shippingInfo[key] = ''
    }
  }
})

const ecpayMapREF = ref(null)
const ecpayMapHtml = ref()
async function handleChooseStore(LogisticsSubType) {
  ecpayMapHtml.value = true
  await nextTick()
  console.log(ecpayMapREF.value);
  const form = ecpayMapREF.value.getElementsByTagName('form')[0]
  form.setAttribute('action', 'https://logistics.ecpay.com.tw/Express/map') //上線前修改成正式環境
  console.log(window.location.hostname);
  form.innerHTML = `
    <input name="ServerReplyURL" value="https://${window.location.hostname}/api/map-back/">
    <input name="MerchantID" value="${process.env.VUE_APP_ECPAY_LOGISTICS_C2C_MID}">
    <input name="IsCollection" value="${order_pay_type.value === 'COD' ? 'Y' : 'N'}">
    <input name="LogisticsType" value="CVS">
    <input name="LogisticsSubType" value="${LogisticsSubType}">
    <input name="Device" value="${ScreenProvider.mode === 'MOBILE' ? '1' : '0'}">
    <input name="ExtraData" value="${order_pay_type.value}">
  `//上線前修改成正常網域
  console.log(form);
  form.submit()
}

const PaymentStore = usePaymentStore()
async function handleSubmit() {
  let checkKey = ['firstName', 'lastName', 'user_phone', 'user_email']
  PaymentStore.payType = order_pay_type.value
  if (order_port_type.value === 'HOME') {
    checkKey = [...checkKey, 'user_address', 'user_city', 'user_urban_area']
    
    //確認地址市是否存在且查詢得到郵遞區號
    const ReceiverAddress = shippingInfo.user_city + shippingInfo.user_urban_area + shippingInfo.user_address
    const zipInfo = await getZipCode(ReceiverAddress)
    if (!zipInfo.zipcode) {
      $message.error('無法找到與出貨地址匹配的郵遞區號，請確認地址是否正確')
      infoErrorTimes.value += 1
      return null
    }
    console.log(zipInfo)
  }
  if (shippingInfo.user_phone.length !== 9) {
    $message.error('手機格式錯誤，請確認是否為9開頭的九位數號碼')
    infoErrorTimes.value += 1
    return null
  }
  if (order_port_type.value === 'CVS' && !PaymentStore.cvsInfo) {
    $message.warning('未選擇取貨門市')
    infoErrorTimes.value += 1
    return null
  }
  if (!checkKey.every(key => shippingInfo[key])) {
    $message.warning('未完全填妥取貨資訊')
    infoErrorTimes.value += 1
    return null
  }
  const result = await PaymentStore.saveShippingInfo({ ...shippingInfo, logisticsType: order_port_type.value})

  //google analytics event
  gaEvent('add_shipping_info', {
    stage_timeout: stageTimeout.value,
    info_error_times: infoErrorTimes.value,
    logistics_type: PaymentStore.cvsInfo?.LogisticsSubType || PaymentStore.shippingInfo.logisticsType
  })

  if (rememberShippingInfo.value) {
    if (order_port_type.value === "HOME") {
      await LoginStore.updateUser({
        user_city: shippingInfo.user_city,
        user_urban_area: shippingInfo.user_urban_area,
        user_address: shippingInfo.user_address
      })
    }
  }

  if (result.status === 200 && ScreenProvider.mode === "COMPUTER") Props.nextStep()
  return true
}

defineExpose({ handleNext: handleSubmit })
</script>

<template>
  <div class="view-shipping">
    <section class="pay-type">
      <header>
        <h2>付款方式</h2>
      </header>
      <main>
        <div class="pay-type-radio credit">
          <div
            :class="{ 'radio': true, 'choosed': order_pay_type === 'CREDIT' }"
            @click="choosePayType('CREDIT')"
          >
            <span class="picked"></span>
          </div>
          <div class="content-wrapper">
            <h3>信用卡付款</h3>
            <p>線上刷卡後，即進行出貨</p>
          </div>
        </div>
        <div class="pay-type-radio credit">
          <div
            :class="{ 'radio': true, 'choosed': order_pay_type === 'COD' }"
            @click="choosePayType('COD')"
          >
            <span class="picked"></span>
          </div>
          <div class="content-wrapper">
            <h3>貨到付款</h3>
            <p>到貨時將由物流公司或超商門市進行收款</p>
          </div>
        </div>
      </main>
    </section>
    <section class="port-type">
      <header>
        <h2>運送方式</h2>
      </header>
      <main>
        <div class="port-type-radio home">
          <div
            :class="{ 'radio': true, 'choosed': order_port_type === 'HOME' }"
            @click="choosePortType('HOME')"
          >
            <span class="picked"></span>
          </div>
          <div class="content-wrapper">
            <h3>宅配到府</h3>
            <p>由黑貓宅急便直送到府，約 1-3 工作天</p>
          </div>
        </div>
        <div class="port-type-radio convenient">
          <div
            :class="{ 'radio': true, 'choosed': order_port_type === 'CVS' }"
            @click="choosePortType('CVS')"
          >
            <span class="picked"></span>
          </div>
          <div class="content-wrapper">
            <h3>超商取貨</h3>
            <p>可選擇超商：7-11，全家 Family Mart</p>  
            <span v-if="PaymentStore.cvsInfo">
              已選擇:{{PaymentStore.cvsInfo.LogisticsSubType==='UNIMARTC2C' ? '7-11統一超商': '全家 FamilyMart'}}
              {{ PaymentStore.cvsInfo.CVSStoreName }} {{ PaymentStore.cvsInfo.CVSAddress }}
            
            </span>
          </div>
        </div>
        <div class="choose-store" v-if="order_port_type === 'CVS'">
            <button @click="handleChooseStore('UNIMARTC2C')">
              <div><img :src="CVS_UNIMART_WEBP" alt=""></div>選擇7-11門市
            </button>
            <button @click="handleChooseStore('FAMIC2C')">
              <div><img :src="CVS_FAMI_WEBP" alt=""></div>選擇全家門市
            </button>
          </div>
      </main>
    </section>
    <section class="port-info">
      <header>
        <h2>取貨資訊</h2>
      </header>
      <main>
        <form>
          <cf-input v-model="shippingInfo.firstName" placeholder="姓氏 (須與身分證相)" required></cf-input>
          <cf-input v-model="shippingInfo.lastName" placeholder="名字 (須與身分證相)" required></cf-input>
          <cf-input
            v-model="shippingInfo.user_phone"
            placeholder="電話號碼"
            addonBefore="+886"
            required
          ></cf-input>
          <template v-if="order_port_type === 'HOME'">
            <cf-input v-model="shippingInfo.user_city" placeholder="城市" required></cf-input>
            <cf-input v-model="shippingInfo.user_urban_area" placeholder="鄉鎮區" required></cf-input>
            <cf-input
              v-model="shippingInfo.user_address"
              customClass="address"
              placeholder="詳細地址 (例：松濤路12巷5號12樓A室)"
              required
            ></cf-input>
          </template>
          <template v-if="order_port_type === 'CVS' && PaymentStore.cvsInfo">
            <cf-input 
              customClass="cvs"
              :modelValue="PaymentStore.cvsInfo.LogisticsSubType==='UNIMARTC2C' ? '7-11 統一超商' : '全家 Family Mart'"
              placeholder="超商" readonly required
            />
            <cf-input customClass="cvs" v-model="PaymentStore.cvsInfo.CVSStoreName" placeholder="取貨門市" readonly required />
            <cf-input
              v-model="PaymentStore.cvsInfo.CVSAddress"
              customClass="cvs address"
              placeholder="門市地址" readonly required
            />
          </template>
          <cf-input v-model="shippingInfo.user_email" placeholder="電子信箱" required></cf-input>
        </form>
        <cf-checkbox v-model="rememberShippingInfo" :checked="true">記住出貨資訊 (下次訂購時候自動帶入出貨資訊，也可於會員專區修改)</cf-checkbox>
        <!-- <cf-checkbox v-model="acceptEDM">我同意接收 Chris Farrell Taiwan 的電子報通知＊</cf-checkbox> -->
        <div class="button-group">
          <button @click="lastStep">上一步</button>
          <button @click="handleSubmit">前往結帳</button>
        </div>
      </main>
    </section>

    <div class="ecpay-map-modal" v-if="ecpayMapHtml">
      <main>
        <section ref="ecpayMapREF" class="map">
          <form action="" method="POST"></form>
        </section>
      </main>
    </div>
  </div>
</template>
