<script setup>
import { onBeforeMount, onBeforeUnmount, onMounted, provide, ref, reactive } from 'vue';
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import CfFooter from "./components/CfFooter.vue"
import NavBar from "./components/NavBar.vue"
import Message from "./components/Message/Message.vue"
import { useLoginStore } from './store/login';
import { useOrderStore } from './store/order';
import { useProductStore } from './store/product';
import { useCouponStore } from './store/coupon';
import { usePaymentStore } from './store/payment';
import { useEmailStore } from './store/email'
import { useLayoutStore } from './store/layout'
import { fetchApi } from '@/utils'
import { usePostStore } from './store/post';
import { useRoute, useRouter } from 'vue-router';
const screenState = reactive({
  mode: 'MOBILE'
})
const layoutState = reactive({
  showNavTopNews: true
})

provide('ScreenProvider', screenState)
provide('LayoutSetting', layoutState)
provide('$message', reactive({}))
let func = () => {
  if (window.innerWidth < 900) screenState.mode = 'MOBILE'
  else screenState.mode = 'COMPUTER'
}
const router = useRouter()
const route = useRoute()
const LoginStore = useLoginStore()
const LayoutStore = useLayoutStore()
const OrderStore = useOrderStore()
const ProductStore = useProductStore()
const PaymentStore = usePaymentStore()
const PostStore = usePostStore()
const CouponStore = useCouponStore()
const EmailStore = useEmailStore()
onBeforeMount(async() => {
  EmailStore.queryEmailTemplates()
  const auth = getAuth()
  onAuthStateChanged(auth, async user => {
    if (user) {
      const result = await LoginStore.saveUser(user)
      if (result.state === 205) router.push(`/register?login=fb&cus=${result.user_id}`)
      
      OrderStore.queryOrders()
      CouponStore.queryCoupon()
      LoginStore.queryVouchers()
    }
  })

  const res = await fetchApi('/api/line/autoLogin/');
  console.log(res);
  if (res.state === 200 && res.user_line_id) {
    await LoginStore.queryUserWithLineId(res.user_line_id)
    if (!LoginStore.currentUser.user_name) {
      router.push(`/register?login=line&cus=${LoginStore.currentUser.key_id}`)
    }
    await OrderStore.queryOrders()
    await CouponStore.queryCoupon()
    await LoginStore.queryVouchers()
  }
})


async function clearPaymentSession() {
  const res = await fetchApi('/api/session/', { unset: ['cartItems', 'couponUsed', 'shippingInfo', 'CVSInfo'] })
  console.log(res);
}

onMounted(async () => {
  func()
  window.addEventListener('resize', func)
  TPDirect.setupSDK(process.env.VUE_APP_TAPPAY_APP_ID, process.env.VUE_APP_TAPPAY_APP_KEY, 'production')
  PaymentStore.reloadSessionOrder()
  await LayoutStore.queryLayout()
  ProductStore.queryProduct()
  PostStore.queryPost()
  // clearPaymentSession()
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', func)
})
</script>

<template>
  <Message />
  <nav-bar></nav-bar>
  <router-view />
  <cf-footer></cf-footer>
</template>

