<script setup>
import Salon from "@/assets/img/Salon.webp"
import BadenBadenBg from "@/assets/img/BadenBaden.webp"
import DrChrisFarrell from "@/assets/img/DrChrisFarrell.webp"
import AboutChrisFarrell from "@/assets/img/AboutChrisFarrell.webp"
import TechInHuman from "@/assets/img/Tech_in_Human.webp"
import Dev1 from '@/assets/img/dev1.png'
import EuropBg from "@/assets/img/Europe.webp"
import { VScrollFadein } from '../directives'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref, inject, onMounted, watch, onBeforeMount, onBeforeUnmount } from 'vue'
import Icon from '../components/Icon.vue'
import { useRouter } from "vue-router"

const router = useRouter()
const ScreenState = inject('ScreenProvider')
onMounted(() => {
  window.scrollTo(0, 0)
  carouselFunc()
})

const spaSwiper = ref(null)
const setSwiper = swiper => spaSwiper.value = swiper
const swipeNext = (isNext=true) => spaSwiper.value[isNext ? 'slideNext' : 'slidePrev']()

const currentTimeout = ref()
const carouselSensor = ref()
watch(carouselSensor, changeCarouselPage)
async function carouselFunc() {
  currentTimeout.value = setTimeout(() => {
    carouselSensor.value = !carouselSensor.value
  }, 3000);
}
function changeCarouselPage(isAuto=true, isNext=true) {
  if (!isAuto) spaSwiper.value[isNext ? 'slideNext' : 'slidePrev']()
  else swipeNext('CAROUSEL', true)
  clearTimeout(currentTimeout.value)
  carouselFunc()
}

onBeforeUnmount(() => clearTimeout(currentTimeout.value))

const hideInfo = ref(true)
const hideTech = ref(true)
function toggleCollapse(type) {
  switch (type) {
    case 'INFO': return hideInfo.value = !hideInfo.value
    case 'TECH': return hideTech.value = !hideTech.value
  }

}

const spaCarouselList = [
  { country: '奧地利', store: 'Ben Cosmetics Tyrol', image: '/salon_1_1.webp' },
  { country: '捷克', store: 'Salon Asineda', image: '/salon_2_1.webp' },
  { country: '德國', store: 'Kosmetik + Wellness', image: '/salon_3_1.webp' }, 
]

const historyList = [
  { year: 1250, title: 'Baden-Baden, Germany', content: '巴登-巴登正式成為城市，由於當地的溫泉及藥草非常著名，因此有「歐洲療養聖地」的美譽。' },
  { year: 1964, title: 'Dr. Chris Farrell', content: 'Dr. Chris Farrell 開始進行生物、化學、皮膚醫學以及植物藥學研究，結合上述廣闊的學問，研發專業保養品並結合人體仿生的科技與成分，用最適合人體的方式幫助膚重獲健康與修復力。' },
  { year: 1968, title: 'Baden-Baden, Germany', content: 'Chris Farrell 創立於德國 巴登-巴登，採取德國黑森林蘊藏無數自然植物，是地球上最珍貴的藥草寶庫，讓克莉斯瑞得以在此取得適合的保養品成分。' },
  { year: 1980, title: 'Chris Farrell Basic Line 系列商品', content: 'Chris Farrell  正式在德國上市銷售。' },
  { year: 2002, title: 'Chris Farrell Basic Line 系列商品', content: 'Chris Farrell 開始引進台灣，提供高級美容院專業的系列保養品，並於2022年正式直接服務消費者' },
  { year: 2022, title: 'Chris Farrell Basic Line 系列商品', content: '正式進軍台灣市場，開設官方網站，同時進駐包括Momo和Shopee等知名電商平台，以擴大我們在台灣的線上銷售渠道。' },
]
</script>

<template>
  <div class="view-about">
    <header class="banner">
      <img :src="require('@/assets/img/v3/about_banner.webp')">
    </header>
    <section class="doctor">
      <header>
        <h1 v-scroll-fadein>About Chris Farrell</h1>
        <h2 v-scroll-fadein><span class="line"></span>關於克莉斯瑞</h2>
      </header>
      <div class="wrapper left">
        <div class="content">
          <span v-scroll-fadein>
            Dr. Chris Farrell 為德國著名的美容專家，於1964年開始進行生物、化學、皮膚醫學以及植物藥學的研究，結合上述廣闊的學問，研發專業保養品以及美容療程，並結合人體仿生的科技與成分，用最適合人體的方式幫助皮膚重獲健康與修復力。
          </span>
          <p>創辦人 Dr. Chris Farrell</p>
          <img :src="require('@/assets/img/v3/text_signature.webp')" alt="" v-scroll-fadein>
        </div>
        <div class="img-wrapper" v-scroll-fadein>
          <img :src="require('@/assets/img/v3/dr1.webp')" alt="">
        </div>
      </div>
      <div class="wrapper right">
        <div class="content">
          <span v-scroll-fadein>
            Mrs. Chris Farrell 在1964時還是醫學院的學生，在1968年時，他3歲的兒子被醫生診斷患有惡性腫瘤，且僅有百分之二的存活機會，從這時開始，創辦人投入了所有的時間積極地進行研究，共花了12年的時間陪同兒子對抗惡疾，最終也因使用人體仿生科技及成分協助兒子成功對抗惡性腫瘤。為了支持家裡的生計，Dr. Chris Farrell 充分利用人體仿生科技與成分進行專業保養品的研發，意外發現人體仿生成分對於人體非常友善，不僅能幫助人們變得美麗，更能夠讓肌膚變得健康，讓肌膚的問題從根本被解決。
          </span>
        </div>
        <div class="img-wrapper" v-scroll-fadein>
          <img :src="require('@/assets/img/v3/dr2.webp')" alt="">
        </div>
      </div>
    </section>
    <section class="baden-baden">
      <header>
        <img :src="require('@/assets/img/v3/baden.webp')" alt="">
        <div class="content">
          <h2 v-scroll-fadein>來自德國古溫泉鎮 歐洲療養聖地</h2>
          <p v-scroll-fadein>Baden-Baden 巴登巴登</p>
        </div>
      </header>
      <div class="info" v-scroll-fadein>
        Chris Farrell 於1969年創立於德國 巴登-巴登，巴登-巴登位於德國的西南部，座落於萊茵河畔及黑森林旁，自古以來，德國黑森林蘊藏無數的自然植物，是地球上最珍貴的藥草寶庫，也讓 Chris Farrell 得以在此取得適合的保養品成分。
        <span :class="{ spread: !hideInfo || ScreenState.mode === 'COMPUTER' }" v-scroll-fadein>
          巴登(baden)在德文有「溫泉」的意思，巴登-巴登在1250年時正式成為城市，由於當地的溫泉及藥草非常著名，因此有「歐洲療養聖地」的美譽。在巴登-巴登，有密集的溫泉療養會館，眾多美容師及前往放鬆療養的客人都將 Chris Farrell 的產品作為其放鬆保養的首選，因為 Chris Farrell 的產品不添加Paraben防腐劑、色素及礦物油，只使用人體皮膚原有或是生物機制激發的物質做為保養品的原料，提供客人最天然、安全且符合巴登-巴登精神的保養選擇。
        </span>
        <button v-if="ScreenState.mode === 'MOBILE'" class="more-mobile" @click="toggleCollapse('INFO')">繼續閱讀<icon icon="chevron-down"/></button>
      </div>
    </section>
    <section class="tech">
      <h1 v-scroll-fadein>德國實驗室研發 <br v-if="ScreenState.mode === 'MOBILE'">人體仿生科技</h1>
      <div class="img-wrapper" v-scroll-fadein>
        <div>
          <span class="gif-title">有人體仿生</span>
          <img :src="require('@/assets/img/v3/tech1.gif')" alt="">
        </div>
        <div>
          <span class="gif-title">無人體仿生</span>
          <img :src="require('@/assets/img/v3/tech2.gif')" alt="">
        </div>
      </div>
      <div class="content" v-scroll-fadein>
        人體仿生為仿造人體組成及構造的一種技術，人體仿生相對於一般保養品成分，對人體更安全、更有效。因為這些成分本來就是皮膚結構的一員，能夠大幅降低皮膚產生排斥的機率，幫助皮膚更有效地吸收。
      </div>
      <span :class="{ spread: !hideTech || ScreenState.mode === 'COMPUTER' }" v-scroll-fadein>
        皮膚是人體最大的器官，它的天職是保護人體免於外在環境的侵害。對於皮膚來說，任何不熟悉的物質，甚至是保養品成分，它都會盡力避免吸收進人體，雖然這些成分對於人體是有幫助的，但為了人類的生存與演化，這正是皮膚數千年來保護人類的方式。然而現代社會的衛生條件相比過去已經完全不同，人們活著不再只是為了生存，更多人追求的是精緻地活著。有了人體仿生保養品的協助，皮膚更願意接納其他對皮膚有效的成分，讓其他美白、抗老、修護等成分與人體仿生成分一同深入肌底，用更安全有效的方式將保養的效果帶給使用者。在這裡邀請大家一同體驗德國研發50年的人體仿生技術，更安全、更有效地照顧自己的肌膚。
      </span>
      <button v-if="ScreenState.mode === 'MOBILE'" class="more-mobile" @click="toggleCollapse('TECH')">繼續閱讀<icon icon="chevron-down"/></button>
    </section>
    <section class="spa">
      <header>
        <h1 v-scroll-fadein>全球5000間</h1>
        <h2 v-scroll-fadein>頂級SPA御用</h2>
      </header>
      <img class="gif" :src="ScreenState.mode === 'COMPUTER' ? require('@/assets/img/v3/spa_flipagram.gif') : require('@/assets/img/v3/spa_flipagram_m.gif')" alt="" v-scroll-fadein>
      <div class="carousel">
        <button class="control left" @click="swipeNext(false)"><icon icon="chevron-left" /></button>
        <button class="control right" @click="swipeNext(true)"><icon icon="chevron-right" /></button>
        <swiper
          @swiper="swiper => setSwiper(swiper, 'CAROUSEL')"
          :slides-per-view="1"
          :space-between="0"
          :loop="true"
        >
          <swiper-slide v-for="store, index in spaCarouselList" :key="`spa_store_${index}`">
            <img :src="require(`@/assets/img/v3${store.image}`)">
            <footer>
              <icon icon="geo-alt-fill" />
              <div><h3>{{ store.store }}</h3><h3 class="country">{{ store.country }}</h3></div>
            </footer>
          </swiper-slide>
        </swiper>
      </div>
      <div class="world-map">
        <img :src="require('@/assets/img/v3/world_map.webp')" alt="" v-scroll-fadein>
        <span v-scroll-fadein>
          奧地利、德國、捷克、韓國...等全球30國5000間頂級SPA皆選用Chris Farrell 作為其御用產品。來自奧地利的kosmetik-amalienbad、德國的Ben Resort...等，都是歐洲貴婦最常光臨的保養SPA。現在你只要在家，就能用合理的價格獲得頂級SPA的保養體驗
        </span>
      </div>
    </section>
    <section class="history">
      <header>
        <h1 v-scroll-fadein>Our History</h1>
        <h2 v-scroll-fadein>創立於1969年</h2>
      </header>
      <ul>
        <li v-for="timepoint, index in historyList" :key="`about_history_${index}`">
          <div class="image" v-scroll-fadein>
            <img :src="require(`@/assets/img/v3/history${index+1}.webp`)" alt="">
          </div>
          <div class="content" v-scroll-fadein>
            <h3>{{ timepoint.year }}<span>年</span></h3>
            <h5>{{ timepoint.title }}</h5>
            <span>{{ timepoint.content }}</span>
          </div>
        </li>
      </ul>
    </section>
    <section class="no-fashion">
      <h1 v-scroll-fadein>我們不時尚</h1>
      <span v-scroll-fadein>
        你可以發現我們的產品設計非常單調普通，因為我們的產品從來不以時尚或潮流為基準，我們研發保養品只有一個目的，那就是希望能幫助消費者安全地解決皮膚問題。因此很抱歉我們無法你提供華麗的包裝與設計，只能提供你基於生理學研發的樸實保養品。
      </span>
      <img :src="require(`@/assets/img/v3/no_fashion.webp`)" alt="" v-scroll-fadein>
    </section>
    <section class="insist">
      <h1 v-scroll-fadein>We Insist</h1>
      <ul class="flow" v-scroll-fadein>
        <li><img :src="require('@/assets/img/Inist_Flow_1.svg')" alt=""><p>化驗</p></li>
        <icon class="arrow" icon="caret-right-fill" />
        <li><img :src="require('@/assets/img/Inist_Flow_2.svg')" alt=""><p>微生物檢驗</p></li>
        <icon class="arrow" icon="caret-right-fill" />
        <li><img :src="require('@/assets/img/Inist_Flow_3.svg')" alt=""><p>毒物學評鑑</p></li>
        <icon class="arrow" icon="caret-right-fill" />
        <li><img :src="require('@/assets/img/Inist_Flow_4.svg')" alt=""><p>製成成品</p></li>
        <icon class="arrow" icon="caret-right-fill" />
        <li><img :src="require('@/assets/img/Inist_Flow_5.svg')" alt=""><p>再次毒物檢驗</p></li>
      </ul>
      <span v-scroll-fadein>
        克莉斯瑞的產品配方在這50多年來隨著科技研究的創新不斷進展，根據最新的皮膚分析檢測指數資料來進行配方的調整。如果我們無法獲得產品所需要的成分，在經實驗找到更好的替代成分之前，我們會將產線停產，直到產品成分恢復供應。所有的 Chris Farrell 的保養品皆在德國原廠完成製造，製造的流程皆遵循最嚴格的WHO規範，直到成品包裝完成前，所有製造過程皆受到嚴密監控與把關。
      </span>
    </section>
    <section class="gurantee">
      <h1 v-scroll-fadein>We Guarantee</h1>
      <ul v-scroll-fadein>
        <li><img :src="require(`@/assets/img/v3/guarantee_cruelty.webp`)" alt=""><p>PETA 零殘忍認證</p></li>
        <li><img :src="require(`@/assets/img/v3/guarantee_iso.webp`)" alt=""><p>歐盟GMP ISO 認證</p></li>
        <li><img :src="require(`@/assets/img/v3/guarantee_beste.webp`)" alt=""><p>德國最佳獎</p></li>
      </ul>
      <span v-scroll-fadein>
        Chris Farrell 獲得歐盟的GMPISO認證，德國工藝的精細與堅持在世界上有目共睹，你值得用合理的價格使用頂級的產品，且我們絕不在動物身上進行產品實驗。Chris Farrell 也在講究品質與工藝的德國市場中榮獲2018德意志最佳獎(Deutschlands Beste)，品質與技術有目共睹！
      </span>
    </section>
  </div>
</template>
